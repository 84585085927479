import React from 'react'
import './style.css'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
    import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import SearchImg from 'assets/icon/Search.png'
import {useSelector,useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import peserta from 'redux/reducers/peserta'

export default function Index(props) {
    const dispatch=useDispatch()
    const peserta = useSelector(state => state.peserta)
    const general = useSelector(state => state.general)
    const {detail_log_list,detail_log_pagination}=peserta
    const togglePagination=(page,pageLength)=>{}
    const back=()=>{props.tabsToggle('list_peserta','detail_log')}
    const refreshData=()=>{toast("Data berhasil diperbarui")}
    return (
        <div>
            <div className='head-section'>
                    <div className='div-flex'>
                        <h4 style={{marginBottom:0,marginTop:0}}>
                            Detail Log pada proyek Batch 
                        </h4>
                        &nbsp;&nbsp;&nbsp;
                        <div style={{width:1,height:20,backgroundColor:'grey'}}></div>
                        &nbsp;&nbsp;&nbsp;
                        <h4 style={{marginBottom:0,marginTop:0}}>
                        01 - 11 Oktober 20  
                        </h4>
                    </div>
                    <div>
                    <Button onClick={back} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="outlined">Kembali</Button>
                    <Button onClick={null} color='primary' className='btn-rounded btn-remove-capital btn-action' variant="contained">Download Hasil</Button>
                    </div>
                </div>
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                            <OutlinedInput
                                size='small'
                                style={{height:30,width:200,}}
                                // value={client.search}
                                id="input-with-icon-textfield"
                                name='password'
                                // onChange={this.searchToggle}
                                // onKeyPress={e =>
                                //     this.handleKeyPress(e)
                                // }
                                required
                                startAdornment={
                                <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                }
                                labelWidth={50}
                            />
                        </FormControl>
                        <Button onClick={refreshData} color="primary" className='btn-remove-capital' variant="text">Perbarui data</Button>
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left" >Tanggal</TableCell>
                            <TableCell align="left" >Waktu</TableCell>
                            <TableCell align="left" >Browser</TableCell>
                            <TableCell align="left">IP Address</TableCell>
                            <TableCell align="left">Aktifitas</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={5}/>
                            :
                            detail_log_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{data.company}</TableCell>
                                    <TableCell >{data.industry}</TableCell>
                                    <TableCell >{data.address}</TableCell>
                                    <TableCell >{data.phone}</TableCell>
                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {detail_log_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={detail_log_pagination.page}
                            pageLength={detail_log_pagination.perPage}
                            totalRecords={detail_log_pagination.total}
                            totalRecords={detail_log_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
