import React ,{useState,useEffect}from 'react'
import {useSelector,useDispatch} from 'react-redux'
import Welcome from './welcome'
import Assessment from './assestment'
import Modal from 'components/Modal'
import Loader from 'components/Loading'
import Camera from 'components/Camera'
import {setCfitTime,setCfitActiveSub, setCfit} from 'redux/actions/assestment'

export default function Index(props) {
    const dispatch = useDispatch()
    // const [active_sub, setActiveSub] = useState(0)
    const [active_intruction, setActiveIntruction] = useState(0)
    const assestment = useSelector(state => state.assestment)

    const {cfit_start_time,cfit_active_sub}=assestment
    const general = useSelector(state => state.general)
    
    const subToggle=async (key)=>{
        // alert('hello')
        if(assestment.cfit[cfit_active_sub+1].start_time==null&&assestment.cfit[cfit_active_sub+1].type!=='welcome'){
            assestment.cfit[cfit_active_sub+1].start_time=Date.now()
            await dispatch(setCfit([...assestment.cfit]))
        }
        if(key==='next'){
            dispatch(setCfitActiveSub(cfit_active_sub+1))
        }else{
            dispatch(setCfitActiveSub(cfit_active_sub-1))

        }
    }
    const intructionToggle=(key)=>{
        if(key==='next'){
            setActiveIntruction(active_intruction+1)
        }else{
            setActiveIntruction(active_intruction-1)
        }
    }
    const resetInstruction=()=>{
        setActiveIntruction(0)
    }
    const clickAgree=async ()=>{
        
        // subToggle(s'next')
    }
    const renderLayer=()=>{
        switch (assestment.cfit[cfit_active_sub].type) {
            case 'welcome':
                return <Welcome profile={props.profile} token={props.token} subToggle={subToggle} active_intruction={active_intruction} intructionToggle={intructionToggle} cfit={assestment.cfit[cfit_active_sub]} clickAgree={clickAgree}/>
            case 'assestment':
                return <Assessment resetInstruction={resetInstruction}  active_sub={cfit_active_sub} setActiveSub={()=>dispatch(setCfitActiveSub())} profile={props.profile} token={props.token} subToggle={subToggle} active_intruction={active_intruction} intructionToggle={intructionToggle} cfit={assestment.cfit[cfit_active_sub]} clickAgree={clickAgree}/>
        
            default:
                break;
        }
    }
    // console.log('assestment.cfit', cfit_active_sub,assestment.cfit,active_intruction)
    return (
        <div>
            <Loader/>
            {general.on_cam&&<Camera profile={props.profile}/>}
            <Modal token={props.token} profile={props.profile}/>
            {renderLayer()}
            
        </div>
    )
}
