import React,{useState} from 'react'
import Layout from 'components/Layouts'
import './style.css'
import Beranda from './beranda'
import FormProyek from './form_proyek'
import {useSelector,useDispatch} from 'react-redux'
import {tabToggle} from 'redux/actions/master'
import FormPeserta from './form_peserta'
export default function Index(props) {
    const dispatch=useDispatch()
    const master = useSelector(state => state.master)
    const {tab_active,tab_back}=master
    const [tab_action,setTabAction]=useState('list_proyek')

    const tabsToggle=(key,back)=>{
        dispatch(tabToggle(key,back))
    }
    console.log('tab_active', tab_active)
    return (
        <div>
            <Layout>
                {tab_active==='beranda'&&<Beranda tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} token={props.token} profile={props.profile} tabsToggle={tabsToggle} tab_back={tab_back} tab_active={tab_active}/>}
                {tab_active==='form_proyek'&&<FormProyek tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} token={props.token} profile={props.profile} tabsToggle={tabsToggle} tab_back={tab_back} tab_active={tab_active}/>}
                {tab_active==='form_peserta'&&<FormPeserta tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} tabsToggle={tabsToggle} token={props.token} profile={props.profile}/>}

            </Layout>
        </div>
    )
}
