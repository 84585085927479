import * as actionTypes from 'redux/constants/oas'
import moment from 'moment'
const initialState={
    type_assestment:[],
    list_assestment:[],
    list_assestment_pagination:null,
    all_total_assestment:0,
    all_total_participant:0,
    assestment_detail:{
        id:0,
        survey:{id:0},
        name:"",
        startDate:moment(),
        endDate:moment(),
        totalGroups:0,
        totalParticipants:0
    },
    list_group:[],
    group_pagination:null,
    individual_report:[],
    individual_report_pagination:null,
    individual_report_headers:[],

    survey_detail:null,
    jawaban:[],
    result_survey:null,
    report_survey:null,
}
export default (state=initialState,action)=>{

    switch (action.type) {
        
        case actionTypes.SET_OAS:
            return{
                ...state,
                [Object.keys(action.payload)]:Object.values(action.payload)[0]
            }
    
            default:

                return state
    }
}