export const SET_QUESTION = "SET_QUESTION";

export const RESET_QUESTION = "RESET_QUESTION";

export const SET_CFIT = "SET_CFIT";
export const RESET_CFIT = "RESET_CFIT";

export const SET_PAPI = "SET_PAPI";
export const RESET_PAPI = "RESET_PAPI";

export const SET_TOEFL = "SET_TOEFL";
export const RESET_TOEFL = "RESET_TOEFL";



export const SET_RECORD = "SET_RECORD";
export const SET_UPDATE_BIO = "SET_UPDATE_BIO";

export const SET_CFIT_TIME = "SET_CFIT_TIME";
export const SET_DISC_TIME = "SET_DISC_TIME";
export const SET_PAPI_TIME = "SET_PAPI_TIME";
export const SET_TOEFL_TIME = "SET_TOEFL_TIME";

export const RESET_ALL = "RESET_ALL";
export const SET_COUNT_FOTO = "SET_COUNT_FOTO";

export const SET_ACTIVE_SUB = "SET_ACTIVE_SUB";
export const SET_ACTIVE_PART = "SET_ACTIVE_PART";

export const SET_CFIT_ACTIVE_SUB = "SET_CFIT_ACTIVE_SUB";
export const SET_PAPI_ACTIVE_SUB = "SET_PAPI_ACTIVE_SUB";
