import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel,Checkbox} from '@material-ui/core'

import Correct from 'assets/image/Correct.png'
import Wrong from 'assets/image/Wrong.png'
import shape_1 from 'assets/image/shape_1.svg'
import shape_2 from 'assets/image/shape_2.svg'
import shape_3 from 'assets/image/shape_3.svg'
import shape_4 from 'assets/image/shape_4.svg'
import shape_5 from 'assets/image/shape_5.png'
import shape_6 from 'assets/image/shape_6.png'
import Check from 'assets/icon/Check.svg'
import {tabToggle} from 'redux/actions/master'
import InputMask from 'react-input-mask'
import moment from 'moment'
// import '../style.css'
import Loader from 'components/Loading'
import {handleFile} from 'components/handleFile'
import {
    DatePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {postInfoPeserta} from 'redux/actions/assestment'
import {setPeserta,uploadPeserta,uploadPeserta2,getDetailUser} from 'redux/actions/peserta'
import {modalToggle} from 'redux/actions/general'
import Modal from 'components/Modal'
// import NotReady from './not_ready'
import ACS from 'assets/image/ACS.png'
import Cookie from 'universal-cookie'
const cookie=new Cookie()

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    
}));
export default function Welcome(props) {
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
    const [agree,setAgree]=useState()

    const dispatch=useDispatch()
    const peserta = useSelector(state => state.peserta)

    const {detail_peserta}=peserta
    useEffect(() => {
        getUsert()
    }, [])
    const getUsert=async ()=>{
        // let res=await dispatch(getDetailUser(`/${profile.id}`))
        // if(res){
        //     if(res.data.completed){
        //         window.location.assign('/done')
        //     }
        // }
    }
    const clickAgree=()=>{
        // window.location.assign('/disc/assestment')
        props.clickAgree()
    }
    return (
        <div>
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile' >
                    <div className='d-flex'>
                        <img
                        src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
                        width="auto"
                        height={35}
                        />
                        </div>
                        <h2>{props.ln==="id"?"Selamat datang":"Welcome"} <span>{detail_peserta.nama}</span></h2>
                        <p >{props.welcomeMessage}</p>
                        
                    </div>
                </div>
                <div className='welcome-example' style={{position:'relative'}}>
                    <img style={{top:-35,left:-40,width:150,position:'absolute'}} className="shape-img-oas"  src={shape_1}/>
                    <img style={{top:-85,right:50,width:250,position:'absolute'}} className="shape-img-oas"  src={shape_5}/>
                    <img style={{bottom:-30,left:-150,width:250,position:'absolute'}} className="shape-img-oas"  src={shape_6}/>
                    <img style={{bottom:-120,left:200,width:200,position:'absolute'}} className="shape-img-oas" src={shape_6}/>
                    <img style={{bottom:-100,right:20,width:150,position:'absolute'}} className="shape-img-oas" src={shape_1}/>
                    <div className='card-example'>
                    <div className='welcome-mobile'>

                    {props.children}
                    <Button disabled={props.handleDisabled()} onClick={clickAgree}   style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>{props.btnText}</Button>
                    </div>
                    </div>
                </div>

                <br className='show-mobile'/>

            </div>
        </div>
    )
}
