import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-with-upload-image'
import {URL as newUrl}  from 'service/base_url'
import Cookie from 'universal-cookie'
import {Button,TextField} from '@material-ui/core'
import {useDispatch,useSelector} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import {setEmailTemplate,postEmailBulk,setEmailBulk} from 'redux/actions/proyek'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'

const cookie=new Cookie()
const token=cookie.get('login_cookie')
ClassicEditor.defaultConfig = {
	toolbar: { 
		items: [
			'heading',
			'|',
			'bold', 
			'italic',
			'alignment',
			'link',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			'imageUpload',
			'blockQuote',
			'insertTable',
			'undo',
			'redo'
		]
	},
	alignment: {
		options: [ 'left', 'right','center' ]
	},
	image: {
		toolbar: [
			'imageStyle:full',
			'imageStyle:side',
			'imageStyle:alignLeft',
			'imageStyle:alignCenter',
			'imageStyle:alignRight',
			'|',
			'imageTextAlternative'
		],
		styles:[
			'full',
			'side',
			'alignLeft',
			'alignCenter',
			'alignRight'
		]
	},
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells'
		]
	},
	// This value must be kept in sync with the language defined in webpack.config.js.
	language: 'en',
	simpleUpload: {
		// The URL that the images are uploaded to.
		uploadUrl: `${newUrl}/upload/ckeditor`,

		// Headers sent along with the XMLHttpRequest to the upload server.
		headers: {
			'X-CSRF-TOKEN': 'CSFR-Token',
			Authorization: `Bearer ${token}`
		}
    }
    
   
};
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width:'100%',
    marginBottom:10
}
}));
export default function Email_template(props) {
	const dispatch = useDispatch()
	const proyek = useSelector(state => state.proyek)
	let {email_template,proyek_detail,email_bulk}=proyek
    const classes=useStyles()

	const onClickSimpan=async ()=>{
		let new_userIds=[]
		proyek_detail.peserta.peserta_list.map((data)=>{
			new_userIds.push(data.id)
		})
		let data={
			projectId:proyek_detail.projectId,
			subject:email_bulk.subject,
			email:email_bulk.email,
			userId:props.profile.id,
			userIds:new_userIds,
			cc:email_bulk.cc,
			to:email_bulk.to
		}
		dispatch(postEmailBulk(data))
		
		
	}
	const deleteCC=(chip,index)=>{
		let filter=email_bulk.cc.filter((data)=>{
            return data!==chip
        })
        dispatch(setEmailBulk({cc:[...filter]}))
	}
	const onAdd=(chip)=>{
		var re = /\S+@\S+\.\S+/;
		if(re.test(chip)){
			dispatch(setEmailBulk({cc:[...email_bulk.cc,chip]}))

		}
	}
	const handleDisable=()=>{
		if(email_bulk.to!=='[%EMAIL%]'){
			var re = /\S+@\S+\.\S+/;
			if(re.test(email_bulk.to)){
				return false

			}else{
				return true
			}
		}else{
			return false
		}
	}
    return (
        <div style={{maxHeight:500,overflowY:'scroll',overflowX:'hidden'}}>
            <div className='div-flex'>
                <div style={{width:'80%'}}>
				<TextField
					label={<>Email</>}
					// variant='outlined'
					value={email_bulk.to}
					size='small'
					// disabled={handleDisable()}
					className={classes.textField}
					onChange={(e)=>dispatch(setEmailBulk({to:e.target.value}))}

				/>
				<ChipInput
                        size="small"
                        color="primary"
                        label="CC"
                        // onUpdateInput={(e) => dispatch(setEmailBulk({cc:[e.target.value]}))}
                        value={email_bulk.cc}
                        onAdd={(chip) =>onAdd(chip)}
                        // onAdd={(chip) =>console.log('chip', chip)}
                        onDelete={(chip, index) => deleteCC(chip,index)}
                        // variant="outlined"
                        className={classes.textField2}
                        // disabled={handleFieldDisable()}

                    />
				<TextField
					label={<>Subject email</>}
					// variant='outlined'
					value={email_bulk.subject}
					size='small'
					// disabled={handleDisable()}
					className={classes.textField}
					onChange={(e)=>dispatch(setEmailBulk({subject:e.target.value}))}

				/>
                <CKEditor
                    data={email_bulk.email}
                    editor={ ClassicEditor }
                    onChange={(event,editor)=>{
                        const data=editor.getData()
                        dispatch(setEmailBulk({email:data}))
                    }}
                    // disabled={handleFieldDisable()}
                />
                <p>Berikut merupakan tampilan yang akan peserta terima di emailnya</p>
                </div>
                <div style={{width:'20%'}}>
                    <div className='template-email-keterangan-header'>
                        <p><b>Keterangan</b></p>
                    </div>
                    <div className='template-email-keterangan-body'>
                        <p><b>[%NAME%]</b></p>
                        <p>Gunakan variabel [%NAME%] untuk merujuk ke nama peserta.</p>
                        <p><b>[%EMAIL%]</b></p>
                        <p>Gunakan variabel [%EMAIL%] digunakan untuk merujuk ke email peserta yang digunakan untuk akses assessment.</p>
                        <p><b>[%PASSWORD%]</b></p>
                        <p>Gunakan variabel [%PASSWORD%] untuk merujuk ke password peserta yang digunakan untuk akses assessment.</p>
                        <p><b>[%LINK%]</b></p>
                        <p>Gunakan variabel [%LINK%] untuk merujuk alamat link assessment dilaksanakan</p>
                        <p><b>[%DARI%] [%SAMPAI%]</b></p>
                        <p>Variabel [%DARI%] digunakan untuk merujuk awal mulai waktu akses dan  [%SAMPAI%] untuk akhir waktu akses assessment</p>
                        <p><b>[%OTOMATIS%]</b></p>
                        <p>Digunakan untuk fitur direct url. peserta dapat akses assessment melalui email</p>
                        <p><b>[%MAILTO%]</b></p>
                        <p>Digunakan untuk merujuk contact person</p>
                    </div>
                </div>
            </div>
            <div style={{textAlign:'center'}}>
                <Button disabled={handleDisable()} onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Kirim</Button>
            </div>
        </div>
    )
}
