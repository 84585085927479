import image_import from 'components/ImageImport'
const data={
    disc:[
        {
            number:1,
            list_question:[
                {q:'Mudah bergaul, ramah, mudah setuju',answer:''},
                {q:'Mempercayai, percaya pada orang lain',answer:''},
                {q:'Petualang, suka mengambil resiko',answer:''},
                {q:'Penuh toleransi, menghormati orang lain',answer:''},
            ]
        },
        {
            number:2,

            list_question:[
                {q:'Yang penting adalah hasil',answer:''},
                {q:'Kerjakan dengan benar, ketepatan sangat penting',answer:''},
                {q:'Buat agar menyenangkan',answer:''},
                {q:'Kerja bersama-sama',answer:''},
            ]
        },
        {
            number:3,

            list_question:[
                {q:'Pendidikan kebudayaan',answer:''},
                {q:'Prestasi, penghargaan',answer:''},
                {q:'Keselamatan, keamanan',answer:''},
                {q:'Sosial, pertemuan kelompok',answer:''},
            ]
        },
        {
            number:4,

            list_question:[
                {q:'Lembut, tertutup',answer:''},
                {q:'Visionary/pandangan Kemasa depan',answer:''},
                {q:'Pusat perhatian, suka bersosialisasi',answer:''},
                {q:'Pendamai, pembawa ketenangan',answer:''},
            ]
        },
        {
            number:5,

            list_question:[
                {q:'Menahan diri, bisa hidup tanpa memiliki',answer:''},
                {q:'Membeli karena dorongan hasrat/impluse',answer:''},
                {q:'Bersedia menunggu ketika ingin membeli',answer:''},
                {q:'Akan membeli apa yang diinginkan',answer:''},
            ]
        },
        {
            number:6,

            list_question:[
                {q:'Mengambil kendali, bersikap langsung/direct',answer:''},
                {q:'Suka bergaul, antusias',answer:''},
                {q:'Mudah ditebak, konsisten',answer:''},
                {q:'Waspada, berhati-hati',answer:''},
            ]
        },
        {
            number:7,

            list_question:[
                {q:'Menyemangati orang lain',answer:''},
                {q:'Berusaha mencapai kesempurnaan',answer:''},
                {q:'Menjadi bagian dari tim/kelompok',answer:''},
                {q:'Ingin menetapkan goal/tujuan',answer:''},
            ]
        },
        {
            number:8,

            list_question:[
                {q:'Bersahabat, mudah bergaul',answer:''},
                {q:'Unik, bosan pada rutinitas',answer:''},
                {q:'Aktif melakukan perubahan',answer:''},
                {q:'Ingin segala sesuatu sesuai ukuran dan pasti',answer:''},
            ]
        },
        {
            number:9,

            list_question:[
                {q:'Sulit dikalahkan/ditundukkan',answer:''},
                {q:'Melaksanakan sesuai perintah',answer:''},
                {q:'Bersemangat, periang',answer:''},
                {q:'Ingin keteraturan, rapi',answer:''},
            ]
        },
        {
            number:10,

            list_question:[
                {q:'Menjadi frustasi',answer:''},
                {q:'Memendam perasaan dalam hati',answer:''},
                {q:'Menyampaikan pandangan pribadi',answer:''},
                {q:'Berani menghadapi oposisi',answer:''},
            ]
        },
        {
            number:11,

            list_question:[
                {q:'Mengalah, tidak suka bertentangan',answer:''},
                {q:'Perhatian pada hal kecil/detail',answer:''},
                {q:'Berubah pada menit-menit akhir',answer:''},
                {q:'Mendesak/memaksa',answer:''},
            ]
        },
        {
            number:12,

            list_question:[
                {q:'Saya akan pimpin mereka',answer:''},
                {q:'Saya akan ikut/mengikuti',answer:''},
                {q:'Saya akan mempengaruhi/membujuk mereka',answer:''},
                {q:'Saya akan dapat fakta-faktanya',answer:''},
            ]
        },
        {
            number:13,

            list_question:[
                {q:'Hidup/lincah banyak bicara',answer:''},
                {q:'Cepat, penuh keyakinan',answer:''},
                {q:'Berusaha menjaga keseimbangan',answer:''},
                {q:'Berusaha patuh pada peraturan',answer:''},
            ]
        },
        {
            number:14,

            list_question:[
                {q:'Ingin kemajuan/peningkatan',answer:''},
                {q:'Puas dengan keadaan tenang/mudah puas',answer:''},
                {q:'Menunjukkan perasaan dengan terbuka',answer:''},
                {q:'Rendah hati, sederhana',answer:''},
            ]
        },
        {
            number:15,

            list_question:[
                {q:'Memikirkan orang lain dahulu',answer:''},
                {q:'Suka bersaing/kompetitif, suka tantangan',answer:''},
                {q:'Optimis, berpikir positif',answer:''},
                {q:'Sistematis berpikir logis',answer:''},
            ]
        },
        {
            number:16,

            list_question:[
                {q:'Mengelola waktu dengan efisien',answer:''},
                {q:'Sering terburu-buru merasa ditekan',answer:''},
                {q:'Hal-hal sosial adalah penting',answer:''},
                {q:'Suka menyelesaikan apa yang sudah dimulai',answer:''},
            ]
        },
        {
            number:17,

            list_question:[
                {q:'Tenang, pendiam, tertutup',answer:''},
                {q:'Gembira, bebas, riang',answer:''},
                {q:'Menyenangkan, baik hati',answer:''},
                {q:'Keras, berani',answer:''},
            ]
        },
        {
            number:18,

            list_question:[
                {q:'Menyenangkan orang lain, ramah, penurut',answer:''},
                {q:'Tertawa lepas, hidup',answer:''},
                {q:'Pemberani, tegas',answer:''},
                {q:'Pendiam, tertutup, tenang',answer:''},
            ]
        },
        {
            number:19,

            list_question:[
                {q:'Menolak perubahan yang mendadak',answer:''},
                {q:'Cenderung terlalu banyak berjanji',answer:''},
                {q:'Mundur apabila berada di bawah tekanan',answer:''},
                {q:'Tidak takut untuk beradu argumentasi',answer:''},
            ]
        },
        {
            number:20,

            list_question:[
                {q:'Menyediakan waktu untuk orang lain',answer:''},
                {q:'Merencanakan masa depan, bersiap-siap',answer:''},
                {q:'Menuju petualangan baru',answer:''},
                {q:'Menerima penghargaan atas pencapaian target',answer:''},
            ]
        },
        {
            number:21,

            list_question:[
                {q:'Ingin wewenang/kekuasaan lebih',answer:''},
                {q:'Ingin kesempatan baru',answer:''},
                {q:'Menghindari perselisihan/konflik apa pun',answer:''},
                {q:'Ingin arahan yang jelas',answer:''},
            ]
        },
        {
            number:22,

            list_question:[
                {q:'Penyemangat/pendukung yang baik',answer:''},
                {q:'Pendengar yang baik',answer:''},
                {q:'Penganalisa yang baik',answer:''},
                {q:'Pendelegasian yang baik/membagi tugas',answer:''},
            ]
        },
        {
            number:23,

            list_question:[
                {q:'Peraturan perlu diuji',answer:''},
                {q:'Peraturan membuat menjadi adil',answer:''},
                {q:'Peraturan membuat menjadi membosankan',answer:''},
                {q:'Peraturan membuat menjadi aman',answer:''},
            ]
        },
        {
            number:24,

            list_question:[
                {q:'Dapat dipercaya dan diandalkan',answer:''},
                {q:'Kreatif, unik',answer:''},
                {q:'Berorientasi pada hasil/sasaran/tujuan',answer:''},
                {q:'Memegang teguh standar yang tinggi/akurat',answer:''},
            ]
        },
    ],
    cfit:[
        {
            sub:1,
            type:'welcome',
            desc:'<p class="instruksi-text">Test ini memiliki beberapa tahap. Pada setiap tahap, anda akan mendapatkan instruksi pengerjaannya terlebih dahulu. Setelah anda melanjutkan ke tahap berikutnya, anda tidak diperkenankan untuk mengerjakan kembali tahap sebelumnya.<br/><br/>Setiap tahap mempunyai waktunya tersendiri. Ketika waktu untuk mengerjakan satu tahapan sudah habis, maka semua jawahan anda akan tersimpan dan anda akan secara otomatis lanjut ke tahap berikutnya. Jika anda sudah menyelesaikan semua soal sementara waktu masih tersedia, anda dapat lanjut ke tahap berikutnya dengan mengklik tombol yang ada di bagian bawah layar<br/><br/>Untuk setiap pertanyaan, jika anda ingin mengganti jawaban, anda cukup klik jawaban lain yang anda inginkan.<br/><br/>Pada Tahap 1 ini, di setiap nomornya anda akan disajikan 4 kotak. Namun kotak terakhir belum ditemukan. Kotak pertama hingga kotak terakhir memiliki hubungan yang saling kontinyu. Anda diminta untuk mencari kotak keempat di antara pilihan A - F dengan melihat hubungan antar kotak tadi. Untuk lebih jelasnya, silakan perhatikan contoh di bawah ini.</p>',
            question:[],
            intruction:[
                {
                    example_img:image_import.cfit.CfitExample1,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.CfitExample2,
                    answer:'E'
                },
                {
                    example_img:image_import.cfit.CfitExample3,
                    answer:'E'
                },
            ],
        },
        {
            sub:1,
            type:'assestment',
            duration:180000,
            // duration:10000,
            start_time:null,
            desc:'<p class="instruksi-text">Pada Tahap 1 ini, di setiap nomornya anda akan disajikan 4 kotak. Namun kotak terakhir belum ditemukan. Kotak pertama hingga kotak terakhir memiliki hubungan yang saling kontinyu. Anda diminta untuk mencari kotak keempat di antara pilihan A - F dengan melihat hubungan antar kotak tadi.</p>',
            question:[
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N1,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N11,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N12,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N13,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N14,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N15,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N16,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N2,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N21,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N22,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N23,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N24,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N25,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N26,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N3,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N31,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N32,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N33,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N34,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N35,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N36,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N4,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N41,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N42,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N43,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N44,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N45,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N46,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N5,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N51,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N52,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N53,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N54,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N55,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N56,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N6,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N61,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N62,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N63,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N64,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N65,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N66,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N7,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N71,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N72,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N73,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N74,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N75,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N76,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N8,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N81,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N82,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N83,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N84,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N85,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N86,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N9,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N91,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N92,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N93,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N94,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N95,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N96,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N10,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N101,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N102,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N103,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N104,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N105,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N106,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N_11,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N111,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N112,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N113,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N114,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N115,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N116,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N_12,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N121,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N122,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N123,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N124,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N125,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N126,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S1N_13,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S1N131,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S1N132,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S1N133,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S1N134,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S1N135,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S1N136,
                            text:'F',
                            value:6
    
                        },
                    ]
                },
            ],
            intruction:[
                {
                    example_img:image_import.cfit.CfitExample1,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.CfitExample2,
                    answer:'E'
                },
                {
                    example_img:image_import.cfit.CfitExample3,
                    answer:'E'
                },
            ],
        },
        {
            sub:2,
            type:'welcome',
            desc:'<p class="instruksi-text">Pada tahap ini, di setiap nomor anda akan disajikan 5 buah kotak. Di antara 5 kotak tersebut, selalu ada 2 kotak yang berbeda dengan ketiga kotak lainnya. Tugas anda adalah memilih kedua gambar tersebut. Untuk lebih jelasnya, silakan perhatikan contoh di bawah ini.</p>',
            question:[],
            intruction:[
                {
                    example_img:image_import.cfit.S2C1,
                    answer:'B dan D'
                },
                {
                    example_img:image_import.cfit.S2C2,
                    answer:'C dan E'
                },
            ],
        },
        {
            sub:2,
            type:'assestment',
            duration:240000,
            // duration:10000,
            desc:'<p class="instruksi-text">Pada tahap ini, di setiap nomor anda akan disajikan 5 buah kotak. Di antara 5 kotak tersebut, selalu ada 2 kotak yang berbeda dengan ketiga kotak lainnya. Tugas anda adalah memilih kedua gambar tersebut. </p>',
            question:[
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N11,
                            text:'A',
                            value:1,
                            checked:false,
                        },
                        {
                            img:image_import.cfit.S2N12,
                            text:'B',
                            value:2,
                            checked:false,
                            
                        },
                        {
                            img:image_import.cfit.S2N13,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N14,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N15,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N21,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N22,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N23,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N24,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N25,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N31,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N32,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N33,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N34,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N35,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N41,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N42,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N43,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N44,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N45,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N51,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N52,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N53,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N54,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N55,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N61,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N62,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N63,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N64,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N65,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N71,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N72,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N73,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N74,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N75,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N81,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N82,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N83,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N84,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N85,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N91,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N92,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N93,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N94,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N95,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N101,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N102,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N103,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N104,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N105,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N111,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N112,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N113,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N114,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N115,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N121,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N122,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N123,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N124,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N125,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N131,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N132,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N133,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N134,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N135,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
                {
                    type_options:'checkbox',
                    question_img:null,
                    answer:[],
                    question_answer:[
                        {
                            img:image_import.cfit.S2N141,
                            text:'A',
                            checked:false,

                            value:1
                        },
                        {
                            img:image_import.cfit.S2N142,
                            text:'B',
                            checked:false,

                            value:2
    
                        },
                        {
                            img:image_import.cfit.S2N143,
                            text:'C',
                            checked:false,

                            value:3
    
                        },
                        {
                            img:image_import.cfit.S2N144,
                            text:'D',
                            checked:false,

                            value:4
    
                        },
                        {
                            img:image_import.cfit.S2N145,
                            text:'E',
                            checked:false,

                            value:5
                        },
                    ]
                },
            ],
            intruction:[
                {
                    example_img:image_import.cfit.S2C1,
                    answer:'B dan D'
                },
                {
                    example_img:image_import.cfit.S2C2,
                    answer:'C dan E'
                },
            ],
        },
        {
            sub:3,
            type:'welcome',
            desc:'<p class="instruksi-text">Pada tahap ini, anda akan melihat 4 buah kotak yang berhubungan seperti bentuk matriks. Anda diminta untuk mencari kotak keempat yang belum ditemukan di antara pilihan A - E dengan melihat hubungan antara kedua kotak di atas dan hubungan di antara kedua kotak di sebelah kiri. Untuk lebih jelasnya, silakan perhatikan contoh di bawah ini.</p>',
            question:[],
            intruction:[
                {
                    example_img:image_import.cfit.S3C1,
                    answer:'B'
                },
                {
                    example_img:image_import.cfit.S3C2,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.S3C3,
                    answer:'F'
                },
            ],
        },
        {
            sub:3,
            type:'assestment',
            duration:180000,
            // duration:10000,
            start_time:null,
            desc:'<p class="instruksi-text">Pada tahap ini, anda akan melihat 4 buah kotak yang berhubungan seperti bentuk matriks. Anda diminta untuk mencari kotak keempat yang belum ditemukan di antara pilihan A - E dengan melihat hubungan antara kedua kotak di atas dan hubungan di antara kedua kotak di sebelah kiri. Untuk lebih jelasnya, silakan perhatikan contoh di bawah ini.</p>',
            question:[
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N1,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N11,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N12,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N13,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N14,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N15,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N16,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N2,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N21,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N22,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N23,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N24,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N25,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N26,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N3,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N31,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N32,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N33,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N34,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N35,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N36,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N4,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N41,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N42,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N43,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N44,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N45,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N46,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N5,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N51,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N52,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N53,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N54,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N55,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N56,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N6,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N61,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N62,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N63,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N64,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N65,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N66,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N7,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N71,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N72,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N73,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N74,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N75,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N76,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N8,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N81,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N82,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N83,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N84,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N85,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N86,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N9,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N91,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N92,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N93,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N94,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N95,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N96,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N10,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N101,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N102,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N103,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N104,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N105,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N106,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N_11,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N111,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N112,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N113,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N114,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N115,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N116,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N_12,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N121,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N122,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N123,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N124,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N125,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N126,
                            text:'F',
                            value:6
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S3N_13,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S3N131,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S3N132,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S3N133,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S3N134,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S3N135,
                            text:'E',
                            value:5
                        },
                        {
                            img:image_import.cfit.S3N136,
                            text:'F',
                            value:6
                        },
                    ]
                },
            ],
            intruction:[
                {
                    example_img:image_import.cfit.S3C1,
                    answer:'B'
                },
                {
                    example_img:image_import.cfit.S3C2,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.S3C3,
                    answer:'F'
                },
            ],
        },
        {
            sub:4,
            type:'welcome',
            desc:'<p class="instruksi-text">Pada tahap ini, di setiap nomornya anda akan melihat beberapa bentuk yang saling berkaitan. Anda juga akan melihat ada sebuah titik di antara bentuk-bentuk tersebut. Perhatikan posisi titik tersebut dalam hubungannya dengan bentuk-bentuk lain yang ada. Anda diminta mencari dari pilihan jawaban yang ada, di mana anda dapat menaruh titik dengan hubungan yang sama dengan bentuk-bentuk tersebut. Silakan perhatikan contoh 1 di bawah ini. Dalam contoh ini, titiknya berada di dalam persegi dan di luar lingkaran. Di antara pilihan yang ada, hanya pada gambar C anda dapat menaruh titik di dalam persegi dan di luar lingkaran, karena itu C adalah jawaban yang benar untuk pertanyaan ini.</p>',
            question:[],
            intruction:[
                {
                    example_img:image_import.cfit.S4C1,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.S4C2,
                    answer:'D'
                },
                {
                    example_img:image_import.cfit.S4C3,
                    answer:'B'
                },
            ],
        },
        {
            sub:4,
            type:'assestment',
            duration:150000,
            // duration:10000,
            start_time:null,
            desc:'<p class="instruksi-text">Pada tahap ini, di setiap nomornya anda akan melihat beberapa bentuk yang saling berkaitan. Anda juga akan melihat ada sebuah titik di antara bentuk-bentuk tersebut. Perhatikan posisi titik tersebut dalam hubungannya dengan bentuk-bentuk lain yang ada. Anda diminta mencari dari pilihan jawaban yang ada, di mana anda dapat menaruh titik dengan hubungan yang sama dengan bentuk-bentuk tersebut. Silakan perhatikan contoh 1 di bawah ini. Dalam contoh ini, titiknya berada di dalam persegi dan di luar lingkaran. Di antara pilihan yang ada, hanya pada gambar C anda dapat menaruh titik di dalam persegi dan di luar lingkaran, karena itu C adalah jawaban yang benar untuk pertanyaan ini.</p>',
            question:[
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N1,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N11,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N12,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N13,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N14,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N15,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N2,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N21,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N22,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N23,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N24,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N25,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N3,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N31,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N32,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N33,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N34,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N35,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N4,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N41,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N42,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N43,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N44,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N45,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N5,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N51,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N52,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N53,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N54,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N55,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N6,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N61,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N62,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N63,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N64,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N65,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N7,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N71,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N72,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N73,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N74,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N75,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N8,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N81,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N82,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N83,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N84,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N85,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N9,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N91,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N92,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N93,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N94,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N95,
                            text:'E',
                            value:5
                        },
                    ]
                },
                {
                    type_options:'radio',
                    question_img:image_import.cfit.S4N10,
                    answer:0,
                    question_answer:[
                        {
                            img:image_import.cfit.S4N101,
                            text:'A',
                            value:1
                        },
                        {
                            img:image_import.cfit.S4N102,
                            text:'B',
                            value:2
    
                        },
                        {
                            img:image_import.cfit.S4N103,
                            text:'C',
                            value:3
    
                        },
                        {
                            img:image_import.cfit.S4N104,
                            text:'D',
                            value:4
    
                        },
                        {
                            img:image_import.cfit.S4N105,
                            text:'E',
                            value:5
                        },
                    ]
                },
            ],
            intruction:[
                {
                    example_img:image_import.cfit.S4C1,
                    answer:'C'
                },
                {
                    example_img:image_import.cfit.S4C2,
                    answer:'D'
                },
                {
                    example_img:image_import.cfit.S4C3,
                    answer:'C'
                },
            ],
        },

    ],
    papi:[
        {
            sub:1,
            type:'welcome',
            desc:'<p class="instruksi-text">Di dalam test ini terdapat 90 pasang pernyataan. Pilihlah satu pernyataan dari pasangan pernyataan itu yang anda rasakan paling mendekati gambaran diri anda, atau yang paling menunjukkan perasaan anda.<br/><br/>Kadang-kadang anda merasa bahwa kedua pernyataan itu tidak sesuai benar dengan diri anda, namun demikian anda diminta tetap memilih satu pernyataan yang paling menunjukkan diri anda. <br/><br/>Centang pada kotak disamping pernyataan yang anda pilih</p>',
            question:[],
            intruction:[],
        },
        {
            sub:1,
            type:'assestment',
            duration:1200000,
            desc:'<p class="instruksi-text">Di dalam buku ini terdapat 90 pasang pernyataan. Pilihlah satu pernyataan dari pasangan pernyataan itu yang anda rasakan paling mendekati gambaran diri anda, atau yang paling menunjukkan perasaan anda.<br/><br/>Kadang-kadang anda merasa bahwa kedua pernyataan itu tidak sesuai benar dengan diri anda, namun demikian anda diminta tetap memilih satu pernyataan yang paling menunjukkan diri anda. <br/><br/>Centang pada kotak disamping pernyataan yang anda pilih</p>',
            question:[
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya seorang pekerja “keras”"
                        },
                        {
                            id:2,
                            text:"Saya bukan seorang pemurung"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka bekerja lebih baik dari orang lain"
                        },
                        {
                            id:2,
                            text:"Saya suka mengerjakan apa yang sedang kerjakan sampai selesai"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka mengerjakan apa yang sedang kerjakan sampai selesai"
                        },
                        {
                            id:2,
                            text:"Saya ingin bekerja sebaik mungkin"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya ingin bekerja sebaik mungkin"
                        },
                        {
                            id:2,
                            text:"Saya senang mengatakan kepada orang lain, apa yang harus dilakukannya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka menggabungkan diri dengan kelompok-kelompok"
                        },
                        {
                            id:2,
                            text:"Saya suka diperhatikan oleh kelompok-kelompok"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang bersahabat intim dengan seseorang"
                        },
                        {
                            id:2,
                            text:"Saya senang bersahabat dengan sekelompok orang"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya cepat berubah bila hal itu diperlukan"
                        },
                        {
                            id:2,
                            text:"Saya berusaha untuk intim dengan teman-teman"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka membalas dendam bila saya benar-benar disakiti"
                        },
                        {
                            id:2,
                            text:"Saya suka melakukan hal-hal yang baru dan berbeda"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya ingin atasan saya menyukai saya"
                        },
                        {
                            id:2,
                            text:"Saya suka mengatakan kepada orang lain, bila mereka salah"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka mengikuti perintah-perintah yang diberikan  kepada saya"
                        },
                        {
                            id:2,
                            text:"Saya suka menyenangkan hati orang yang memimpin saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya mencoba sekuat tenaga"
                        },
                        {
                            id:2,
                            text:"Saya seorang yang tertib. Saya meletakkan segala sesuatu pada tempatnya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya membuat orang lain melakukan apa yang saya inginkan"
                        },
                        {
                            id:2,
                            text:"Saya bukan orang yang cepat gusar"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka mengatakan kepada kelompok, apa yang harus dilakukan"
                        },
                        {
                            id:2,
                            text:"Saya menekuni satu pekerjaan sampai selesai"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya ingin tampak bersemangat dan menarik"
                        },
                        {
                            id:2,
                            text:"Saya ingin menjadi sangat sukses"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka menyelaraskan diri dengan kelompok"
                        },
                        {
                            id:2,
                            text:"Saya suka membantu orang lain menentukan pendapatnya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya cemas kalau orang lain tidak menyukai saya"
                        },
                        {
                            id:2,
                            text:"Saya senang kalau orang-orang memperhatikan saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka mencoba sesuatu yang baru"
                        },
                        {
                            id:2,
                            text:"Saya lebih suka bekerja bersama orang-orang daripada bekerja sendiri"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Kadang-kadang saya menyalahkan orang lain bila terjadi sesuatu kesalahan"
                        },
                        {
                            id:2,
                            text:"Saya cemas bila seseorang tidak menyukai saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka menyenangkan hati orang yang memimpin saya"
                        },
                        {
                            id:2,
                            text:"Saya suka mencoba pekerjaan-pekerjaan yang baru dan berbeda"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai petunjuk yang terinci untuk melakukan sesuatu pekerjaan"
                        },
                        {
                            id:2,
                            text:"Saya suka mengatakan kepada orang lain bila mereka mengganggu saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya selalu mencoba sekuat tenaga"
                        },
                        {
                            id:2,
                            text:"Saya senang bekerja dengan sangat cermat dan hati-hati"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya adalah seorang pemimpin yang baik"
                        },
                        {
                            id:2,
                            text:"Saya mengorganisir tugas-tugas secara baik"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya mudah menjadi gusar"
                        },
                        {
                            id:2,
                            text:"Saya seorang yang lambat dalam membuat keputusan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang mengerjakan beberapa pekerjaan pada waktu yang bersamaan"
                        },
                        {
                            id:2,
                            text:"Bila dalam kelompok, saya lebih suka diam"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang bila diundang"
                        },
                        {
                            id:2,
                            text:"Saya ingin melakukan sesuatu lebih baik dari orang lain"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka berteman intim dengan teman-teman saya"
                        },
                        {
                            id:2,
                            text:"Saya suka memberi nasihat kepada orang lain"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka melakukan hal-hal yang baru dan berbeda"
                        },
                        {
                            id:2,
                            text:"Saya suka menceritakan keberhasilan saya dalam mengerjakan tugas"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Bila saya benar, saya suka mempertahankannya “mati-matian”"
                        },
                        {
                            id:2,
                            text:"Saya suka bergabung ke dalam suatu kelompok"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya tidak mau berbeda dengan orang lain"
                        },
                        {
                            id:2,
                            text:"Saya berusaha untuk sangat intim dengan orang-orang"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka diajari mengenai caranya mengerjakan suatu pekerjaan"
                        },
                        {
                            id:2,
                            text:"Saya mudah merasa jemu (bosan)"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya bekerja keras"
                        },
                        {
                            id:2,
                            text:"Saya banyak berpikir dan berencana"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya memimpin kelompok"
                        },
                        {
                            id:2,
                            text:"Hal-hal yang kecil (detail) menarik hati saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya cepat dan mudah mengambil keputusan"
                        },
                        {
                            id:2,
                            text:"Saya meletakkan segala sesuatu secara rapih dan teratur"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Tugas-tugas saya kerjakan secara cepat"
                        },
                        {
                            id:2,
                            text:"Saya jarang  marah atau sedih"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya ingin menjadi bagian dari kelompok"
                        },
                        {
                            id:2,
                            text:"Pada suatu waktu tertentu, saya hanya ingin mengerjakan satu tugas saja"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya berusaha untuk intim dengan teman-teman saya"
                        },
                        {
                            id:2,
                            text:"Saya berusaha keras untuk menjadi yang terbaik"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai mode baju baru dan tipe-tipe mobil terbaru"
                        },
                        {
                            id:2,
                            text:"Saya ingin menjadi penanggung jawab bagi orang-orang lain"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka berdebat"
                        },
                        {
                            id:2,
                            text:"Saya ingin diperhatikan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka menyenangkan hati orang yang memimpin saya"
                        },
                        {
                            id:2,
                            text:"Saya tertarik menjadi anggota dari suatu kelompok"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang mengikuti aturan secara tertib"
                        },
                        {
                            id:2,
                            text:"Saya suka orang-orang mengenal saya benar-benar."
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya mencoba sekuat tenaga"
                        },
                        {
                            id:2,
                            text:"Saya sangat menyenangkan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Orang lain beranggapan bahwa saya adalah seorang pemimpin yang baik"
                        },
                        {
                            id:2,
                            text:"Saya berpikir jauh ke depan dan terinci"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Seringkali saya memanfaatkan peluang"
                        },
                        {
                            id:2,
                            text:"Saya senang memperhatikan hal-hal sampai sekecil-kecilnya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Orang lain menganggap saya bekerja cepat"
                        },
                        {
                            id:2,
                            text:"Orang lain menganggap saya dapat melakukan penataan yang rapi dan teratur"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai permainan-permainan dan olahraga"
                        },
                        {
                            id:2,
                            text:"Saya sangat menyenangkan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang bila orang-orang dapat intim dan bersahabat"
                        },
                        {
                            id:2,
                            text:"Saya selalu berusaha menyelesaikan apa yang telah saya mulai"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka bereksperimen dan mencoba sesuatu yang baru"
                        },
                        {
                            id:2,
                            text:"Saya suka mengerjakan pekerjaan-pekerjaan yang sulit dengan baik"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang diperlakukan  secara adil"
                        },
                        {
                            id:2,
                            text:"Saya senang mengajari orang lain bagaimana caranya mengerjakan sesuatu"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka mengerjakan apa yang diharapkan dari saya"
                        },
                        {
                            id:2,
                            text:"Saya suka menarik perhatian"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka petunjuk-petunjuk terinci dalam melaksanakan pekerjaan"
                        },
                        {
                            id:2,
                            text:"Saya senang berada bersama dengan orang lain"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya selalu berusaha mengerjakan tugas secara sempurna"
                        },
                        {
                            id:2,
                            text:"Orang lain menganggap, saya tidak mengenal lelah, dalam kerja sehari-hari"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya tergolong tipe pemimpin"
                        },
                        {
                            id:2,
                            text:"Saya mudah berteman"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya memanfaatkan peluang-peluang"
                        },
                        {
                            id:2,
                            text:"Saya banyak berfikir"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya bekerja dengan kecepatan yang mantap dan cepat"
                        },
                        {
                            id:2,
                            text:"Saya senang mengerjakan hal-hal yang detail"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya memiliki banyak energi untuk permainan-permainan dan olahraga"
                        },
                        {
                            id:2,
                            text:"Saya selalu ingin menyelesaikan pekerjaan yang sudah saya mulai"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya bergaul baik dengan semua orang"
                        },
                        {
                            id:2,
                            text:"Saya pandai mengendalikan diri"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya ingin berkenalan dengan orang-orang baru dan mengerjakan hal baru"
                        },
                        {
                            id:2,
                            text:"Saya selalu ingin menyelesaikan pekerjaan yang sudah saya mulai"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya saya bersikeras mengenai apa yang saya yakini"
                        },
                        {
                            id:2,
                            text:"Biasanya saya suka bekerja keras"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai saran-saran dari orang-orang yang saya kagumi"
                        },
                        {
                            id:2,
                            text:"Saya senang mengatur orang lain"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya biarkan orang-orang lain mempengaruhi saya"
                        },
                        {
                            id:2,
                            text:"Saya suka menerima banyak perhatian."
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya saya bekerja sangat keras"
                        },
                        {
                            id:2,
                            text:"Biasanya saya bekerja cepat"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Bila saya berbicara, kelompok akan mendengarkan"
                        },
                        {
                            id:2,
                            text:"Saya terampil menggunakan alat-alat kerja"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya lambat dalam membina persahabatan"
                        },
                        {
                            id:2,
                            text:"Saya lambat dalam mengambil keputusan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya saya makan secara cepat"
                        },
                        {
                            id:2,
                            text:"Saya suka membaca"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai pekerjaan yang memungkinkan saya berkeliling"
                        },
                        {
                            id:2,
                            text:"Saya menyukai pekerjaan yang harus dilakukan secara teliti"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya berteman sebanyak mungkin"
                        },
                        {
                            id:2,
                            text:"Saya dapat menemukan hal-hal yang telah saya pindahkan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Perencanaan saya jauh ke masa depan"
                        },
                        {
                            id:2,
                            text:"Saya selalu menyenangkan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya merasa bangga akan nama baik saya"
                        },
                        {
                            id:2,
                            text:"Saya tetap menekuni satu permasalahan sampai ia terselesaikan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka menyenangkan hati orang-orang yang saya kagumi"
                        },
                        {
                            id:2,
                            text:"Saya suka menjadi seorang yang berhasil"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang bila orang-orang lain mengambil keputusan untuk kelompok"
                        },
                        {
                            id:2,
                            text:"Saya suka mengambil keputusan untuk kelompok"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya selalu berusaha sangat keras"
                        },
                        {
                            id:2,
                            text:"Saya cepat dan mudah mengambil keputusan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya kelompok saya mengerjakan hal-hal yang saya inginkan"
                        },
                        {
                            id:2,
                            text:"Biasanya saya tergesa-gesa"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya seringkali merasa lelah"
                        },
                        {
                            id:2,
                            text:"Saya lambat di dalam mengambil keputusan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya bekerja secara cepat"
                        },
                        {
                            id:2,
                            text:"Saya mudah mendapat kawan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya saya bersemangat atau bergairah"
                        },
                        {
                            id:2,
                            text:"Sebagian besar waktu saya untuk berpikir"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya sangat hangat kepada orang-orang"
                        },
                        {
                            id:2,
                            text:"Saya menyukai pekerjaan yang menuntut ketepatan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya banyak berpikir dan merencana"
                        },
                        {
                            id:2,
                            text:"Saya meletakkan segala sesuatu pada tempatnya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka tugas yang perlu ditekuni sampai kepada hal sedetilnya"
                        },
                        {
                            id:2,
                            text:"Saya tidak cepat marah"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang mengikuti orang-orang yang saya kagumi"
                        },
                        {
                            id:2,
                            text:"Saya selalu menyelesaikan pekerjaan yang saya kagumi"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya menyukai petunjuk-petunjuk yang jelas"
                        },
                        {
                            id:2,
                            text:"Saya suka bekerja keras"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya mengejar apa yang saya inginkan"
                        },
                        {
                            id:2,
                            text:"Saya adalah seorang pemimpin yang baik"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya membuat orang lain bekerja keras"
                        },
                        {
                            id:2,
                            text:"Saya adalah seorang yang gampangan (tak banyak pertimbangan)"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya membuat keputusan-keputusan secara cepat "
                        },
                        {
                            id:2,
                            text:"Bicara saya cepat"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Biasanya saya bekerja tergesa-gesa"
                        },
                        {
                            id:2,
                            text:"Secara teratur saya berolahraga"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya tidak suka bertemu dengan orang-orang"
                        },
                        {
                            id:2,
                            text:"Saya cepat lelah"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya mempunyai banyak sekali teman"
                        },
                        {
                            id:2,
                            text:"Banyak waktu saya untuk berpikir "
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka bekerja dengan teori"
                        },
                        {
                            id:2,
                            text:"Saya suka bekerja sedetil-detilnya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya suka bekerja sampai sedetil-detilnya"
                        },
                        {
                            id:2,
                            text:"Saya suka mengorganisir pekerjaan saya"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya meletakkan segala sesuatu pada tempatnya"
                        },
                        {
                            id:2,
                            text:"Saya selalu menyenangkan"
                        },
                    ]
                },
                {
                    answer:0,
                    item:[
                        {
                            id:1,
                            text:"Saya senang diberi petunjuk mengenai apa yang harus saya lakukan"
                        },
                        {
                            id:2,
                            text:"Saya harus menyelesaikan apa yang sudah saya mulai"
                        },
                    ]
                },
                
            ]
        }
    ],
    toefl:[
        {
            sub:1,
            duration:2400000,
            // duration:20000,
            type:'part',
            session:'listening',
            desc:'<p class="instruksi-text">Di dalam buku ini terdapat 90 pasang pernyataan. Pilihlah satu pernyataan  dari pasangan pernyataan itu yang adan rasakan paling mendekati gambaran diri anda, atau yang paling menunjukkan perasaan anda.<br/><br/>Kadang-kadang anda merasa bahwa kedua pernyataan itu tidak sesuai benar dengan diri anda, namun demikian anda diminta tetap memilih satu pernyataan yang paling menunjukkan diri anda. <br/><br/>Centang pada kotak disamping pernyataan yang anda pilih</p>',
            question:[],
            intruction:[],
            start_time:null,
            part:[
                {
                    part:1,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>Section 1 : Listening Comprehension</b></p><p class="intruksi-text">TIME APPROXIMATELY 40 MINUTES (50 QUESTIONS)<br/>In this section of the test, you will have an opportunity to demonstrate your ability to understand conversations and talks in English. There are three parts to this section with special directions for each part. Answer all the questions on the basis of what is stated or implied by the speakers in this test. <br/>In order to complete this section, make sure you turn on the sound in your device.</p><p class="intruksi-text"><b>Part A<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part A, you will hear short conversations between two people. After each conversation, you will hear a question about the conversation. The conversations and questions will not be repeated. After you hear a question, read the four possible answers in on the screen and choose the best answer. </p><p class="intruksi-text">Now begin work on the questions. </p></div>'
                },
                {
                    part:1,
                    type:'assestment',
                    title_desc_modal:'Instructions Section 1 Listening Comprehension',
                    desc:'<div><p class="intruksi-text"><b>Part A<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part A, you will hear short conversations between two people. After each conversation, you will hear a question about the conversation. The conversations and questions will not be repeated. After you hear a question, read the four possible answers in on the screen and choose the best answer. </p><p class="intruksi-text">Now begin work on the questions. </p></div>',
                    question:[
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n101.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:1,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Her first day was better than she thought.',
                                            value:1
                                        },
                                        {
                                            text:'Her first day was dull.',
                                            value:2
                                        },
                                        {
                                            text:'Her first day was okay.',
                                            value:3
                                        },
                                        {
                                            text:'Her first day was worse than she thought.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n102.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:2,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'She feels sleepy.',
                                            value:1
                                        },
                                        {
                                            text:'She lives in a city that never sleeps.',
                                            value:2
                                        },
                                        {
                                            text:'She lives in a small, quiet city.',
                                            value:3
                                        },
                                        {
                                            text:'She wants to move to a bigger city.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n103.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:3,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He could not go to the movie.',
                                            value:1
                                        },
                                        {
                                            text:'He does not want to watch the movie.',
                                            value:2
                                        },
                                        {
                                            text:'He will pass the theatre on his way home.',
                                            value:3
                                        },
                                        {
                                            text:'He will stand in the line to buy the tickets.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n104.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:4,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Elena fell down from the stairs.',
                                            value:1
                                        },
                                        {
                                            text:"Elena failed to bring Eric's shoes.",
                                            value:2
                                        },
                                        {
                                            text:'Eric broke up with Elena.',
                                            value:3
                                        },
                                        {
                                            text:'Eric talked about her scores.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n105.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:5,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He wants the woman to give him money.',
                                            value:1
                                        },
                                        {
                                            text:'He wants to give the woman money.',
                                            value:2
                                        },
                                        {
                                            text:'He wants to know when the upcoming exam is.',
                                            value:3
                                        },
                                        {
                                            text:"He wants to know what the woman's thinking.",
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n106.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:6,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Help Professor Mitchell with his proposal.',
                                            value:1
                                        },
                                        {
                                            text:'Meet Professor Mitchell as soon as possible.',
                                            value:2
                                        },
                                        {
                                            text:'Propose to Professor Mitchell.',
                                            value:3
                                        },
                                        {
                                            text:'Submit her proposal.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n107.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:7,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He forgot.',
                                            value:1
                                        },
                                        {
                                            text:'He was sick.',
                                            value:2
                                        },
                                        {
                                            text:'He was trapped in a storm.',
                                            value:3
                                        },
                                        {
                                            text:'The weather was bad.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n108.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:8,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Dr. Nelson has just arrived in his office.',
                                            value:1
                                        },
                                        {
                                            text:'Dr. Nelson just left his office.',
                                            value:2
                                        },
                                        {
                                            text:'Dr. Nelson will arrive soon.',
                                            value:3
                                        },
                                        {
                                            text:'Dr. Nelson will step out to meet the woman.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n109.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:9,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He accidentally met Samantha.',
                                            value:1
                                        },
                                        {
                                            text:'He did not remember who Samantha is.',
                                            value:2
                                        },
                                        {
                                            text:"He is curious on Samantha's news.",
                                            value:3
                                        },
                                        {
                                            text:'He ran with Samantha to the bus station.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n110.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:10,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Sarah cannot speak French.',
                                            value:1
                                        },
                                        {
                                            text:'Sarah lived in French during high school.',
                                            value:2
                                        },
                                        {
                                            text:'Sarah studied French in high school.',
                                            value:3
                                        },
                                        {
                                            text:'Sarah will learn French in France.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n111.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:11,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The project is cancelled.',
                                            value:1
                                        },
                                        {
                                            text:'The project is going well as planned.',
                                            value:2
                                        },
                                        {
                                            text:'The project spent less than what is budgeted.',
                                            value:3
                                        },
                                        {
                                            text:'The project spent more than what is budgeted.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n112.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:12,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'To cancel the interview on her behalf.',
                                            value:1
                                        },
                                        {
                                            text:"To check the woman's car.",
                                            value:2
                                        },
                                        {
                                            text:'To lend his car to the woman.',
                                            value:3
                                        },
                                        {
                                            text:'To take the woman to the interview place.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n113.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:13,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"Ask for Lucas' help.",
                                            value:1
                                        },
                                        {
                                            text:"Ask for the woman's help.",
                                            value:2
                                        },
                                        {
                                            text:'Pass the subject with an excellent grade.',
                                            value:3
                                        },
                                        {
                                            text:'Try to do the assignment by himself.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n114.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:14,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'To buy a lemon.',
                                            value:1
                                        },
                                        {
                                            text:'To clean it with lemon and baking soda.',
                                            value:2
                                        },
                                        {
                                            text:'To drink soda.',
                                            value:3
                                        },
                                        {
                                            text:'To soak it in lemon and soda.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n115.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:15,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"Eva didn't play in the movie.",
                                            value:1
                                        },
                                        {
                                            text:'Eva played a small role in the background of the movie.',
                                            value:2
                                        },
                                        {
                                            text:'Eva was the lead actress of the movie.',
                                            value:3
                                        },
                                        {
                                            text:'Eva was the producer of the movie.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n116.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:16,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The man did not really work in a circus.',
                                            value:1
                                        },
                                        {
                                            text:'The man wants to work in a circus.',
                                            value:2
                                        },
                                        {
                                            text:'The man worked in a circus as a make-up artist.',
                                            value:3
                                        },
                                        {
                                            text:'The man wrote a story about circus.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n117.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:17,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Kate did not have the time to do bungee jumping during her last vacation.',
                                            value:1
                                        },
                                        {
                                            text:"Kate is afraid of height, so she doesn't want to do bungee jumping.",
                                            value:2
                                        },
                                        {
                                            text:'Kate lost her money while doing bungee jumping.',
                                            value:3
                                        },
                                        {
                                            text:'Kate wanted to do bungee jumping.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n118.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:18,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He has to report his missing luggage to the Lost & Found.',
                                            value:1
                                        },
                                        {
                                            text:'He left his luggage at home.',
                                            value:2
                                        },
                                        {
                                            text:'His luggage is found by the Lost & Found Officer.',
                                            value:3
                                        },
                                        {
                                            text:'His luggage is still missing.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n119.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:19,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The game finished early.',
                                            value:1
                                        },
                                        {
                                            text:'The game was cancelled.',
                                            value:2
                                        },
                                        {
                                            text:'The game was postponed.',
                                            value:3
                                        },
                                        {
                                            text:'The game was still going despite the rain.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n120.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:20,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Annoyed.',
                                            value:1
                                        },
                                        {
                                            text:'Bored.',
                                            value:2
                                        },
                                        {
                                            text:'Excited.',
                                            value:3
                                        },
                                        {
                                            text:'Indifferent.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n121.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:21,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"Jack's hometown is a two-day drive.",
                                            value:1
                                        },
                                        {
                                            text:'Jack will be in his hometown for two days.',
                                            value:2
                                        },
                                        {
                                            text:'Jack will meet them soon.',
                                            value:3
                                        },
                                        {
                                            text:'Jack will wait for them in his hometown.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n122.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:22,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"Her watch's warranty has expired.",
                                            value:1
                                        },
                                        {
                                            text:'Her watch is being repaired.',
                                            value:2
                                        },
                                        {
                                            text:'Her watch is sold to the store.',
                                            value:3
                                        },
                                        {
                                            text:'Her watch is still under warranty.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n123.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:23,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Samuel is trying to manage his anger.',
                                            value:1
                                        },
                                        {
                                            text:'Samuel just got angry with his teammates.',
                                            value:2
                                        },
                                        {
                                            text:'Samuel will be kicked out off the team.',
                                            value:3
                                        },
                                        {
                                            text:'Samuel will join an audition for a spot in the team.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n124.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:24,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"The woman didn't wear glasses.",
                                            value:1
                                        },
                                        {
                                            text:'The woman just got her eyes checked.',
                                            value:2
                                        },
                                        {
                                            text:'The woman looks beautiful with glasses.',
                                            value:3
                                        },
                                        {
                                            text:'The woman should wear glasses.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n125.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:25,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"Borrow the woman's finished work.",
                                            value:1
                                        },
                                        {
                                            text:'Finish his work as soon as possible.',
                                            value:2
                                        },
                                        {
                                            text:'Look for his phone.',
                                            value:3
                                        },
                                        {
                                            text:'Write it on his agenda.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n126.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:26,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Ask another person.',
                                            value:1
                                        },
                                        {
                                            text:'Find a taxi to the courthouse.',
                                            value:2
                                        },
                                        {
                                            text:'Find the direction on her own.',
                                            value:3
                                        },
                                        {
                                            text:'Follow the man along.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n127.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:27,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"He doesn't like both colors.",
                                            value:1
                                        },
                                        {
                                            text:'He likes both colors.',
                                            value:2
                                        },
                                        {
                                            text:'He prefers the blue one.',
                                            value:3
                                        },
                                        {
                                            text:'He prefers the red one.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n128.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:28,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Professor Mitchell has never given assignment.',
                                            value:1
                                        },
                                        {
                                            text:'Professor Mitchell rarely gives assignment.',
                                            value:2
                                        },
                                        {
                                            text:'Professor Mitchell seldom gives assignment.',
                                            value:3
                                        },
                                        {
                                            text:'Professor Mitchell always gives assignment.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n129.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:29,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The woman failed in convincing her client.',
                                            value:1
                                        },
                                        {
                                            text:'The woman gave up in convincing her client.',
                                            value:2
                                        },
                                        {
                                            text:'The woman is still convincing her client.',
                                            value:3
                                        },
                                        {
                                            text:'The woman succeeded in convincing her client.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n130.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:30,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'He bought the CD because of the cover.',
                                            value:1
                                        },
                                        {
                                            text:'He bought the CD because of the music',
                                            value:2
                                        },
                                        {
                                            text:'He bought the CD because the woman asked him to.',
                                            value:3
                                        },
                                        {
                                            text:'He bought the CD of his favorite singer.',
                                            value:4
                                        }
                                    ]
                                }                                
                            ]
                        },
                    ]
                },
                {
                    part:2,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>Section 1 : Listening Comprehension</b></p><p class="intruksi-text"><b>Part B<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part B, you will hear longer conversations. After each conversation, you will be asked some questions. The conversations and questions will be spoken just one time. They will not be written out for you, so you will have to listen carefully in order to understand and remember what the speaker says. <br/><br/>When you hear a question, read the four possible answers on the screen and decide which one would be the best answer to the question you have heard. Then, select the letter of the answer you have chosen. </p></div>'
                },
                {
                    part:2,
                    type:'assestment',
                    title_desc_modal:'How to take the English Proficiency  Test process ?',
                    desc:'<div><p class="instruksi-text"><b>Section 1 : Listening Comprehension</b></p><p class="intruksi-text"><b>Part B<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part B, you will hear longer conversations. After each conversation, you will be asked some questions. The conversations and questions will be spoken just one time. They will not be written out for you, so you will have to listen carefully in order to understand and remember what the speaker says. <br/><br/>When you hear a question, read the four possible answers on the screen and decide which one would be the best answer to the question you have heard. Then, select the letter of the answer you have chosen. </p></div>',
                    question:[
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n231.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:31,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'How to take the English Ability Test process?',
                                            value:1
                                        },
                                        {
                                            text:"To find out the woman's free time.",
                                            value:2
                                        },
                                        {
                                            text:'To review the CSR project.',
                                            value:3
                                        },
                                        {
                                            text:'To schedule a meeting.',
                                            value:4
                                        }
                                    ]
                                },                                
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n232.mp3',
                            can_play:true,
                            parrent:31,
                            audio_question:[
                                {
                                    number:32,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'To review about the CSR project.',
                                            value:1
                                        },
                                        {
                                            text:"To review the man's job performance.",
                                            value:2
                                        },
                                        {
                                            text:"To review the woman's performance.",
                                            value:3
                                        },
                                        {
                                            text:'To review their personal project.',
                                            value:4
                                        }
                                    ]
                                },             
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n233.mp3',
                            can_play:true,
                            parrent:31,
                            audio_question:[
                                {
                                    number:33,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Company Social Responsibility.',
                                            value:1
                                        },
                                        {
                                            text:"Company Society Responsibility.",
                                            value:2
                                        },
                                        {
                                            text:"Corporate Social Responsibility.",
                                            value:3
                                        },
                                        {
                                            text:'Corporate Society Responsibility.',
                                            value:4
                                        }
                                    ]
                                },                
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n234.mp3',
                            can_play:true,
                            parrent:31,
                            audio_question:[
                                {
                                    number:34,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Monday',
                                            value:1
                                        },
                                        {
                                            text:"Wednesday",
                                            value:2
                                        },
                                        {
                                            text:"Thursday",
                                            value:3
                                        },
                                        {
                                            text:'Friday',
                                            value:4
                                        }
                                    ]
                                },               
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n235.mp3',
                            can_play:true,
                            parrent:31,
                            audio_question:[
                                {
                                    number:35,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'10:00 AM',
                                            value:1
                                        },
                                        {
                                            text:"10:30 AM",
                                            value:2
                                        },
                                        {
                                            text:"11:00 AM",
                                            value:3
                                        },
                                        {
                                            text:'11:30 AM',
                                            value:4
                                        }
                                    ]
                                },           
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n236.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:36,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'In a mall.',
                                            value:1
                                        },
                                        {
                                            text:"In a theatre.",
                                            value:2
                                        },
                                        {
                                            text:"In a travel agent office.",
                                            value:3
                                        },
                                        {
                                            text:"In the man's office.",
                                            value:4
                                        }
                                    ]
                                },           
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n237.mp3',
                            can_play:true,
                            parrent:36,
                            audio_question:[
                                {
                                    number:37,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The 22nd',
                                            value:1
                                        },
                                        {
                                            text:"The 25th",
                                            value:2
                                        },
                                        {
                                            text:"The 28th",
                                            value:3
                                        },
                                        {
                                            text:'The 30th',
                                            value:4
                                        }
                                    ]
                                },           
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n238.mp3',
                            can_play:true,
                            parrent:36,
                            audio_question:[
                                {
                                    number:38,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'To issue them on the same day.',
                                            value:1
                                        },
                                        {
                                            text:"To leave the time open.",
                                            value:2
                                        },
                                        {
                                            text:"Will be booked separately by his colleague.",
                                            value:3
                                        },
                                        {
                                            text:'Will be paid later on.',
                                            value:4
                                        }
                                    ]
                                },           
                                                      
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n239.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:39,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'USD 335.',
                                            value:1
                                        },
                                        {
                                            text:"USD 670.",
                                            value:2
                                        },
                                        {
                                            text:"USD 1,005.",
                                            value:3
                                        },
                                        {
                                            text:'USD 1,340.',
                                            value:4
                                        }
                                    ]
                                },           
                                                      
                            ]
                        },
                    ]
                },
                {
                    part:3,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>Section 1 : Listening Comprehension</b></p><p class="intruksi-text"><b>Part C<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part C, you will hear several talks. After each talk, you will be asked some questions. The talks and questions will be spoken just one time. They will not be written out for you, so you will have to listen carefully in order to understand and remember what the speaker says.<br/><br/>When you hear a question, read the four possible answers on the screen and select which one would be the best answer to the question you have heard.</p></div>'
                },
                {
                    part:3,
                    type:'assestment',
                    title_desc_modal:'How to take the English Proficiency  Test process ?',
                    desc:'<div><p class="instruksi-text"><b>Section 1 : Listening Comprehension</b></p><p class="intruksi-text"><b>Part C<br/>DIRECTIONS </b></p><p class="intruksi-text">In Part C, you will hear several talks. After each talk, you will be asked some questions. The talks and questions will be spoken just one time. They will not be written out for you, so you will have to listen carefully in order to understand and remember what the speaker says.<br/><br/>When you hear a question, read the four possible answers on the screen and select which one would be the best answer to the question you have heard.</p></div>',
                    question:[
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n340.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:40,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'To ensure that both legs are the same length.',
                                            value:1
                                        },
                                        {
                                            text:"To get a healthier lower back, leg and feet.",
                                            value:2
                                        },
                                        {
                                            text:'To maintain the leg endurance.',
                                            value:3
                                        },
                                        {
                                            text:'To reduce cramps.',
                                            value:4
                                        }
                                    ]
                                },                                
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n341.mp3',
                            can_play:true,
                            parrent:40,
                            audio_question:[
                                {
                                    number:41,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Lift the right foot.',
                                            value:1
                                        },
                                        {
                                            text:"Lower the heel to the floor.",
                                            value:2
                                        },
                                        {
                                            text:"Raise the heel as high as possible.",
                                            value:3
                                        },
                                        {
                                            text:'Straighten the leg.',
                                            value:4
                                        }
                                    ]
                                },                                
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n342.mp3',
                            can_play:true,
                            parrent:40,
                            audio_question:[
                                {
                                    number:42,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'3 seconds.',
                                            value:1
                                        },
                                        {
                                            text:"6 seconds.",
                                            value:2
                                        },
                                        {
                                            text:"9 seconds.",
                                            value:3
                                        },
                                        {
                                            text:'12 seconds.',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n343.mp3',
                            can_play:true,
                            parrent:40,
                            audio_question:[
                                {
                                    number:43,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'One time for each foot.',
                                            value:1
                                        },
                                        {
                                            text:"Two times for each foot.",
                                            value:2
                                        },
                                        {
                                            text:"Three times for each foot.",
                                            value:3
                                        },
                                        {
                                            text:'Four times for each foot.',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n344.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:44,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Floppy discs and CDs were exclusively produced for the experts.',
                                            value:1
                                        },
                                        {
                                            text:"Floppy discs and CDs were very expensive at that time.",
                                            value:2
                                        },
                                        {
                                            text:"Other users didn't know how to back up their computer.",
                                            value:3
                                        },
                                        {
                                            text:"The other users didn't have any important data to back up.",
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n345.mp3',
                            can_play:true,
                            parrent:44,
                            audio_question:[
                                {
                                    number:45,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'1',
                                            value:1
                                        },
                                        {
                                            text:"2",
                                            value:2
                                        },
                                        {
                                            text:"3",
                                            value:3
                                        },
                                        {
                                            text:'4',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n346.mp3',
                            can_play:true,
                            parrent:44,
                            audio_question:[
                                {
                                    number:46,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'The chance of getting the laptops damaged is higher.',
                                            value:1
                                        },
                                        {
                                            text:"The hard drives, now, contain too much data.",
                                            value:2
                                        },
                                        {
                                            text:"The need of using the computer is rising significantly.",
                                            value:3
                                        },
                                        {
                                            text:'The number of the computer users is increasing.',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n347.mp3',
                            can_play:true,
                            parrent:44,
                            audio_question:[
                                {
                                    number:47,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Keeping photos in photo album is considered outdated.',
                                            value:1
                                        },
                                        {
                                            text:"Now, people prefer to keep their photos on a computer.",
                                            value:2
                                        },
                                        {
                                            text:"Now, we can store photos to a computer with only one click..",
                                            value:3
                                        },
                                        {
                                            text:'Photos and memories should not be backed up too often..',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n348.mp3',
                            can_play:true,
                            parrent:null,
                            audio_question:[
                                {
                                    number:48,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'100',
                                            value:1
                                        },
                                        {
                                            text:"200",
                                            value:2
                                        },
                                        {
                                            text:"10,000",
                                            value:3
                                        },
                                        {
                                            text:'20,000',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n349.mp3',
                            can_play:true,
                            parrent:48,
                            audio_question:[
                                {
                                    number:49,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Distributing entrance tickets.',
                                            value:1
                                        },
                                        {
                                            text:"Handling inquiries by phone.",
                                            value:2
                                        },
                                        {
                                            text:"Handling the human traffic in the exhibition hall.",
                                            value:3
                                        },
                                        {
                                            text:'Stamping entrance tickets..',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                        {
                            audio_src:'https://www.onegml.com/assets/disc/media/english/n350.mp3',
                            can_play:true,
                            parrent:48,
                            audio_question:[
                                {
                                    number:50,
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'In the exhibition hall..',
                                            value:1
                                        },
                                        {
                                            text:"In the information booths.",
                                            value:2
                                        },
                                        {
                                            text:"In the kitchen.",
                                            value:3
                                        },
                                        {
                                            text:'In the parking lot.',
                                            value:4
                                        }
                                    ]
                                },                            
                                                               
                            ]
                        },
                    ]
                },
            ]
        },
        {
            sub:2,
            duration:1500000,
            // duration:20000,
            type:'part',
            session:'reading-1',
            desc:'<p class="instruksi-text">Di dalam buku ini terdapat 90 pasang pernyataan. Pilihlah satu pernyataan  dari pasangan pernyataan itu yang adan rasakan paling mendekati gambaran diri anda, atau yang paling menunjukkan perasaan anda.<br/><br/>Kadang-kadang anda merasa bahwa kedua pernyataan itu tidak sesuai benar dengan diri anda, namun demikian anda diminta tetap memilih satu pernyataan yang paling menunjukkan diri anda. <br/><br/>Centang pada kotak disamping pernyataan yang anda pilih</p>',
            question:[],
            intruction:[],
            start_time:null,
            part:[
                {
                    part:1,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>SECTION 2<br/>STRUCTURE AND WRITTEN EXPRESSION </b></p><p class="intruksi-text">TIME: APPROXIMATELY 25 MINUTES (40 QUESTIONS) <br/>This section is designed to measure your ability to recognize language that is appropriate for standard written English. There are two types of questions in this section, with special directions for each type. </div>'
                },
                {
                    part:1,
                    type:'assestment',
                    title_desc_modal:'Section 2 : Structure  and Written  Expression',
                    desc:'<div><p class="intruksi-text"><b>STRUCTURE <br/>DIRECTIONS</b></p><p class="intruksi-text">Questions 1-15 are incomplete sentences. Beneath each sentence you will see four words or phrases, marked (A), (B), (C), and (0). Choose the one word or phrase that best completes the sentence. Then, on your answer sheet, find the number of the question and fill in the space that corresponds to the letter of the answer you have chosen. Fill in the space so that the letter inside the oval cannot be seen. </p></div>',
                    question:[
                        {
                            number:1,
                            desc:'Artificial Intelligence, or Al, is different from most technologies in that scientists still understand very little about ....',
                            answer:0,
                            question_answer:[
                                {
                                    text:'how intelligence work.',
                                    value:1
                                },
                                {
                                    text:'how intelligence works.',
                                    value:2
                                },
                                {
                                    text:'on how intelligence is working.',
                                    value:3
                                },
                                {
                                    text:"the intelligence's work.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:2,
                            desc:'There is an automated deposit machine available in the self-service area ... the customers can use to deposit money, both notes and coins.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'how',
                                    value:1
                                },
                                {
                                    text:'when',
                                    value:2
                                },
                                {
                                    text:'where',
                                    value:3
                                },
                                {
                                    text:"which",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:3,
                            desc:'In general, most of the ... work is done by purchase ledger clerks and sales ledger clerks.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'bored',
                                    value:1
                                },
                                {
                                    text:'boring',
                                    value:2
                                },
                                {
                                    text:'more boring',
                                    value:3
                                },
                                {
                                    text:"more bored",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:4,
                            desc:'In Roman numerals, … symbols for numeric values.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'are letters of the alphabet',
                                    value:1
                                },
                                {
                                    text:'letters of the alphabet are',
                                    value:2
                                },
                                {
                                    text:'which uses letters of the alphabet',
                                    value:3
                                },
                                {
                                    text:"in which letters of the alphabet are",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:5,
                            desc:'The Palo Verde tree ... in spring',
                            answer:0,
                            question_answer:[
                                {
                                    text:'has beautiful yellow blossoms',
                                    value:1
                                },
                                {
                                    text:'beautiful yellow blossoms',
                                    value:2
                                },
                                {
                                    text:'having beautiful yellow blossoms',
                                    value:3
                                },
                                {
                                    text:"with beautiful yellow blossoms",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:6,
                            desc:'The great apes, a generally peaceful species, … in groups.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'would rather living',
                                    value:1
                                },
                                {
                                    text:'would rather live',
                                    value:2
                                },
                                {
                                    text:'would rather they live',
                                    value:3
                                },
                                {
                                    text:"would rather lived",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:7,
                            desc:'Refrigerating meat ... the spread of bacteria',
                            answer:0,
                            question_answer:[
                                {
                                    text:'retards',
                                    value:1
                                },
                                {
                                    text:'retarding',
                                    value:2
                                },
                                {
                                    text:'to retard',
                                    value:3
                                },
                                {
                                    text:"is retarded",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:8,
                            desc:'In English, there are many different kinds of expressions that people use to give name to anything ... name is unknown or momentarily forgotten.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'which',
                                    value:1
                                },
                                {
                                    text:'who',
                                    value:2
                                },
                                {
                                    text:'whom',
                                    value:3
                                },
                                {
                                    text:"whose",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:9,
                            desc:'Williamsburg, ..., was settled by English colonists in 1633.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'a historic city in Virginia',
                                    value:1
                                },
                                {
                                    text:'a historical city in Virginia',
                                    value:2
                                },
                                {
                                    text:'is a historic city in Virginia.',
                                    value:3
                                },
                                {
                                    text:"is a historical city in Virginia",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:10,
                            desc:'Vaccines are prepared from harmful viruses or bacteria and administered to patients ... to specific diseases.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'provide immunity',
                                    value:1
                                },
                                {
                                    text:'providing immune',
                                    value:2
                                },
                                {
                                    text:'that provides immune',
                                    value:3
                                },
                                {
                                    text:"to provide immunity",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:11,
                            desc:'… several unsuccessful attempts, Robert Peary reached the North Pole on April 6, 1909.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'After',
                                    value:1
                                },
                                {
                                    text:'He made',
                                    value:2
                                },
                                {
                                    text:'His',
                                    value:3
                                },
                                {
                                    text:"When",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:12,
                            desc:'Nowhere ... more skewed than in the auto industry.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'are retail trade figures',
                                    value:1
                                },
                                {
                                    text:'retail trade figures',
                                    value:2
                                },
                                {
                                    text:'retailing trade figures',
                                    value:3
                                },
                                {
                                    text:"that retail trade figures",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:13,
                            desc:"Each object … Jupiter's magnetic field is deluged with electrical charges.",
                            answer:0,
                            question_answer:[
                                {
                                    text:'enter',
                                    value:1
                                },
                                {
                                    text:'enters',
                                    value:2
                                },
                                {
                                    text:'entering',
                                    value:3
                                },
                                {
                                    text:"it enters",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:14,
                            desc:'One difference between Mathematics and Language is that Mathematics is precise …',
                            answer:0,
                            question_answer:[
                                {
                                    text:'but Language not',
                                    value:1
                                },
                                {
                                    text:'language is not',
                                    value:2
                                },
                                {
                                    text:'while is Language',
                                    value:3
                                },
                                {
                                    text:"while Language is not.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:15,
                            desc:'… squeezed, the orange juice in a one-cup serving provides twice the minimum daily requirement for Vitamin C.',
                            answer:0,
                            question_answer:[
                                {
                                    text:'If freshly',
                                    value:1
                                },
                                {
                                    text:'If it freshly',
                                    value:2
                                },
                                {
                                    text:'It is freshly',
                                    value:3
                                },
                                {
                                    text:"You freshly",
                                    value:4
                                }
                            ]
                        },
                    ]   
                },
                {
                    part:2,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>WRITTEN EXPRESSION <br/>DIRECTIONS </b></p><p class="intruksi-text">In questions 16 – 40 each sentence has four underlined words or phrases. The four underlined parts of the sentence are marked (A), (B), (C), and (D). Identify the one underlined word or phrase that must be changed in order for the sentence to be correct. Then, on the screen, find the number of the question and mark your answer corresponding to the letter of the answer you have chosen.</div>'
                },
                {
                    part:2,
                    type:'assestment',
                    title_desc_modal:'Section 2 : Structure  and Written  Expression',
                    desc:'<div><p class="intruksi-text"><b> Written  Expression <br/>DIRECTIONS</b></p><p class="intruksi-text"> In questions 16 – 40 each sentence has four underlined words or phrases. The four underlined parts of the sentence are marked (A), (B), (C), and (D). Identify the one underlined word or phrase that must be changed in order for the sentence to be correct. Then, on the screen, find the number of the question and mark your answer corresponding to the letter of the answer you have chosen.</p></div>',
                    question:[
                        {
                            number:16,
                            desc:'<div><span class="st"><div class="st-answer">A</div><div>A</div></span> solar eclipse <span class="st"><div class="st-answer">happens</div><div>B</div></span> when the moon <span class="st"><div class="st-answer">passed</div><div>C</div></span> in front of <span class="st"><div class="st-answer">the</div><div>D</div></span> sun.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:17,
                            desc:'<div><span class="st"><div class="st-answer">In early days</div><div>A</div></span> hydrochloric acid <span class="st"><div class="st-answer">was done</div><div>B</div></span> by <span class="st"><div class="st-answer">heating</div><div>C</div></span> a mixture of sodium chloride <span class="st"><div class="st-answer">with</div><div>D</div></span> iron</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:18,
                            desc:'<div>The city of London was <span class="st"><div class="st-answer">founded</div><div>A</div></span> <span class="st"><div class="st-answer">by</div><div>B</div></span> the Romans <span class="st"><div class="st-answer">on</div><div>C</div></span> <span class="st"><div class="st-answer">the</div><div>D</div></span> year of 43 AD.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:19,
                            desc:'<div>The media image of paleontologists <span class="st"><div class="st-answer">which</div><div>A</div></span> study prehistoric life <span class="st"><div class="st-answer">is</div><div>B</div></span> often of field of workers <span class="st"><div class="st-answer">camped</div><div>C</div></span> in the desert in the hot sun, carefully <span class="st"><div class="st-answer">picking</div><div>D</div></span> away at the rock surrounding a large dinosaur bone</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:20,
                            desc:'<div>All of us <span class="st"><div class="st-answer">are</div><div>A</div></span> unique, but some of us <span class="st"><div class="st-answer">are</div><div>B</div></span> <span class="st"><div class="st-answer">uniquer</div><div>C</div></span> <span class="st"><div class="st-answer">than</div><div>D</div></span> others.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:21,
                            desc:'<div>In rich countries, water consumption <span class="st"><div class="st-answer">has</div><div>A</div></span> gradually been <span class="st"><div class="st-answer">slow</div><div>B</div></span> down by price <span class="st"><div class="st-answer">increases</div><div>C</div></span> and the <span class="st"><div class="st-answer">use</div><div>D</div></span> of modern technology and recycling.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:22,
                            desc:"<div>Because doctors <span class='st'><div class='st-answer'>are treating</div><div>A</div></span> more people for skin cancer, it is widely <span class='st'><div class='st-answer'>believed</div><div>B</div></span> that <span class='st'><div class='st-answer'>changes</div><div>C</div></span> in the protective layers of the earth's atmosphere <span class='st'><div class='st-answer'>must be produce</div><div>D</div></span> harmful effects now.</div>",
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:23,
                            desc:'<div>Telegraph <span class="st"><div class="st-answer">service</div><div>A</div></span> <span class="st"><div class="st-answer">across</div><div>B</div></span> the Atlantic was <span class="st"><div class="st-answer">successful</div><div>C</div></span> <span class="st"><div class="st-answer">established</div><div>D</div></span> in 1866. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:24,
                            desc:'<div>The site for Williamsburg <span class="st"><div class="st-answer">has been selected</div><div>A</div></span> by the colonists <span class="st"><div class="st-answer">because of</div><div>B</div></span> the soil drainage <span class="st"><div class="st-answer">was better</div><div>C</div></span> there <span class="st"><div class="st-answer">than</div><div>D</div></span> at the Jamestown location.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:25,
                            desc:'<div><span class="st"><div class="st-answer">Alike</div><div>A</div></span> <span class="st"><div class="st-answer">all</div><div>B</div></span> <span class="st"><div class="st-answer">other</div><div>C</div></span> mammals, dolphins have <span class="st"><div class="st-answer">lungs.</div><div>D</div></span></div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:26,
                            desc:'<div><span class="st"><div class="st-answer">Most players</div><div>A</div></span> of that team <span class="st"><div class="st-answer">are</div><div>B</div></span> under 16 and <span class="st"><div class="st-answer">not any</div><div>C</div></span> of them <span class="st"><div class="st-answer">is</div><div>D</div></span> over 20.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:27,
                            desc:'<div>Manufacturers <span class="st"><div class="st-answer">may use</div><div>A</div></span> food additives <span class="st"><div class="st-answer">for preserving</div><div>B</div></span>, to color, to flavor, or to <span class="st"><div class="st-answer">fortify</div><div>C</div></span> <span class="st"><div class="st-answer">foods</div><div>D</div></span>. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:28,
                            desc:'<div>The Rivers Museum <span class="st"><div class="st-answer">in</div><div>A</div></span> the corner of the Corn Street and New Road <span class="st"><div class="st-answer">house</div><div>B</div></span> a <span class="st"><div class="st-answer">fascinating</div><div>C</div></span> collection of <span class="st"><div class="st-answer">art</div><div>D</div></span> and other objects.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:29,
                            desc:'<div><span class="st"><div class="st-answer">Most</div><div>A</div></span> oxygen atoms <span class="st"><div class="st-answer">have</div><div>B</div></span> eight neutrons, but a small <span class="st"><div class="st-answer">amount</div><div>C</div></span> have <span class="st"><div class="st-answer">nine or ten.</div><div>D</div></span> </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:30,
                            desc:'<div><span class="st"><div class="st-answer">After</div><div>A</div></span> days of discussion, the committee agreed <span class="st"><div class="st-answer">for</div><div>B</div></span> the <span class="st"><div class="st-answer">amount</div><div>C</div></span> of money to <span class="st"><div class="st-answer">donate</div><div>D</div></span>. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:31,
                            desc:'<div>The frontal lobes of the cerebrum <span class="st"><div class="st-answer">is contained</div><div>A</div></span> the nerve cells <span class="st"><div class="st-answer">which</div><div>B</div></span> <span class="st"><div class="st-answer">control</div><div>C</div></span> all <span class="st"><div class="st-answer">power of movement</div><div>D</div></span> in the body muscles.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:32,
                            desc:'<div>Myocardial infarction usually <span class="st"><div class="st-answer">was produced</div><div>A</div></span> by thrombotic occlusion <span class="st"><div class="st-answer">of one</div><div>B</div></span> of the <span class="st"><div class="st-answer">larger</div><div>C</div></span> <span class="st"><div class="st-answer">branches</div><div>D</div></span> of the coronary arteries.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:33,
                            desc:'<div>The professor <span class="st"><div class="st-answer">does</div><div>A</div></span> not give <span class="st"><div class="st-answer">many</div><div>B</div></span> <span class="st"><div class="st-answer">exam</div><div>C</div></span> in chemistry class, but the ones she gives <span class="st"><div class="st-answer">are</div><div>D</div></span> difficult.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:34,
                            desc:'<div>Uranus <span class="st"><div class="st-answer">is</div><div>A</div></span> <span class="st"><div class="st-answer">the</div><div>B</div></span> seventh <span class="st"><div class="st-answer">planets</div><div>C</div></span> from <span class="st"><div class="st-answer">the sun</div><div>D</div></span>. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:35,
                            desc:'<div>Heart <span class="st"><div class="st-answer">attacks</div><div>A</div></span> are <span class="st"><div class="st-answer">fatally</div><div>B</div></span> in 75% <span class="st"><div class="st-answer">percent</div><div>C</div></span> of <span class="st"><div class="st-answer">occurences</div><div>D</div></span>. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:36,
                            desc:'<div>Operas can be <span class="st"><div class="st-answer">broadly</div><div>A</div></span> <span class="st"><div class="st-answer">classified</div><div>B</div></span> as either comedies <span class="st"><div class="st-answer">or</div><div>C</div></span> <span class="st"><div class="st-answer">they are</div><div>D</div></span> tragedies. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:37,
                            desc:'<div><span class="st"><div class="st-answer">In the</div><div>A</div></span> human body, blood flows from <span class="st"><div class="st-answer">a</div><div>B</div></span> heart through <span class="st"><div class="st-answer">the arteries</div><div>C</div></span> and it returns through <span class="st"><div class="st-answer">the</div><div>D</div></span> veins.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:38,
                            desc:'<div>The <span class="st"><div class="st-answer">witnesses</div><div>A</div></span> saw that <span class="st"><div class="st-answer">most</div><div>B</div></span> of the <span class="st"><div class="st-answer">fire</div><div>C</div></span> in the hills <span class="st"><div class="st-answer">were extinguished</div><div>D</div></span>.</div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:39,
                            desc:"<div>Ben Franklin <span class='st'><div class='st-answer'>first</div><div>A</div></span> <span class='st'><div class='st-answer'>attaining</div><div>B</div></span> popular success <span class='st'><div class='st-answer'>through</div><div>C</div></span> his <span class='st'><div class='st-answer'>writings</div><div>D</div></span> in his brother's newspaper, the New England Current. </div>",
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                        {
                            number:40,
                            desc:'<div>The report <span class="st"><div class="st-answer">could not be</div><div>A</div></span> <span class="st"><div class="st-answer">turned</div><div>B</div></span> in on time because all the <span class="st"><div class="st-answer">needed</div><div>C</div></span> work <span class="st"><div class="st-answer">lost</div><div>D</div></span>. </div>',
                            answer:0,
                            question_answer:[
                                {
                                    text:'A',
                                    value:1
                                },
                                {
                                    text:'B',
                                    value:2
                                },
                                {
                                    text:'C',
                                    value:3
                                },
                                {
                                    text:"D.",
                                    value:4
                                }
                            ]
                        },
                    ]
                },
                
            ]
        },
        {
            sub:3,
            duration:3300000,
            // duration:20000,
            type:'part',
            session:'reading-2',
            desc:'<p class="instruksi-text">Di dalam buku ini terdapat 90 pasang pernyataan. Pilihlah satu pernyataan  dari pasangan pernyataan itu yang adan rasakan paling mendekati gambaran diri anda, atau yang paling menunjukkan perasaan anda.<br/><br/>Kadang-kadang anda merasa bahwa kedua pernyataan itu tidak sesuai benar dengan diri anda, namun demikian anda diminta tetap memilih satu pernyataan yang paling menunjukkan diri anda. <br/><br/>Centang pada kotak disamping pernyataan yang anda pilih</p>',
            question:[],
            intruction:[],
            start_time:null,
            part:[
                {
                    part:1,
                    type:'welcome',
                    desc:'<div><p class="instruksi-text"><b>Section 3 : Reading Comprehension</b></p><p class="intruksi-text">TIME: APPROXIMATELY 55 MINUTES (50 QUESTIONS) <br/>In this section you will read several passages. Each one is followed by several questions about it. For this section, you are to choose the one best answer, (A), (B), (C), or (D), to each question. Then, on your answer sheet, find the number of the question and fill in the space that corresponds to the Letter of the answer you have chosen. </p></div>'
                },
                {
                    part:1,
                    type:'assestment',
                    title_desc_modal:'Section 3 : Reading Comprehension',
                    desc:'<div><p class="instruksi-text"><b>Section 3 : Reading Comprehension</b></p><p class="intruksi-text">TIME: APPROXIMATELY 55 MINUTES (50 QUESTIONS) <br/>In this section you will read several passages. Each one is followed by several questions about it. For this section, you are to choose the one best answer, (A), (B), (C), or (D), to each question. Then, on your answer sheet, find the number of the question and fill in the space that corresponds to the Letter of the answer you have chosen. </p></div>',
                    question:[
                        {
                            image:image_import.toefl.Questions_1_10,
                            image_question:[
                                {
                                    number:1,
                                    desc:'What does the passage tell about a protein?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'A protein only consists of amino acids.',
                                            value:1
                                        },
                                        {
                                            text:'There are at least 13 amino acids that should be included in a regular diet.',
                                            value:2
                                        },
                                        {
                                            text:'All 22 natural amino acids are different from each other.',
                                            value:3
                                        },
                                        {
                                            text:"A protein is used to build muscles.",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:2,
                                    desc:'What does the passage say about haemoglobin?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Haemoglobin is not categorized as a protein.',
                                            value:1
                                        },
                                        {
                                            text:'Haemoglobin consists of amino acids and iron atoms.',
                                            value:2
                                        },
                                        {
                                            text:'Haemoglobin is needed to ensure the blood flows smoothly in our body.',
                                            value:3
                                        },
                                        {
                                            text:"The red color of haemoglobin is the result of blood mixed with a protein.",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:3,
                                    desc:'According to the passage, how many essential amino acids that cannot be produced by the body?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'7',
                                            value:1
                                        },
                                        {
                                            text:'9',
                                            value:2
                                        },
                                        {
                                            text:'13',
                                            value:3
                                        },
                                        {
                                            text:"22",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:4,
                                    desc:'The word "they" in line 13 refers to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the 13 amino acids',
                                            value:1
                                        },
                                        {
                                            text:'the 22 natural amino acids',
                                            value:2
                                        },
                                        {
                                            text:'the regular diet',
                                            value:3
                                        },
                                        {
                                            text:"the amino acids and iron atoms",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:5,
                                    desc:'The word "fabricate" in line 15 is closest in meaning to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'break',
                                            value:1
                                        },
                                        {
                                            text:'create',
                                            value:2
                                        },
                                        {
                                            text:'mix',
                                            value:3
                                        },
                                        {
                                            text:"separate",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:6,
                                    desc:'What does the passage say about milk and eggs?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'They contain all the essential amino acids.',
                                            value:1
                                        },
                                        {
                                            text:'They contain only 3/4 of the essential amino acids.',
                                            value:2
                                        },
                                        {
                                            text:'They are the cause of cholesterol.',
                                            value:3
                                        },
                                        {
                                            text:"They are considered as the best source to increase the haemoglobin level.",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:7,
                                    desc:'What does paragraph 4 mainly talk about?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the example of complete protein and pure protein',
                                            value:1
                                        },
                                        {
                                            text:'the importance of preparing protein correctly',
                                            value:2
                                        },
                                        {
                                            text:'the alternative protein for vegetarians',
                                            value:3
                                        },
                                        {
                                            text:"the definition of protein",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:8,
                                    desc:'The word "utilized" in line 27 could be best replaced by ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'involved',
                                            value:1
                                        },
                                        {
                                            text:'manipulated',
                                            value:2
                                        },
                                        {
                                            text:'recognized',
                                            value:3
                                        },
                                        {
                                            text:"used",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:9,
                                    desc:'According to the passage, what should the vegetarians do?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'to drink milk at least once a month to ensure they get the essential amino acids',
                                            value:1
                                        },
                                        {
                                            text:'to replace all meat with eggs',
                                            value:2
                                        },
                                        {
                                            text:'to combine corn and beans to get the essential amino acids',
                                            value:3
                                        },
                                        {
                                            text:"to cook the corn and beans in oil rather than water",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:10,
                                    desc:'The word "subsist" in line 31 is closest in meaning to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'cease',
                                            value:1
                                        },
                                        {
                                            text:'discontinue',
                                            value:2
                                        },
                                        {
                                            text:'exist',
                                            value:3
                                        },
                                        {
                                            text:"quit",
                                            value:4
                                        }
                                    ]
                                },                                
                            ]
                        },
                        {
                            image:image_import.toefl.Questions_11_18,
                            image_question:[
                                {
                                    number:11,
                                    desc:'What is the passage mainly about?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the difference between nocturnal and diurnal creatures',
                                            value:1
                                        },
                                        {
                                            text:'the effects of light to night activities',
                                            value:2
                                        },
                                        {
                                            text:'the light pollution',
                                            value:3
                                        },
                                        {
                                            text:"humans as diurnal creatures",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:12,
                                    desc:'According to the passage, what does "diurnal" mean?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the ability to work during the day and at night',
                                            value:1
                                        },
                                        {
                                            text:'the tendency to be active during the day',
                                            value:2
                                        },
                                        {
                                            text:'the tendency to be active at night',
                                            value:3
                                        },
                                        {
                                            text:"the inability to work during the day and at night",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:13,
                                    desc:'The word "its" in line 10 refers to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'light pollution',
                                            value:1
                                        },
                                        {
                                            text:'the manipulative engineering',
                                            value:2
                                        },
                                        {
                                            text:'nocturnal creature',
                                            value:3
                                        },
                                        {
                                            text:"the scientist",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:14,
                                    desc:'According to the passage, what is the main cause of light pollution?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"the human's inability to be active at night",
                                            value:1
                                        },
                                        {
                                            text:'the bad lighting design',
                                            value:2
                                        },
                                        {
                                            text:'the damming of a river',
                                            value:3
                                        },
                                        {
                                            text:"the basic evolutionary fact",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:15,
                                    desc:'Which aspect of life is NOT affected by the light pollution?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the food-finding process',
                                            value:1
                                        },
                                        {
                                            text:'the procreation process',
                                            value:2
                                        },
                                        {
                                            text:'the physical movement from one area to another area',
                                            value:3
                                        },
                                        {
                                            text:"the strive of staying alive",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:16,
                                    desc:'The word "it" in line 19 refers to ....',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the light pollution',
                                            value:1
                                        },
                                        {
                                            text:'London',
                                            value:2
                                        },
                                        {
                                            text:'the human history',
                                            value:3
                                        },
                                        {
                                            text:"year 1800",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:17,
                                    desc:'According to the passage, when were gaslights available in the streets?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'1800',
                                            value:1
                                        },
                                        {
                                            text:'1807',
                                            value:2
                                        },
                                        {
                                            text:'1817',
                                            value:3
                                        },
                                        {
                                            text:"1870",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:18,
                                    desc:'The attitude of the author toward the human lighting could be best describe as ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'admiration',
                                            value:1
                                        },
                                        {
                                            text:'compliant',
                                            value:2
                                        },
                                        {
                                            text:'indifference',
                                            value:3
                                        },
                                        {
                                            text:"opposition",
                                            value:4
                                        }
                                    ]
                                },                                
                                                             
                            ]
                        },
                        {
                            image:image_import.toefl.Questions_19_28,
                            image_question:[
                                {
                                    number:19,
                                    desc:'What does the passage mainly discuss?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the interaction of human and nature',
                                            value:1
                                        },
                                        {
                                            text:'the relationship between city and natural environment',
                                            value:2
                                        },
                                        {
                                            text:'the shape of natural environment',
                                            value:3
                                        },
                                        {
                                            text:"the spread of metropolitan populations",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:20,
                                    desc:'The word "profoundly" in line 9 could be best replaced by ....',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'accurately',
                                            value:1
                                        },
                                        {
                                            text:'falsely',
                                            value:2
                                        },
                                        {
                                            text:'immensely',
                                            value:3
                                        },
                                        {
                                            text:"insignificantly",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:21,
                                    desc:'The word "dwellers" in line 11 is closest in meaning to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'habitat',
                                            value:1
                                        },
                                        {
                                            text:'native',
                                            value:2
                                        },
                                        {
                                            text:'refugee',
                                            value:3
                                        },
                                        {
                                            text:"resident",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:22,
                                    desc:'According to the passage, what does NOT the human do to protect themselves against the nature threat?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'building dams',
                                            value:1
                                        },
                                        {
                                            text:'building earthquake-resistant buildings',
                                            value:2
                                        },
                                        {
                                            text:'building pest-free shelters',
                                            value:3
                                        },
                                        {
                                            text:"building storage for food",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:23,
                                    desc:'What does the industry require for production purpose?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'construction materials',
                                            value:1
                                        },
                                        {
                                            text:'food',
                                            value:2
                                        },
                                        {
                                            text:'natural materials',
                                            value:3
                                        },
                                        {
                                            text:"water",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:24,
                                    desc:'The word "effluent" in line 35 could be best replaced by ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'pollution',
                                            value:1
                                        },
                                        {
                                            text:'problems',
                                            value:2
                                        },
                                        {
                                            text:'sewerage system',
                                            value:3
                                        },
                                        {
                                            text:"waste",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:25,
                                    desc:'What was the drawback of using coal as fuel for industry?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'dirty',
                                            value:1
                                        },
                                        {
                                            text:'expensive',
                                            value:2
                                        },
                                        {
                                            text:'easily found',
                                            value:3
                                        },
                                        {
                                            text:"powerful",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:26,
                                    desc:'What does paragraph 5 mainly discuss?',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the demands of cleaner environment and public health improvement',
                                            value:1
                                        },
                                        {
                                            text:'the improvement of urban sanitary',
                                            value:2
                                        },
                                        {
                                            text:'the replacement of horse by car to improve the air quality',
                                            value:3
                                        },
                                        {
                                            text:"the women's groups showing concern for high-quality life",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:27,
                                    desc:'The word "congestion" in line 57 is closest in meaning to ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'development',
                                            value:1
                                        },
                                        {
                                            text:'growth',
                                            value:2
                                        },
                                        {
                                            text:'traffic',
                                            value:3
                                        },
                                        {
                                            text:"variety",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:28,
                                    desc:'It can be inferred from paragraph 6 that the substitution of natural gas and oil for coal ....',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'creates new opportunities for trading',
                                            value:1
                                        },
                                        {
                                            text:'creates new problems to the urban environment',
                                            value:2
                                        },
                                        {
                                            text:'produces denser smoke',
                                            value:3
                                        },
                                        {
                                            text:"proves to be a cheaper alternative",
                                            value:4
                                        }
                                    ]
                                },                                
                                                             
                            ]
                        },
                        {
                            image:image_import.toefl.Questions_29_40,
                            image_question:[
                                {
                                    number:29,
                                    desc:'The passage is mainly concerned with ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the proof of a theory that being taller is better ',
                                            value:1
                                        },
                                        {
                                            text:'the pursuit of wealth by promoting milk ',
                                            value:2
                                        },
                                        {
                                            text:'the reasons of why Japanese is taller than Chinese ',
                                            value:3
                                        },
                                        {
                                            text:"the struggle of Chinese youngsters to get taller ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:30,
                                    desc:'According to the passage, what is the main reason of Chinese government urging their adolescents to think tall? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the effect of magazine and television on popularizing the new normal height ',
                                            value:1
                                        },
                                        {
                                            text:'the growing milk industry ',
                                            value:2
                                        },
                                        {
                                            text:'the pursuit of celebrity and wealth ',
                                            value:3
                                        },
                                        {
                                            text:"the shame that Japanese is now taller than Chinese ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:31,
                                    desc:'The word "lanky" in line 7 is closest in meaning to .... ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'bony ',
                                            value:1
                                        },
                                        {
                                            text:'gaunt ',
                                            value:2
                                        },
                                        {
                                            text:'scrawny ',
                                            value:3
                                        },
                                        {
                                            text:"slender ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:32,
                                    desc:'The phrase "whisked away" in line 10 could be best replaced by ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'contacted ',
                                            value:1
                                        },
                                        {
                                            text:'guided ',
                                            value:2
                                        },
                                        {
                                            text:'introduced ',
                                            value:3
                                        },
                                        {
                                            text:"taken ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:33,
                                    desc:'According to the passage, who is Xia Hetao? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'a Chinese model from Southern China ',
                                            value:1
                                        },
                                        {
                                            text:'a clinic doctor who performs leg-lengthening operations ',
                                            value:2
                                        },
                                        {
                                            text:'a young man who underwent the leg-lengthening operation ',
                                            value:3
                                        },
                                        {
                                            text:"one of the famous basketball star ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:34,
                                    desc:'What is the record for the leg-lengthening operation? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'1 inch ',
                                            value:1
                                        },
                                        {
                                            text:'5 inches ',
                                            value:2
                                        },
                                        {
                                            text:'6.5 inches ',
                                            value:3
                                        },
                                        {
                                            text:"8 inches ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:35,
                                    desc:'What does the passage say about the leg-lengthening operations? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'It takes a month to gain the desired height. ',
                                            value:1
                                        },
                                        {
                                            text:'The operation only guarantees 85% success rate. ',
                                            value:2
                                        },
                                        {
                                            text:'The pain because of the operation is bearable. ',
                                            value:3
                                        },
                                        {
                                            text:"There is a chance that the bone fails to set properly. ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:36,
                                    desc:'According to the passage, what is NOT the alternative of getting taller? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'acupuncture ',
                                            value:1
                                        },
                                        {
                                            text:'cosmetic surgery ',
                                            value:2
                                        },
                                        {
                                            text:'"increasing Brain and Stature" tablets ',
                                            value:3
                                        },
                                        {
                                            text:"natural growth hormone releasing shoe pad ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:37,
                                    desc:'The word "her" in line 49 refers to .... ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Huang Xinye ',
                                            value:1
                                        },
                                        {
                                            text:'Wanlijian ',
                                            value:2
                                        },
                                        {
                                            text:'Xia Hetao ',
                                            value:3
                                        },
                                        {
                                            text:"Zhang Mei ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:38,
                                    desc:'Why are Yao Ming, Wang Zhizhi, and Menk Bateer known as "Walking Great Wall"? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'They are as strong as the Great Wall. ',
                                            value:1
                                        },
                                        {
                                            text:'They are 3 giant heroes in basketball. ',
                                            value:2
                                        },
                                        {
                                            text:'They are very tall. ',
                                            value:3
                                        },
                                        {
                                            text:"They beat Scandinavian and American Dream Team. ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:39,
                                    desc:'What does the idiom "looked ... straight in the eye" in line 56 mean? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'to find out the hidden values of something ',
                                            value:1
                                        },
                                        {
                                            text:'to have the same purpose ',
                                            value:2
                                        },
                                        {
                                            text:'to look directly without fear or shame ',
                                            value:3
                                        },
                                        {
                                            text:"to cause someone to see someone/something for the first time ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:40,
                                    desc:'What is the cause of the unpopularity of drinking milk policy in China? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Milk is expensive. ',
                                            value:1
                                        },
                                        {
                                            text:'Milk is difficult to digest. ',
                                            value:2
                                        },
                                        {
                                            text:'Most children could not drink milk due to their intolerance of lactose. ',
                                            value:3
                                        },
                                        {
                                            text:"The rarity of milk. ",
                                            value:4
                                        }
                                    ]
                                },                                
                                                             
                            ]
                        },
                        {
                            image:image_import.toefl.Question_41_50,
                            image_question:[
                                {
                                    number:41,
                                    desc:'What does the passage mainly discuss? ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:"the arts of Georgia O'Keeffe",
                                            value:1
                                        },
                                        {
                                            text:"the background reasons of Georgia O'Keeffe's becoming a painter ",
                                            value:2
                                        },
                                        {
                                            text:"the legacy of Georgia O'Keeffe ",
                                            value:3
                                        },
                                        {
                                            text:"the life of Georgia O'Keeffe ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:42,
                                    desc:"According to the passage, it can be inferred that O'Keeffe ….",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'did not finish her high school ',
                                            value:1
                                        },
                                        {
                                            text:'did not have a long life ',
                                            value:2
                                        },
                                        {
                                            text:'preferred living in open spaces ',
                                            value:3
                                        },
                                        {
                                            text:"was not originally from America ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:43,
                                    desc:"Where was O'Keeffe born? ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Amarillo ',
                                            value:1
                                        },
                                        {
                                            text:'Chicago ',
                                            value:2
                                        },
                                        {
                                            text:'New Mexico ',
                                            value:3
                                        },
                                        {
                                            text:"Sun Prairie ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:44,
                                    desc:"When did Georgia O'Keeffe stop painting for good? ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'in 1908 ',
                                            value:1
                                        },
                                        {
                                            text:'in summer of 1912 ',
                                            value:2
                                        },
                                        {
                                            text:'in summer of 1929 ',
                                            value:3
                                        },
                                        {
                                            text:"in the late 1970s ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:45,
                                    desc:"It can be inferred from the paragraph 3 that Georgia O'Keeffe's paintings were .... ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'the source of debates among teachers ',
                                            value:1
                                        },
                                        {
                                            text:'different from the current trend ',
                                            value:2
                                        },
                                        {
                                            text:'popular ',
                                            value:3
                                        },
                                        {
                                            text:"the real example of realism ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:46,
                                    desc:'The word "she" in line 19 refers to .... ',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Georgia ',
                                            value:1
                                        },
                                        {
                                            text:"Georgia's teacher ",
                                            value:2
                                        },
                                        {
                                            text:'Madison ',
                                            value:3
                                        },
                                        {
                                            text:"Virginia ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:47,
                                    desc:"Under whose guidance that O'Keeffe found back her passion for painting? ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'Abiquiu ',
                                            value:1
                                        },
                                        {
                                            text:'Alon Bemont ',
                                            value:2
                                        },
                                        {
                                            text:'Arthur Dow ',
                                            value:3
                                        },
                                        {
                                            text:"Williams Burg ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:48,
                                    desc:'The word "barren" could be best replaced by ….',
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'dynamic ',
                                            value:1
                                        },
                                        {
                                            text:'fertile ',
                                            value:2
                                        },
                                        {
                                            text:'static ',
                                            value:3
                                        },
                                        {
                                            text:"sterile ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:49,
                                    desc:"When did Georgia O'Keeffe start supporting her own life? ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'1903 ',
                                            value:1
                                        },
                                        {
                                            text:'1905 ',
                                            value:2
                                        },
                                        {
                                            text:'1908 ',
                                            value:3
                                        },
                                        {
                                            text:"1912 ",
                                            value:4
                                        }
                                    ]
                                },                                
                                {
                                    number:50,
                                    desc:"According to the passage, what did O'Keeffe think about realism? ",
                                    answer:0,
                                    question_answer:[
                                        {
                                            text:'It is not real. ',
                                            value:1
                                        },
                                        {
                                            text:'It is subjective. ',
                                            value:2
                                        },
                                        {
                                            text:'It is liberating. ',
                                            value:3
                                        },
                                        {
                                            text:"It includes too many details. ",
                                            value:4
                                        }
                                    ]
                                },                                
                                                             
                            ]
                        },
                    ]
                },
            ]
        },
        
    ],
} 
export default data