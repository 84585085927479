import * as actionTypes from 'redux/constants/general'
import {apiCall} from 'service/apiCall'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const modalToggle=(obj)=>async dispatch=>{
    dispatch({
        type:actionTypes.MODAL_SHOW,
        payload:obj
    })
}

export const modalToggleReset=(obj)=>async dispatch=>{
    dispatch({
        type: actionTypes.MODAL_RESET,
        payload: obj
      });
}

export const setLoading =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING,
        payload
    })
}
export const setLoadingTable =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING_TABLE,
        payload
    })
}
export const setLoadingTable2 =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING_TABLE2,
        payload
    })
}
export const setLoadingTable3 =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_LOADING_TABLE3,
        payload
    })
}

export const setError =(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ERROR,
        payload
    })
}


export const getListTest=()=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/assess/list',
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
           dispatch({
               type:actionTypes.GET_LIST_TEST,
               payload:res.data
           })
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const getLisByUserId=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/assess${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch({
                type:actionTypes.GET_LIST_TEST_BYUSERID,
                payload:res.data
            })
            // if(res.data.length>0){
            //     dispatch({
            //         type:actionTypes.GET_LIST_TEST_BYUSERID,
            //         payload:res.data
            //     })
            // }else{
            //     // window.location.assign('/done')
            // }
            dispatch(setLoading(false))
           
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}