import React,{useState,useEffect} from 'react'
import Check from 'assets/icon/Check.svg'
import {Button} from '@material-ui/core'
import Countdown from 'react-countdown';
import {useSelector,useDispatch} from 'react-redux'
import {postJawaban,setQuestion} from 'redux/actions/assestment'
import {isMobile} from 'react-device-detect'
import './style.css'
import Loader from 'components/Loading'
import Camera from 'components/Camera'
import {modalToggle} from 'redux/actions/general'
import Modal from 'components/Modal'
import Cookie from 'universal-cookie'
import infomedia from 'assets/image/Logo_Infomedia_white.png'
const cookie = new Cookie()
const Navbar=React.memo(props=>{
    const dispatch=useDispatch()
    const [second,setSecond]=useState(1200000)
    const [start_time, setStart_time] = useState(Date.now())
    useEffect(() => {
        if(props.start_time!==undefined){
            setStart_time(props.start_time)
        }
    }, [])
    const onClickHelp=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "",
            modal_component: "intruksi_disc",
            modal_size:700,
            modal_type:'',
            modal_data:null,
            modal_action:'success',
        }))
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className='timer-expired'>00:00:00</span>;
            
        } else {
            return <span className='timer'>00:{minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}</span>;
        }
      };
      let username=props.profile.userName.split(" ")[0]
    return(
        <div className='navbar-wrapper'>
            <div className='div-flex div-space-between div-align-center navbar' >
    <div className='d-flex'>
                        <img
                        src={infomedia}
                        width="auto"
                        height={35}
                        />
                        </div>
    <h3>Selamat mengerjakan <br className='show-mobile'/> <span className='name'>{username}</span></h3>
    <div className=' assestmen-toolbar div-flex div-align-center div-space-between'>
                <div className='div-flex div-align-center'>
                    <p className='hide-mobile' style={{color:'white',fontWeight:'bold',margin:0}}>Petunjuk pengisian</p>
                    &nbsp;&nbsp;
                    <div onClick={onClickHelp} className='circle-help' ><span>?</span></div>

                </div>
                <div className='hide-mobile' style={{width:1.3,height:30,backgroundColor:'white'}}></div>
                <h3>Sisa waktu <br className='show-mobile'/> 
                    <Countdown
                        date={parseInt(start_time) + second}
                        renderer={renderer}
                    />
                </h3>
            </div>
            </div>
            
        </div>
    )
})
export default function Assestment(props) {
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const general = useSelector(state => state.general)
    const {question}=assestment
    const [expired,setExpired]=useState(false)
    const [list_question,setListQuestion]=useState([])
    const [m,setM]=useState([])
    const [l,setL]=useState([])
    const [index,setIndex]=useState({
        i1:0,
        i2:3
    })
    let get=cookie.get('start_time')
    useEffect(()=>{
        let new_q=[]
        let new_l=[]
        question.map((q)=>{
            let lq=[]
            q.list_question.map((l)=>{
                new_l.push(l)
                lq.push({...l,id:new_l.length})

            })
            new_q.push({...q,list_question:lq})
        })
        dispatch(setQuestion(new_q))
        
        console.log(`get`, get)
    },[])
    const chooseAnswer=(q1,q2,id,answers)=>{
        let findM=question[q1].list_question.filter((data)=>{return data.answer==='M'})
        let findL=question[q1].list_question.filter((data)=>{return data.answer==='L'})
        if(answers==='M'){
            if(findM.length>0){
                findM[0].answer=''
                question[q1].list_question[q2].answer=answers
                
                dispatch(setQuestion([...question]))
                // console.log('id,m', id)
                // let removeId=m.filter((data)=>{
                //     return data!==id
                // })
                // setM(removeId)
                // let findId=m.filter((data)=>{
                //     return data===id
                // })
                // console.log('findId', findId)
                // if(findId.length<1){
                //     setM([...m,list_question[q1].list_question[q2].id])
                // }
                // console.log('eyoo')

            }else{
                question[q1].list_question[q2].answer=answers
                dispatch(setQuestion([...question]))
                // let findId=m.filter((data)=>{
                //     return data.id===id
                // })
                // console.log('findId', findId)
                // if(findId.length<1){
                //     setM([...m,list_question[q1].list_question[q2]])
                // }
                
            }
        }else{
            if(findL.length>0) {
                findL[0].answer=''
                question[q1].list_question[q2].answer=answers
                dispatch(setQuestion([...question]))
                // let findId=l.filter((data)=>{
                //     return data===id
                // })
                // if(findId.length<1){
                //     setL([...l,list_question[q1].list_question[q2].id])
                // }
            }else{
                question[q1].list_question[q2].answer=answers
                dispatch(setQuestion([...question]))
                // let findId=l.filter((data)=>{
                //     return data===id
                // })
                // if(findId.length<1){
                //     setL([...l,list_question[q1].list_question[q2].id])
                // }

            }
        }
        
        
        
        
    }
    const next=()=>{
        window.scrollTo(0,0)
        setIndex({
            i1:index.i1+3,
            i2:index.i2+3,
        })
    }
    const back=()=>{
        window.scrollTo(0,0)

        setIndex({
            i1:index.i1!==0?index.i1-3:0,
            i2:index.i2!==3?index.i2-3:3,
        })
    }
    const handleDisable=()=>{
        let m=[]
        let lw=[]
        question.map((lq)=>{
            lq.list_question.map((l)=>{
                if(l.answer==='M'){
                    m.push(l.id)
                }
                if(l.answer==='L'){
                    lw.push(l.id)
                }
            })
        })
        if(m.length>=24&&lw.length>=24){
            return false
        }else{
            return true
        }
    }
    // console.log('m,l', m,l)
    const handleDisable2=()=>{
        let a=[]

        let mobile=question.slice(index.i1,index.i2)
        mobile.map((data)=>{
            data.list_question.map(diti=>{
                if(diti.answer!==''){
                    a.push(diti)
                }
            })
        })

        if(a.length===6){
            return false
        }else{
            return true
        }
    }
    const onSend=()=>{
        let data={
            userId:props.profile.id,
            m:[],
            l:[]
        }
        question.map((lq)=>{
            lq.list_question.map((l)=>{
                if(l.answer==='M'){
                    data.m.push(l.id)
                }
                if(l.answer==='L'){
                    data.l.push(l.id)
                }
            })
        })
        // console.log('data', data)
        dispatch(postJawaban(data))
        // console.log('m,l', m,l)

    }
    let mobile=question.slice(index.i1,index.i2)
    let ismob=isMobile?mobile:question
    
    return (
        <div>
            <Loader/>
            <Modal token={props.token} profile={props.profile}/>
            {general.on_cam&&<Camera profile={props.profile}/>}
            <Navbar start_time={get} expired={expired} setExpired={setExpired} profile={props.profile}/>

            <div className='assestment-wrapper'>
                <div className='assestment-grid'>
                    <div style={{textAlign:'center'}}>
                    <p>Di atas pertanyaan petunjuknya: Pilih satu <b>M</b> (<b style={{color:'#78000F'}}>M</b>enggambarkan  diri anda) dan satu <b>T</b> (<b style={{color:'#78000F'}}>T</b>idak menggambarkan diri anda).</p>
                    </div>
                    {ismob.map((que,i1)=>(
                        <div key={i1} className='question-flex' >
                        {/* */}
                        <div className='square'>
                            <p>{que.number}</p>
                        </div>
                        <div style={{width:'100%'}}>
                        <div className='grid-head'>
                            <p>M</p>
                            <p>T</p>
                        </div>
                        {que.list_question.map((lq,i2)=>(
                            <div className='div-flex grid-content2' key={i2}>
                                <div onClick={()=>isMobile?chooseAnswer(que.number-1,i2,lq.id,'M'):chooseAnswer(i1,i2,lq.id,'M')} className={`right-card ${lq.answer==='M'&&'active-card'}`}>
                                    {lq.answer==='M'&&<img src={Check} style={{width:20}}/>}
                                </div>
                                <div className='middle-card'>
                                    <p>{lq.q}</p>
                                </div>
                                <div onClick={()=>isMobile?chooseAnswer(que.number-1,i2,lq.id,'L'):chooseAnswer(i1,i2,lq.id,'L')} className={`right-card ${lq.answer==='L'&&'active-card'}`}>
                                    {lq.answer==='L'&&<img src={Check} style={{width:20}}/>}
                                </div>
                            </div>
                        ))}
                        </div>
                        
                    </div>
                    ))}
                </div>
                <br/>
                <div style={{textAlign:'center'}} className='hide-mobile'>
                <Button disabled={handleDisable()} onClick={onSend} style={{width:300,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kumpulkan</Button>
                </div>
                <div className='show-mobile'>
                <div  style={{width:'90%',margin:'auto',display:'flex',justifyContent:'space-between'}}>
                    <Button onClick={back} style={{visibility:index.i1===0?'hidden':'visible',width:100,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kembali</Button>
                    {index.i2===24?
                    <Button disabled={handleDisable()} onClick={onSend} style={{width:100,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kumpulkan</Button>
                    :
                    <Button disabled={handleDisable2()} onClick={next} style={{width:100,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Lanjut</Button>
                    }
                </div>
                </div>
                <br/>
            </div>
        </div>
    )
}
