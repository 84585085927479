import React ,{useState,useEffect}from 'react'
import WelcomeContainer from 'components/WelcomeContainer'
import Check from 'assets/icon/Check.svg'
import Correct from 'assets/image/Correct.png'
import {useSelector,useDispatch} from 'react-redux'
import {setPapiTime} from 'redux/actions/assestment'

const Welcome=(props)=>{
    const dispatch=useDispatch()
    
    let {subToggle,active_intruction,intructionToggle,papi,clickAgree}=props
    return(
        <WelcomeContainer ln="id"  btnText={`Mulai proses `} clickAgree={()=>clickAgree()} welcomeMessage="Anda sedang menjalani proses Work Preference Test. Baca Instruksi yang tersedia di setiap tahap pada test dengan teliti.">
            <div className='welcome-mobile'>
                <h3>Bagaimana menjalani proses Work Preference Test?</h3>
                <div dangerouslySetInnerHTML={{__html:papi.desc}}></div>
                <div className=''>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                    </div>
                
                    <div className='papi-example-wrapper'>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox checked-example'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div className='div-flex' style={{gap:2,marginBottom:5}}>
                            <div className='papi-example-checkbox'>
                                <img src={Check}/>
                            </div>
                            <div className='papi-example-question'>
                                <p>Saya seorang pekerja “keras” </p>
                            </div>
                        </div>
                        <div style={{textAlign:'center',marginTop:10}}>
                            <img src={Correct} style={{width:50}}/>
                        </div>
                    </div>
                    <p>Bekerjalah dengan cepat, tetapi jangan sampai ada nomor pernyataan yang terlewatkan.</p>
                </div>
            </div>
        </WelcomeContainer>
    )
}

export default Welcome