import React from 'react'
import TextField from 'components/TextField'
import { Button } from '@material-ui/core'
export default function Oas_add_kredit() {
    return (
        <div>
            <TextField
                label="Jumlah Kredit"
                variant='outlined'
                // value={admins.name}
                size='small'
                style={{marginBottom:20}}
                // onChange={(e)=>setadmin({...admins,name:e.target.value})}
            />
            <Button onClick={null} style={{width:'100%',height:35}} className='btn-remove-capital btn-rounded' variant='contained'  color="primary">Tambah Kredit</Button>

        </div>
    )
}
