import React ,{useState,useEffect,useRef}from 'react'
import {useSelector,useDispatch} from 'react-redux'
import Welcome from './welcome'
import Assessment from './assestment'
import Modal from 'components/Modal'
import Loader from 'components/Loading'
import Camera from 'components/Camera'
import Countdown from 'react-countdown';
import {modalToggle} from 'redux/actions/general'
import {isMobile} from 'react-device-detect'
import {setActiveSub,setActivePart,setToefl} from 'redux/actions/assestment'
import moment from 'moment'
import {setToeflTime,postToefl} from 'redux/actions/assestment'
import Cookies from 'universal-cookie'
import infomedia from 'assets/image/Logo_Infomedia_white.png'
const cookie= new Cookies()
const Navbar=React.memo(props=>{
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const [second,setSecond]=useState(props.duration)
    const [start,setStart]=useState(Date.now())
    const {active_sub,active_part}=assestment
    useEffect(() => {
        setSecond(props.duration)
        if(props.start_time!==null){
            setStart(props.start_time)
        }
    }, [props.duration,props.start_time])

    const onClickHelp=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "",
            modal_component: "intruksi_toefl",
            modal_size:700,
            modal_type:'',
            modal_data:null,
            modal_action:'success',
        }))
    }
    const onComplete=(e)=>{
        if(assestment.active_sub!==2){
            if(e.completed&&assestment.toefl[active_sub].part[active_part].type!=='welcome'){
                if(assestment.toefl[active_sub+1].start_time===null){
                    cookie.set('checkpoint',Date.now())
                    assestment.toefl[active_sub+1].start_time=Date.now()
                    dispatch(setToefl([...assestment.toefl]))
                    //di modif disini pake set cookie
                }
                dispatch(setActiveSub(assestment.active_sub+1))
                dispatch(setActivePart(0))
            }
        }else{
            if(assestment.toefl[active_sub].part[active_part].type!=='welcome'){
                // alert('final sub')
                props.postJawaban()
            }
            // 
        }
    }
    const onStop=(e)=>{
        console.log('from on Stop',e)
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // console.log('from renderer')
            return <span className='timer-expired'>00:00:00</span>;
        } else {
            return <span className='timer'>00:{minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}</span>;
        }
    };

    let username=props.profile.userName.split(" ")[0]
    return(
        <div className='navbar-wrapper'>
            <div className='div-flex div-space-between div-align-center navbar' >
            <div className='d-flex'>
                        <img
                        src={infomedia}
                        width="auto"
                        height={35}
                        />
                        </div>
            <h3>Good luck, <br className='show-mobile'/> <span className='name'>{username}</span></h3>
            <div className=' assestmen-toolbar div-flex div-align-center div-space-between'>
                <div className='div-flex div-align-center'>
                    <p className='hide-mobile' style={{color:'white',fontWeight:'bold',margin:0}}>Instruction</p>
                    &nbsp;&nbsp;
                    <div onClick={onClickHelp} className='circle-help' ><span>?</span></div>

                </div>
                <div className='hide-mobile' style={{width:1.3,height:30,backgroundColor:'white'}}></div>
                <h3>remaining time <br className='show-mobile'/> 
                    <Countdown
                        date={start + second}
                        renderer={renderer}
                        ref={props.countRef}
                        onComplete={onComplete}
                        onStop={onStop}
                    />
                </h3>
            </div>
            </div>
            
        </div>
    )
})
export default function Index(props) {
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const general = useSelector(state => state.general)
    const ref = useRef(null)
    const {active_sub,active_part}=assestment
  
    useEffect(() => {
        if(assestment.toefl[active_sub].part[active_part].type==='welcome'){
           
            if(active_part!==0){
                let getChekpoint=cookie.get('checkpoint')
                let time=Date.now()-parseInt(getChekpoint);
                assestment.toefl[active_sub].start_time=assestment.toefl[active_sub].start_time+time
                dispatch(setToefl([...assestment.toefl]))
                cookie.remove('checkpoint')
                cookie.set('checkpoint',Date.now())
                // alert('uoo')
            }else{
                if(active_sub!==0){
                    // let getChekpoint=cookie.get('checkpoint')
                    // let time=Date.now()-parseInt(getChekpoint);
                    // assestment.toefl[active_sub].start_time=assestment.toefl[active_sub].start_time+time
                    // dispatch(setToefl([...assestment.toefl]))

                    assestment.toefl[active_sub].start_time=Date.now()
                    dispatch(setToefl([...assestment.toefl]))
                }
                // console.log(`active_part`, active_part)
                // alert('uoo')
            }
            ref.current.pause()
        }else{
            // alert('hell')
            if(active_part>1){
                let getIsRefresh=cookie.get('is_refresh')
                if(getIsRefresh==='true'){
                    // let getChekpoint=cookie.get('checkpoint')
                    // let time=Date.now()-parseInt(getChekpoint);
                    // assestment.toefl[active_sub].start_time=assestment.toefl[active_sub].start_time+time
                    // dispatch(setToefl([...assestment.toefl]))
                    // cookie.remove('checkpoint')
                    // cookie.set('checkpoint',Date.now())
                }
                
                
                setTimeout(() => {
                    cookie.set('is_refresh',true)
                }, 1000);
            }
           
            ref.current.start()
        }
        
    }, [active_part])
    const subToggle=(key)=>{
        // alert('hei')
        if(assestment.toefl[active_sub+1].start_time===null){
            assestment.toefl[active_sub+1].start_time=Date.now()
            dispatch(setToefl([...assestment.toefl]))
        }
        if(key==='next'){
            dispatch(setActiveSub(active_sub+1))
        }else{
            dispatch(setActiveSub(active_sub-1))
        }
    }
    const partToggle= (key)=>{
        cookie.set('checkpoint',Date.now())
        cookie.set('is_refresh',false)
        if(key==='next'){
            dispatch(setActivePart(active_part+1))
        }else{
            dispatch(setActivePart(active_part-1))
        }
    }
    const clickAgree=()=>{
        
        subToggle('next')
    }
    const postJawaban=()=>{
        let answers1A=[]
        let answers1B=[]
        let answers1C=[]

        let answers2A=[]
        let answers2B=[]

        let answers3=[]

        let sub1=assestment.toefl.filter((data)=>{
            return data.sub===1
        })
        let sub2=assestment.toefl.filter((data)=>{
            return data.sub===2
        })
        let sub3=assestment.toefl.filter((data)=>{
            return data.sub===3
        })

        let an_s1_a=sub1[0].part.filter((data)=>{
            return data.part===1&&data.type==='assestment'
        })
        let an_s1_b=sub1[0].part.filter((data)=>{
            return data.part===2&&data.type==='assestment'
        })
        let an_s1_c=sub1[0].part.filter((data)=>{
            return data.part===3&&data.type==='assestment'
        })
        an_s1_a[0].question.map((data)=>{
            answers1A.push(data.audio_question[0].answer)
        })
        an_s1_b[0].question.map((data)=>{
            answers1B.push(data.audio_question[0].answer)
        })
        an_s1_c[0].question.map((data)=>{
            answers1C.push(data.audio_question[0].answer)
        })

        let an_s2_a=sub2[0].part.filter((data)=>{
            return data.part===1&&data.type==='assestment'
        })
        let an_s2_b=sub2[0].part.filter((data)=>{
            return data.part===2&&data.type==='assestment'
        })
        an_s2_a[0].question.map((data)=>{
            answers2A.push(data.answer)
        })
        an_s2_b[0].question.map((data)=>{
            answers2B.push(data.answer)
        })

        let an_s3_a=sub3[0].part.filter((data)=>{
            return data.part===1&&data.type==='assestment'
        })
        an_s3_a[0].question.map((data)=>{
            data.image_question.map((img_q)=>{
                answers3.push(img_q.answer)
            })
        })
        let data={
            userId:props.profile.id,
            answers1A,
            answers1B,
            answers1C,
            answers2A,
            answers2B,
            answers3 
        }
        dispatch(postToefl(data))
    }   
    const clickAgreePart=async ()=>{
        if(active_part>1){
            let getChekpoint=cookie.get('checkpoint')
            let time=Date.now()-parseInt(getChekpoint);
            // console.log(`time`,time)
            assestment.toefl[active_sub].start_time=assestment.toefl[active_sub].start_time+time
            dispatch(setToefl([...assestment.toefl]))
            
        }
        if(assestment.toefl[active_sub].start_time===null){
            // alert('eu')
            assestment.toefl[active_sub].start_time=Date.now()
            await dispatch(setToefl([...assestment.toefl]))
        }
        if(active_sub>0&&assestment.toefl[active_sub].sub-1!==assestment.toefl[active_sub].sub){
            if(active_part==0){
                assestment.toefl[active_sub].start_time=Date.now()
                await dispatch(setToefl([...assestment.toefl]))
            }
            
        }
        // console.log(`object`, assestment.toefl[active_sub])
        partToggle('next')
    }
    const resetPart=()=>{
        dispatch(setActivePart(0))
    }
    const renderPart=(data,sub_length)=>{
        switch (data.part[active_part].type) {
            case 'welcome':
                return <Welcome profile={props.profile} token={props.token} partToggle={partToggle}  toefl={data.part[active_part]} clickAgreePart={clickAgreePart}/>
            case 'assestment':
                return (
                    <div>
                        <Assessment postJawaban={postJawaban} active_sub={active_sub} session={data.session} resetPart={resetPart} subToggle={subToggle} duration={data.duration} profile={props.profile} token={props.token} partToggle={partToggle}  toefl={data.part[active_part]} active_part={active_part} count_part={data.part.length-1} count_sub={sub_length} clickAgreePart={clickAgreePart}/>
                    </div>
                )
            default:
                break;
        }
    }
    const renderLayer=()=>{
        switch (assestment.toefl[active_sub].type) {
            case 'welcome':
                return <Welcome profile={props.profile} token={props.token} subToggle={subToggle}  toefl={assestment.toefl[active_sub]} clickAgree={clickAgree}/>
            case 'assestment':
                return <Assessment postJawaban={postJawaban} profile={props.profile} token={props.token} subToggle={subToggle}  toefl={assestment.toefl[active_sub]} clickAgree={clickAgree} clickAgreePart={clickAgreePart}/>
            case 'part':
                return renderPart(assestment.toefl[active_sub],assestment.toefl.length-1)
            default:
                break;
        }
    }

    
    return (
        <div >
            {general.on_cam&&<Camera profile={props.profile}/>}
            <Loader/>
            <Modal token={props.token} profile={props.profile}/>
            {assestment.toefl[active_sub].type==='part'&&
            <div style={{visibility:assestment.toefl[active_sub].part[active_part].type!=="welcome"?'visible':'hidden'}}>
                <Navbar postJawaban={postJawaban}  subToggle={subToggle} start_time={assestment.toefl[active_sub].start_time} toefl_start_time={assestment.toefl_start_time} countRef={ref} duration={assestment.toefl[active_sub].duration}  profile={props.profile} />
            </div>
            }
            {renderLayer()}
            
        </div>
    )
}
