import React , {useState} from 'react'
import Demografi  from './demografi'
import Assestment from './assestment'
import Done from './done'
import Portal from './portal'
import {useSelector} from 'react-redux'
import './style.css'
import Loader from 'components/Loading'
import { MuiThemeProvider, createMuiTheme, } from '@material-ui/core/styles'
import Modal from 'components/Modal'
import {withRouter} from 'react-router-dom'
// import Disc from './disc'
const themeButton = createMuiTheme({ 
    palette: { 
        secondary: {
            main:'#afe597',
            contrastText: '#FFFFFF',

        },
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
    } 
})
 function Index(props) {
    const master = useSelector(state => state.master)
    const {tab_active}=master
    const {slug}=props.match.params
    console.log('window.location.pathname', window.location.pathname)
    return (
        <div >
            <Loader/>
            <MuiThemeProvider theme={themeButton}>
            {/* {tab_active==='demografi'&&<Demografi profile={props.profile} token={props.token}/>}
            {tab_active==='assestment'&&<Assestment profile={props.profile} token={props.token}/>}
            {tab_active==='done'&&<Done profile={props.profile}/>}
            {tab_active==='portal'&&<Portal profile={props.profile} token={props.token}/>} */}
            {/* {slug==='disc'&&<Disc profile={props.profile} token={props.token}/>} */}
            </MuiThemeProvider>
        </div>
    )
}
export default withRouter(Index)