import React ,{useState}from 'react'
import './style.css'
import Layout from 'components/Layouts'
import {useDispatch,useSelector} from 'react-redux'
import ListProyek from './list_proyek'
import {tabToggle} from 'redux/actions/master'
import FormProyek from './form_proyek'
import FormPeserta from './form_peserta'
export default function Index(props) {
    const dispatch=useDispatch()
    const master = useSelector(state => state.master)
    const [tab_action,setTabAction]=useState('list_proyek')
    const {tab_active,tab_back}=master
    const tabsToggle=(key,back)=>{
        dispatch(tabToggle(key,back))
    }
    return (
        <div>
            <Layout>
                {tab_active==='list_proyek'&&<ListProyek tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} tabsToggle={tabsToggle} token={props.token} profile={props.profile}/>}
                {tab_active==='form_proyek'&&<FormProyek tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} tabsToggle={tabsToggle} token={props.token} profile={props.profile}/>}
                {tab_active==='form_peserta'&&<FormPeserta tab_active={tab_active} tab_back={tab_back} tab_action={tab_action} setTabAction={setTabAction} tabsToggle={tabsToggle} token={props.token} profile={props.profile}/>}
            </Layout>
        </div>
    )
}
