import React,{useEffect,useState} from 'react'
import './style.css'
import Folders from 'assets/icon/Folders.svg'
import UserTie from 'assets/icon/UserTie.svg'
import Sertifikat from 'assets/icon/Sertifikat.svg'
import { PieChart } from 'react-minimal-pie-chart';
import {Button,TextField,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import SearchImg from 'assets/icon/Search.png'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Skeleton from 'components/Skeleton'
import Skeletons  from 'react-loading-skeleton';
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce} from 'lodash'
import status_ilustrasi from 'assets/image/status_ilustrasi.png'
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import {getDashboard,getProject,getDetailProjectProgress,getDetailProject,getListUserProject,resetProyek,getListTest,getSaListProject,getSaDashboard,getListCredit,getHistoryCredit} from 'redux/actions/proyek'
import moment from 'moment'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import {modalToggle} from 'redux/actions/general'
const useStyles = makeStyles(theme => ({
    search: {
        [`& fieldset`]: {
            borderRadius: 8,
            height:30,
            margin: theme.spacing(1),
          },
          width:200,
          height:10,
          marginBottom:40
  },
  input:{
    fontSize:14,
    margin:3      
  }
}));
export default function Index(props) {
    const classes=useStyles()
    const dispatch = useDispatch()
    const proyek = useSelector(state => state.proyek)
    const general = useSelector(state => state.general)
    const [pagination,setPagination]=useState({
        page:1,
        perPage:10
    })
    const [search,setSearch]=useState('')
    const [search2,setSearch2]=useState('')
    const [tooltip,setTooltip]=useState('')
    const [detailkredit, setdetailkredit] = useState(false)
    const {proyek_list,proyek_pagination,beranda,beranda_sa,filter_sa_project,use_filter,list_credit,history_credit,history_credit_pagination,filter_history_credit}=proyek
    let {roleId,name}=props.profile
    let {from,to}=filter_sa_project.rangeValue
    let renderFrom=`${from.year}${from.month<10?`0${from.month}`:from.month}`
    let renderTo=`${to.year}${to.month<10?`0${to.month}`:to.month}`
    let renderFrom2=`${filter_history_credit.rangeValue.from.year}${filter_history_credit.rangeValue.from.month<10?`0${filter_history_credit.rangeValue.from.month}`:filter_history_credit.rangeValue.from.month}`
    let renderTo2=`${filter_history_credit.rangeValue.to.year}${filter_history_credit.rangeValue.to.month<10?`0${filter_history_credit.rangeValue.to.month}`:filter_history_credit.rangeValue.to.month}`
    useEffect(() => {
        dispatch(getListTest())
        if(proyek_list.length<1){
            if(roleId===3){
                dispatch(getSaDashboard())
                dispatch(getSaListProject(`/0/0/${pagination.page}/${pagination.perPage}/${search!==''?search:'*'}`))
            }else{
                dispatch(getDashboard())
                dispatch(getProject(`/${moment().format('MM')}/${moment().format('YYYY')}/${pagination.page}/${pagination.perPage}/${search!==''?search:'*'}`))
            }
            
        }
       
    }, [])
    
    const refreshData=async ()=>{
        await dispatch(getDashboard())
        let res=await dispatch(getProject(`/${moment().format('MM')}/${moment().format('YYYY')}/${pagination.page}/${pagination.perPage}/${search!==''?search:'*'}`))
        if(res){
            toast("Data berhasil diperbarui")
        }
    }
    const togglePagination=(page,pageLength)=>{
        if(roleId===3){
            dispatch(getSaListProject(`/${use_filter?renderFrom:0}/${use_filter?renderTo:0}/${page}/${pageLength}/${search!==''?search:'*'}`))
        }else{
            dispatch(getProject(`/${moment().format('MM')}/${moment().format('YYYY')}/${page}/${pageLength}/${search!==''?search:'*'}`))
        }
    }
    const historyCreditTogglePagination=(page,pageLength)=>{
        let new_assestment_id=[]
        filter_history_credit.assestmentId.map((data)=>{
            new_assestment_id.push(data.id)
        })
        dispatch(getHistoryCredit(`/${props.profile.clientId}/${new_assestment_id.length>0?new_assestment_id:0}/${use_filter?renderFrom2:0}/${use_filter?renderTo2:0}/${page}/${pageLength}/${search2!==''?search2:'*'}`))
    }
    const historyCreditSearch=debounce(async (value)=>{
        setSearch2(value)
        dispatch(getHistoryCredit(`/${props.profile.clientId}/0/0/0/1/10/${value.length>0?value:'*'}`))
    },1000)
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        if(value.length>0){
            if(roleId===3){
                await dispatch(getSaListProject(`/0/0/1/10/${value!==''?value:'*'}`))

            }else{
                await dispatch(getProject(`/${moment().format('MM')}/${moment().format('YYYY')}/${1}/${10}/${value!==''?value:'*'}`))

            }
            
        }else{
            if(roleId===3){
                await dispatch(getSaListProject(`/0/0/1/10/*`))

            }else{
                await dispatch(getProject(`/${moment().format('MM')}/${moment().format('YYYY')}/${1}/${10}/*`))

            }
        }
        
    },1000)
    const filterHistoryCredit=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter`,
            modal_component: "filter_history",
            modal_size:300,
            modal_data:filter_history_credit,
            modal_action:'filter_history'
        }))
    }
    const createProyek=async ()=>{
        let res=await dispatch(getListTest())
        if(res){
            props.tabsToggle('form_proyek','beranda')
            dispatch(resetProyek())
            props.tabsToggle('form_proyek','list_proyek')
            props.setTabAction('add_proyek')
        }
        
    }
    const detailProyek=async (data)=>{
        let res1=await dispatch(getDetailProjectProgress(data.id))
        let res2=await dispatch(getDetailProject(data.id))
        let res3=await dispatch(getListUserProject(`/${data.id}/2/0/0/1/100/*`))
        let res4=await dispatch(getListTest())
        if(res4){
            props.tabsToggle('form_proyek','beranda')
            props.setTabAction('detail_proyek')
        }
    }
    const filterData=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter`,
            modal_component: "filter_project",
            modal_size:300,
            modal_data:filter_sa_project,
            modal_action:'filter_project'
        }))
    }
    const toggleDetailKredit=()=>{
        dispatch(getListCredit(props.profile.clientId))
        dispatch(getHistoryCredit(`/${props.profile.clientId}/0/0/0/1/10/*`))
        setdetailkredit(!detailkredit)
    }
    return (
        <div>
            
            <div className='beranda-wrapper'>
                <div className='beranda-status-wrapper'>
                    <div className='beranda-ilustrasi'>
                        <img src={status_ilustrasi} style={{width:200,bottom:0,position:'absolute'}} />
                    </div>
                    <div className='beranda-status'>
                        <h2>Hi, <span>{name}</span></h2>
                        {/* <p>{roleId===3?'Berikut merupakan status penggunaan dan pelaksaan Assessment Center':'Berikut merupakan status penggunaan dan pelaksaan personality test company anda'}</p> */}
                        <p>Berikut merupakan status penggunaan dan pelaksaan personality test company anda</p>
                        <div className='beranda-grid'>
                            {roleId===3?
                            <div style={{width:'25%'}}>
                                <p className='semi-bold2'>Jumlah Client</p>
                                {general.isLoadingTable?
                                <>
                                <Skeletons height={30}/>
                                <Skeletons height={30}/>
                                </>
                                :
                                <div className='div-flex div-space-between'>
                                    <div>
                                    <p style={{fontSize:36,color:'#777777',fontWeight:'bold'}}>{beranda_sa.client}</p>
                                    </div>
                                    <img src={UserTie} style={{width:34}}/>
                                </div>
                                }
                            </div>
                            :
                            <div style={{width:'25%'}}>
                                <div className='div-flex div-space-between'>
                                    <p className='semi-bold2'>Penggunaan kredit</p>
                                    <p onClick={toggleDetailKredit} className='semi-bold2' style={{color:'#00a1ed',fontWeight:'bold',cursor:'pointer'}}>Lihat Detail</p>

                                </div>
                                {general.isLoadingTable?
                                <>
                                <Skeletons height={30}/>
                                <Skeletons height={30}/>
                                </>
                                :
                                <div className='div-flex div-space-between'>
                                    <div>
                                        <p style={{fontSize:10,color:'#00a1ed',fontWeight:'bold'}}>Sisa<br/> <span style={{fontSize:12}}>{beranda.sisa}</span></p>
                                        <p style={{fontSize:10,color:'#c3cfd5',fontWeight:'bold'}}>Digunakan<br/> <span style={{fontSize:12}}>{beranda.digunakan}</span></p>
                                    </div>
                                    <div style={{width:'60%',height:65}}>
                                        <PieChart
                                            data={[
                                                { title: 'Digunakan', value: beranda.digunakan, color: '#e1e7ea' },

                                                { title: 'Sisa', value: beranda.sisa, color: '#00a1ed' },
                                            ]}
                                        />
                                    </div>
                                </div> 

                                }
                            </div>
                            }
                            <div style={{width:'20%'}}>
                                <p className='semi-bold2'>Total project</p>
                                {general.isLoadingTable?
                                <>
                                <Skeletons height={30}/>
                                <Skeletons height={30}/>
                                </>
                                :
                                <div className='div-flex div-space-between'>
                                    <div>
                                    <p style={{fontSize:36,color:'#777777',fontWeight:'bold'}}>{roleId===3?beranda_sa.project:beranda.totalProject}</p>
                                    </div>
                                    <img src={Folders} style={{width:34}}/>
                                </div>
                                }
                            </div>
                            <div>
                                <p className='semi-bold2'>Jumlah peserta yang menyelesaikan</p>
                                {general.isLoadingTable?
                                <>
                                <Skeletons height={30}/>
                                <Skeletons height={30}/>
                                </>
                                :
                                <div className='div-flex div-space-between'>
                                    <div>
                                        <p style={{fontSize:36,color:'#777777',fontWeight:'bold'}}>{roleId===3?beranda_sa.peserta:beranda.menyelesaikan}</p>
                                    </div>
                                    <img src={Sertifikat} style={{width:34}}/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                {detailkredit&&
                <div className='card-content'>
                    <div className='card-table'>
                        <p className='semi-bold'>Daftar Kredit</p>
                        <div className='card-table__content'>
                        <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                            <TableCell align="left" >No.</TableCell>
                            <TableCell align="left" >Jenis Test</TableCell>
                            <TableCell align="center">Total Kredit</TableCell>
                            <TableCell align="center">Digunakan</TableCell>
                            <TableCell align="center" >Sisa Kredit</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {general.isLoadingTable?
                                    <Skeleton count={5}/>
                                :
                                list_credit.length<1?
                                <TableRow>
                                    <TableCell colSpan={7} style={{textAlign:'center'}}>Belum terdapat kredit terdaftar</TableCell>
                                </TableRow>
                                :list_credit.map((data,i)=>(
                                    <TableRow key={i}>
                                        <TableCell >{i+1}</TableCell>
                                        <TableCell >{data.title}</TableCell>
                                        <TableCell align="center">{data.total}</TableCell>
                                        <TableCell align="center">{data.total-data.balance}</TableCell>
                                        <TableCell align="center">{data.balance}</TableCell>
                                    </TableRow>
                                ))
                                
                                }
                            </TableBody>
                        </Table>
                        </div>
                    </div>
                    <div className='card-table'>
                        <p className='semi-bold'>Riwayat Kredit</p>
                        <div className='card-table__head'>
                            <TextField
                                placeholder="Cari"
                                variant='outlined'
                                size='small'
                                InputLabelProps={{shrink: false}}
                                className={classes.search}
                                inputProps={{
                                    className:classes.input
                                }}
                                onChange={(e)=>historyCreditSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                            <Button onClick={filterHistoryCredit} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
                        </div>
                        <div className='card-table__content'>
                        <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                            <TableCell align="left" >No.</TableCell>
                            <TableCell align="left" >Aktifitas</TableCell>
                            <TableCell align="left">Jenis Test</TableCell>
                            <TableCell align="left">Tanggal</TableCell>
                            <TableCell align="left" >Keterangan</TableCell>
                            <TableCell align="center">Jumlah</TableCell>
                            <TableCell align="center">Sisa Kredit</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {general.isLoadingTable?
                                    <Skeleton count={7}/>
                                :
                                history_credit.length<1?
                                <TableRow>
                                    <TableCell colSpan={7} style={{textAlign:'center'}}>Belum terdapat aktifitas kredit</TableCell>
                                </TableRow>
                                :history_credit.map((data,i)=>(
                                    <TableRow key={i}>
                                        <TableCell >{i+1}</TableCell>
                                        <TableCell >{data.activity}</TableCell>
                                        <TableCell>{data.assessment}</TableCell>
                                        <TableCell>{moment(data.lastUpdated).format('DD MMM YYYY')}</TableCell>
                                        <TableCell>{data.description}</TableCell>
                                        <TableCell align="center" style={{color:data.amount>=0?'#a8e090':'#ff6e79'}}>{data.amount>=0&&'+'}{data.amount}</TableCell>
                                        <TableCell align="center" >{data.balance}</TableCell>
                                    </TableRow>
                                ))
                                
                                }
                            </TableBody>
                        </Table>
                        <div className='card-table__pagination'>
                        {history_credit_pagination!==null&&
                        <TablePagination
                                className="card-pagination"
                                type="reduced"
                                page={history_credit_pagination.page}
                                pageLength={history_credit_pagination.perPage}
                                totalRecords={history_credit_pagination.total}
                                totalRecords={history_credit_pagination.total}
                                onPageChange={({ page, pageLength }) => {
                                    historyCreditTogglePagination(page,pageLength)
                                }}
                                prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                nextPageRenderer={() => <img src={CevronRight}/>}
                            />
                        }
                    </div>
                        </div>
                    </div>
                </div>
                }
                <br/>
                {roleId===3?
                <>
                    <div className='head-section'>
                        <div>
                            <h4>Daftar project</h4>
                        </div>
                        
                    </div>
                    <div className='card-content'>
                        <div className='card-table'>
                            <div className='card-table__head'>
                                <TextField
                                    placeholder="Cari"
                                    variant='outlined'
                                    size='small'
                                    InputLabelProps={{shrink: false}}
                                    className={classes.search}
                                    inputProps={{
                                        className:classes.input
                                    }}
                                    onChange={(e)=>searchToggle(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <img alt="search" src={SearchImg} style={{width:15}}/>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button onClick={filterData} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
                            </div>
                            <div className='card-table__content'>
                            <Table  size="small" aria-label="a dense table">
                                <TableHead>
                                <TableRow>
                                <TableCell align="left" style={{width:5}}>No.</TableCell>
                                <TableCell align="left" style={{width:90}}>Nama Proyek</TableCell>
                                <TableCell align="left">Tanggal Mulai</TableCell>
                                <TableCell align="left">Tanggal Selesai</TableCell>
                                <TableCell align="left" >Perusahaan client</TableCell>
                                <TableCell align="left">Jenis test</TableCell>
                                <TableCell align="center">Jumlah Peserta</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {general.isLoadingTable?
                                        <Skeleton count={7}/>
                                    :
                                    proyek_list.length<1?
                                    <TableRow>
                                        <TableCell colSpan={7} style={{textAlign:'center'}}>Belum ada Project terdaftar</TableCell>
                                    </TableRow>
                                    :proyek_list.map((data,i)=>(
                                        <TableRow key={i}>
                                            <TableCell >{i+1}</TableCell>
                                            <TableCell >{data.name}</TableCell>
                                            <TableCell>{moment(data.startDate).format('DD MMM YYYY')}</TableCell>
                                            <TableCell>{moment(data.endDate).format('DD MMM YYYY')}</TableCell>
                                            <TableCell>{data.clientName}</TableCell>
                                            <TableCell>{data.tests}</TableCell>
                                            <TableCell align="center">{data.participants}</TableCell>
                                        </TableRow>
                                    ))
                                    
                                    }
                                </TableBody>
                            </Table>
                            <div className='card-table__pagination'>
                            {proyek_pagination!==null&&
                            <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={proyek_pagination.page}
                                    pageLength={proyek_pagination.perPage}
                                    totalRecords={proyek_pagination.total}
                                    totalRecords={proyek_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination(page,pageLength)
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            }
                        </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                <div className='head-section'>
                    <div>
                        <h4>Project yang sedang berlangsung bulan {moment().format('MMMM')}</h4>
                    </div>
                    
                </div>
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__head'>
                        <TextField
                            placeholder="Cari"
                            variant='outlined'
                            size='small'
                            InputLabelProps={{shrink: false}}
                            className={classes.search}
                            inputProps={{
                                className:classes.input
                            }}
                            onChange={(e)=>searchToggle(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <img alt="search" src={SearchImg} style={{width:15}}/>
                                </InputAdornment>
                                ),
                              }}
                        />
                        <Button onClick={refreshData} color="primary" className='btn-remove-capital' variant="text">Perbarui data</Button>
                    </div>
                    <div className='card-table__content'>
                    <Table  size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="left" style={{width:10}}>No.</TableCell>
                        <TableCell align="left" style={{width:90}}>Nama Proyek</TableCell>
                        <TableCell align="left">Tanggal</TableCell>
                        <TableCell align="left">Periode</TableCell>
                        <TableCell align="left" style={{width:50}}>Pembuat</TableCell>
                        <TableCell align="left">Progress pengerjaan</TableCell>
                        <TableCell align="center">Aksi</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {general.isLoadingTable?
                                <Skeleton count={7}/>
                            :
                            proyek_list.length<1?
                            <TableRow>
                                <TableCell colSpan={7} style={{textAlign:'center'}}>belum ada proyek survey di bulan {moment().format('MMMM')}, <Button onClick={createProyek} className='btn-remove-capital' variant="text" color="primary">Tambah Proyek</Button></TableCell>
                            </TableRow>
                            :proyek_list.map((data,i)=>(
                                <TableRow key={i}>
                                    <TableCell >{i+1}</TableCell>
                                    <TableCell >{data.name}</TableCell>
                                    <TableCell>{moment(data.startDate).format('DD MMM YYYY')}</TableCell>
                                    <TableCell>{moment(data.endDate).format('DD MMM YYYY')}</TableCell>
                                    <TableCell>{data.createdBy}</TableCell>
                                    <TableCell style={{position:'relative'}}>
                                    {tooltip[i]&&<div className='tooltip-progress'>
                                        <p>Peserta<br/><span style={{color:'#CCCCCC',fontSize:12}}>{data.completed}/{data.assigned}</span></p>
                                    </div>}
                                    <progress onMouseEnter={()=>setTooltip({[i]:true})} onMouseLeave={()=>setTooltip({[i]:false})} className='bar-desktop' max={data.assigned} value={data.completed} data-label={`${data.completed===0?0:((data.completed/data.assigned)*100).toFixed(0)}%`}>
                                    </progress>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={()=>detailProyek(data)} color="primary" variant="text" style={{textTransform:'none'}}>Lihat Detail</Button>
                                    </TableCell>

                                </TableRow>
                            ))
                            
                            }
                        </TableBody>
                    </Table>
                    <div className='card-table__pagination'>
                    {proyek_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={proyek_pagination.page}
                            pageLength={proyek_pagination.perPage}
                            totalRecords={proyek_pagination.total}
                            totalRecords={proyek_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
                    </div>
                </div>
            </div>
            </>}
            </div>
        </div>
    )
}
