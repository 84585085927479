import React,{useState,useEffect,useRef} from 'react'
import Check from 'assets/icon/Check.svg'
import {Button,Checkbox,FormGroup ,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@material-ui/core'
import Countdown from 'react-countdown';
import {useSelector,useDispatch} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import {postCfit,setCfit,setCfitActiveSub} from 'redux/actions/assestment'
import {isMobile} from 'react-device-detect'
// import './style.css'
import Loader from 'components/Loading'
import infomedia from 'assets/image/Logo_Infomedia_white.png'

const Navbar=React.memo(props=>{
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    const [start, setStart] = useState(Date.now())
    const [second,setSecond]=useState(props.duration)
    const ref = useRef(null)
    useEffect(() => {
        if(props.start_time!==null){
            setStart(props.start_time)
            // setStart(10000)
        }
    }, [])
    const startCountDown=()=>{
        ref.current.start()
    }
    const onClickHelp=()=>{
        // ref.current.pause()
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "",
            modal_component: "intruksi_cfit",
            modal_size:700,
            modal_type:'',
            modal_data:{
                cfit:props.cfit,
                // action:()=>startCountDown()
            },
            modal_action:'success',
        }))
    }
    const onComplete=(e)=>{
        if(assestment.cfit_active_sub!==7){
            if(e.completed){
                props.resetInstruction()
                dispatch(setCfitActiveSub(assestment.cfit_active_sub+1))
            }
        }else{
            // alert('final sub')
            props.setforcePost(true)
        }
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className='timer-expired'>00:00:00</span>;
            
        } else {
            return <span className='timer'>00:{minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}</span>;
        }
      };
      let username=props.profile.userName.split(" ")[0]

    return(
        <div className='navbar-wrapper'>
            <div className='div-flex div-space-between div-align-center navbar' >
                <div className='d-flex'>
                        <img
                        src={infomedia}
                        width="auto"
                        height={35}
                        />
                        </div>
                <h3>Selamat mengerjakan <br className='show-mobile'/> <span className='name'>{username}</span></h3>
            <div className=' assestmen-toolbar div-flex div-align-center div-space-between'>
                <div className='div-flex div-align-center'>
                    <p className='hide-mobile' style={{color:'white',fontWeight:'bold',margin:0}}>Petunjuk pengisian</p>
                    &nbsp;&nbsp;
                    <div onClick={onClickHelp} className='circle-help' ><span>?</span></div>

                </div>

                <div className='hide-mobile' style={{width:1.3,height:30,backgroundColor:'white'}}></div>

                <h3>Sisa waktu <br className='show-mobile'/> 
                    <Countdown
                        date={start + second}
                        renderer={renderer}
                        onComplete={onComplete}
                        ref={ref}
                    />
                </h3>
            </div>
            </div>
            
        </div>
    )
})
const Option=(props)=>{
    let {data,sub,chooseAnswer,index}=props
    const renderWidthOption=(count,sub)=>{
        switch (sub) {
            case 1:
                return 100
            case 2:
                return 115
            case 3:
                return 70
            case 4:
                return 90
            default:
                break;
        }
    }
    if(data.type_options==='radio'){
        return(
            <FormControl component="fieldset">
                <RadioGroup value={data.answer} onChange={(e)=>chooseAnswer(index,0,e.target.value,data.type_options)} row aria-label="position" name="position" defaultValue="top">
                    <div className='assestment-answer'>
                        {data.question_answer.map((answer,i)=>(
                            <div key={i} style={{width:!isMobile?renderWidthOption(data.question_answer.length,sub):null}} className='assestment-answer-item'>
                                <img src={answer.img} onClick={()=>chooseAnswer(index,0,answer.value,data.type_options)} className='assestment-subimage'/>
                                <FormControlLabel
                                    value={answer.value}
                                    control={<Radio style={{padding:0}} color="primary" size="small"/>}
                                    label={<b style={{fontSize:22}}>{answer.text}</b>}
                                    labelPlacement="top"
                                    
                                />
                            </div>
                        ))}
                    </div>
                </RadioGroup>
            </FormControl>
        )
    }else if(data.type_options==='checkbox'){
        return(
            <FormControl component="fieldset">
                <FormGroup aria-label="position" value={data.answer} row >
                    <div className='assestment-answer'>
                        {data.question_answer.map((answer,i)=>(
                            <div key={i} style={{width:!isMobile?renderWidthOption(data.question_answer.length,sub):null}} className='assestment-answer-item'>
                                <img onClick={()=>chooseAnswer(index,i,answer.value,data.type_options)} src={answer.img} className='assestment-subimage'/>
                                <FormControlLabel
                                    value={answer.value}
                                    control={<Checkbox style={{padding:0}} color="primary" size="small"/>}
                                    label={<b style={{fontSize:22}}>{answer.text}</b>}
                                    labelPlacement="top"
                                    checked={answer.checked}
                                    onChange={(e)=>chooseAnswer(index,i,e.target.value,data.type_options)}
                                />
                            </div>
                        ))}
                    </div>
                </FormGroup>
            </FormControl>
        )
    }
}
export default function Assestment(props) {
    const dispatch=useDispatch()
    
    const assestment = useSelector(state => state.assestment)
    const ref = useRef(null)
    const {cfit_start_time,cfit_active_sub}=assestment
    const [expired,setExpired]=useState(false)
    const [forcePost, setforcePost] = useState(false)

    let {cfit,resetInstruction}=props
    useEffect(() => {
        if(forcePost){
            postJawaban()
        }
        // if (performance.navigation.type == 1) {
        //     let time=Date.now()-cfit.start_time
        //     assestment.cfit[cfit_active_sub].start_time=assestment.cfit[cfit_active_sub].start_time+time/5
        //     dispatch(setCfit([...assestment.cfit]))
        //   }
    }, [forcePost])
    
    const handleDisable=()=>{

    }
    const postJawaban=()=>{
        let section_1=[]
        let section_2=[]
        let section_3=[]
        let section_4=[]
        let filter_section1=assestment.cfit.filter((data)=>{
            return data.sub===1&&data.type==='assestment'
        })
        let filter_section2=assestment.cfit.filter((data)=>{
            return data.sub===2&&data.type==='assestment'
        })
        let filter_section3=assestment.cfit.filter((data)=>{
            return data.sub===3&&data.type==='assestment'
        })
        let filter_section4=assestment.cfit.filter((data)=>{
            return data.sub===4&&data.type==='assestment'
        })
        filter_section1[0].question.map((data)=>{
            section_1.push(data.answer)
        })
        filter_section2[0].question.map((data)=>{
            let new_check=[]
            data.question_answer.map((ques=>{
                if(ques.checked){
                    new_check.push(ques.value)
                }
            }))
            if(new_check.length<1){
                new_check=[0,0]
            }
            if(new_check.length==1){
                new_check.push(0)
            }
            section_2.push(new_check)
        })
        filter_section3[0].question.map((data)=>{
            section_3.push(data.answer)
        })
        filter_section4[0].question.map((data)=>{
            section_4.push(data.answer)
        })
        let data={
            userId:props.profile.id,
            section1:section_1,
            section2:section_2,
            section3:section_3,
            section4:section_4,
        }
        dispatch(postCfit(data))
        setforcePost(false)
        console.log('data', data)
    }

    const onNext=()=>{
        if(cfit.sub===4&&cfit.type==='assestment'){
            postJawaban()
        }else{
            props.resetInstruction()
            props.subToggle('next')
        }
        
    }
    const handleCheckboxChange=(i1,i2,answer,type)=>{
        let current_answer=cfit.question[i1].question_answer[i2]
        if(current_answer.checked===true){
            cfit.question[i1].question_answer[i2].checked=!cfit.question[i1].question_answer[i2].checked
            dispatch(setCfit([...assestment.cfit]))
        }else{
            let filter_checked=cfit.question[i1].question_answer.filter((data)=>{
                return data.checked===true
            })
            if(filter_checked.length<2){
                cfit.question[i1].question_answer[i2].checked=!cfit.question[i1].question_answer[i2].checked
                dispatch(setCfit([...assestment.cfit]))
            }
        }
    }
    const chooseAnswer=(i1,i2,answer,type)=>{
        if(type==='radio'){
            cfit.question[i1].answer=parseInt(answer)
            dispatch(setCfit([...assestment.cfit]))
        }else if(type==='checkbox'){
            handleCheckboxChange(i1,i2,answer,type)
        }
        
    }
    const renderSub=(data,i)=>{
        if(cfit.sub==1){
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div className='assestment-question'>
                    <div className='circle-number'>{i+1}</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='question-wrapper'>
                        {data.question_img!==null&&<><img src={data.question_img} className='assestment-image'/><br/><br/><br/></>}
                        <Option index={i} chooseAnswer={chooseAnswer} data={data} sub={cfit.sub}/>
                    </div>
                    </div>
                </div>
            )
            
        }
        if(cfit.sub==2){
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                <div className='assestment-question'>
                    <div className='circle-number'>{i+1}</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='question-wrapper'>
                        {data.question_img!==null&&<><img src={data.question_img} className='assestment-image'/><br/><br/></>}
                        <Option index={i} chooseAnswer={chooseAnswer} data={data} sub={cfit.sub}/>
                    </div>
                </div>
                </div>
            )
        }
        if(cfit.sub==3){
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                <div className='assestment-question'>
                    <div className='circle-number'>{i+1}</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='question-wrapper sub3'>
                        {data.question_img!==null&&<><img src={data.question_img} className='assestment-image-sub3'/><br /><br className='hide-mobile'/></>}
                        <div className='sub3-option-wrapper'>
                            <Option index={i} chooseAnswer={chooseAnswer} data={data} sub={cfit.sub}/>
                        </div>
                    </div>
                </div>
                </div>
            )
        }
        if(cfit.sub==4){
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                <div className='assestment-question'>
                    <div className='circle-number'>{i+1}</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className='question-wrapper sub4'>
                        {data.question_img!==null&&<><img src={data.question_img} className='assestment-image-sub4'/><br /><br className='hide-mobile'/></>}
                        <div className='sub4-option-wrapper'>
                            <Option index={i} chooseAnswer={chooseAnswer} data={data} sub={cfit.sub}/>
                        </div>
                    </div>
                </div>
                </div>
            )
        }
    }
    const moveSub=(key)=>{
        props.subToggle(key)
    }
    const pauseCountDown=()=>{
        ref.current.pause()
    }
    const startCountDown=()=>{
        ref.current.start()
    }
    console.log(`ref`, ref)
    return (
        <div>
            <Loader/>

            <Navbar resetInstruction={resetInstruction} pauseCountDown={pauseCountDown} startCountDown={startCountDown} countRef={ref} start_time={cfit.start_time} subToggle={props.subToggle} cfit_start_time={cfit_start_time} forcePost={forcePost} setforcePost={setforcePost} active_sub={props.active_sub} setActiveSub={props.setActiveSub} cfit={cfit}  duration={cfit.duration}  expired={expired} setExpired={setExpired} profile={props.profile}/>

            <div className='assestment-wrapper'>
                <div className='assestment-container' >
                    <h4 className='assestment-container-h4 '>Intruksi:</h4>
                    <div className='assestment-intruksi assestment-container-p' dangerouslySetInnerHTML={{__html:cfit.desc}}></div>
                    {cfit.question.map((data,i)=>(
                        <div key={i} >
                            <br/><br/>
                            {renderSub(data,i)}
                        </div>
                    ))}
                    
                </div>
                <br/>
                <div style={{textAlign:'center'}} >
                    <Button disabled={handleDisable()} onClick={onNext} style={{width:300,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Selanjutnya</Button>
                </div>
                <br/>
            </div>
        </div>
    )
}
