import React, { useState,useEffect } from 'react'
import {Button,TextField} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles, makeStyles} from '@material-ui/core/styles'
import {useSelector,useDispatch} from 'react-redux'
import {setDetailClient,postKredit,getDetailClient} from 'redux/actions/client'
import {getHistoryCredit} from 'redux/actions/proyek'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
}));
export default function Add_kredit(props) {
    const dispatch = useDispatch()
    const proyek = useSelector(state => state.proyek)
    const client = useSelector(state => state.client)
    const [kredit, setkredit] = useState([])
    const [deskripsi, setdeskripsi] = useState('')
    let {list_test}=proyek
    let {client_detail}=client
    useEffect(()=>{
        let new_k=[]
        list_test.map((data)=>{
            new_k.push({assestment:data,balance:0})
        })
        setkredit(new_k)
    },[])
    const onClickSimpan=async ()=>{
        if(props.modal_data.tab==='add'){
            let k_with_balance=kredit.filter((data)=>{
                return data.balance!==0
            })
            let new_k=[...client_detail.kredit,...k_with_balance]
            let new_without_duplicate=[]
            new_k.map((data,i)=>{
                if(new_without_duplicate.length<1){
                    new_without_duplicate.push(data)
                }else{
                    let find=new_without_duplicate.filter((find)=>{
                        return find.assestment.id===data.assestment.id
                    })
                    if(find.length>0){
                        find[0].balance+=data.balance
                    }else{
                        new_without_duplicate.push(data)
                    }
                }
            })
            dispatch(setDetailClient({kredit:new_without_duplicate}))
            dispatch(setDetailClient({desc_kredit:deskripsi}))
            props.modalToggleReset()
        }else{
            let new_kredit=[]
            let filter=kredit.filter((data)=>{
                return data.balance!==0
            })
            filter.map((data)=>{
                new_kredit.push({assessmentId:data.assestment.id,balance:data.balance})
            })
            let data={
                clientId:client_detail.clientId,
                userId:props.profile.id,
                credits:new_kredit,
                description:deskripsi
            }
            let res =await dispatch(postKredit(data))
            if(res){
                dispatch(getDetailClient(`/${client_detail.clientId}`))
                dispatch(getHistoryCredit(`/${client_detail.clientId}/0/0/0/1/10/*`))
            }
            // console.log('kredit', kredit)
        }
        
    }

    const onChange=(value,i)=>{
        kredit[i].balance=parseInt(value)
        setkredit(kredit)
    }
    const classes=useStyles()

    return (
        <div>
            {kredit.map((data,i)=>(
                <div key={i}>
                    <div  className='div-flex div-space-between div-align-center' >
                        <p style={{fontSize:14,color:'#252525',fontWeight:500}}>{data.assestment.text}</p>
                        <input type="number" onChange={(e)=>onChange(e.target.value,i)}  className='input-kredit' placeholder="0"/>
                    </div>
                    <div style={{width:'100%',height:1,backgroundColor:'#cccccc',margin:'8px 0px 8px 0px'}}></div>
                </div>
            ))}
            <br/>
            <TextField
                label={<>Deskripsi</>}
                variant='outlined'
                value={deskripsi}
                size='small'
                className={classes.textField}
                onChange={(e)=>setdeskripsi(e.target.value)}
            />
            <div style={{textAlign:'right'}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Tambah Kredit</Button>
                </MuiThemeProvider>
            </div>
        </div>
    )
}
