import React from 'react'

export default function Tutorial_dtra() {
    return (
        <div>
            <div className='hide-mobile'>Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
            <br/><br/>
            Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah kiri dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah kanan. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</div>
            <div className='show-mobile'>Setiap pertanyaan dibuat berpasangan yang menggambarkan keadaan perusahaan Bapak/Ibu saat ini.
            <br/><br/>
            Pilihlah angka dari skala 1 - 4, angka 1 artinya sangat sesuai dengan pernyataan di sebelah atas dan angka 4 artinya sangat sesuai dengan pernyataan di sebelah bawah. Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</div>
        </div>
    )
}
