import * as actionTypes from "../constants/assestment";
import data from 'service/data_dum'
const initialState={
    cfit:data.cfit,
    papi:data.papi,
    question:data.disc,
    toefl:data.toefl,
    
    record:true,
    count_foto:0,
    is_update_bio:false,
    cfit_start_time:null,
    disc_start_time:null,
    papi_start_time:null,
    toefl_start_time:null,

    active_sub:0,
    active_part:0,

    cfit_active_sub:0,
    papi_active_sub:0
}

export default (state=initialState,action)=>{
    
    switch (action.type) {
        case actionTypes.SET_PAPI_ACTIVE_SUB:
            return{
                ...state,
                papi_active_sub:action.payload
            }
        case actionTypes.SET_CFIT_ACTIVE_SUB:
            return{
                ...state,
                cfit_active_sub:action.payload
            }
        case actionTypes.SET_ACTIVE_SUB:
            return{
                ...state,
                active_sub:action.payload
            }
        case actionTypes.SET_ACTIVE_PART:
            return{
                ...state,
                active_part:action.payload
            }
        case actionTypes.SET_COUNT_FOTO:
            // console.log('from reducer',initialState.count_foto)
            return{
                ...state,
                count_foto:action.payload
            }
        case actionTypes.RESET_ALL:
            return{
                ...initialState
            }
        case actionTypes.SET_CFIT_TIME:
            return{
                ...state,
                cfit_start_time:action.payload
            }
        case actionTypes.SET_DISC_TIME:
            return{
                ...state,
                disc_start_time:action.payload
            }
        case actionTypes.SET_PAPI_TIME:
            return{
                ...state,
                papi_start_time:action.payload
            }
        case actionTypes.SET_TOEFL_TIME:
            return{
                ...state,
                toefl_start_time:action.payload
            }
        case actionTypes.SET_QUESTION:
            return{
                ...state,
                question:action.payload
            }
        case actionTypes.SET_RECORD:
            return{
                ...state,
                record:action.payload
            }
        case actionTypes.RESET_QUESTION:
            return{
                ...state,
                question:data.disc
            }
        case actionTypes.SET_CFIT:
            return{
                ...state,
                cfit:action.payload
            }
        case actionTypes.RESET_CFIT:
            return{
                ...state,
                cfit:data.cfit
            }
        case actionTypes.SET_PAPI:
            return{
                ...state,
                papi:action.payload
            }
        case actionTypes.RESET_PAPI:
            return{
                ...state,
                papi:data.papi
            }
        case actionTypes.SET_TOEFL:
            return{
                ...state,
                toefl:action.payload
            }
        case actionTypes.RESET_TOEFL:
            return{
                ...state,
                toefl:data.toefl
            }
        
        case actionTypes.SET_UPDATE_BIO:
            return{
                ...state,
                is_update_bio:action.payload
            }
        default:
            return state;
    }
}

