import React,{useState} from 'react'
import ChevronLeft from 'assets/icon/chevron-blue-left.svg'
import ChevronRight from 'assets/icon/chevron-blue-right.svg'
// import '../../../views/assestment/style.css'
export default function CfitIntruksi(props) {
    const {cfit}=props.modal_data
    const [active_intruction, setActiveIntruction] = useState(0)
    const intructionToggle=(key)=>{
        if(key==='next'){
            setActiveIntruction(active_intruction+1)
        }else{
            setActiveIntruction(active_intruction-1)
        }
    }
    const onClickChevron=(key)=>{
        intructionToggle(key)
    }
    return (
        <div>
            
            <div className='intruksi-modal'>
                <div className='welcome-mobile'>
                <h3>Bagaimana menjalani proses Intelligence Test ?</h3>
                <p className='instruksi-text' style={{fontWeight:'bold',margin:'0px 0px 5px 0px'}}>Instruksi Tahap {cfit.sub}:</p>
                <div dangerouslySetInnerHTML={{__html:cfit.desc}}></div>
                <br/>
                <div className='hide-mobile'>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                        <div className='div-flex div-align-center'>
                            {active_intruction!==0&&<img onClick={()=>onClickChevron('back')} src={ChevronLeft} className='chevron-contoh'/>}
                            <p style={{color:'#00a1ed',fontSize:14,fontWeight:'bold',margin:'0px 5px 0px 5px'}}>Contoh Lainnya</p>
                            {active_intruction!==cfit.intruction.length-1&&<img onClick={()=>onClickChevron('next')} src={ChevronRight} className='chevron-contoh'/>}
                        </div>
                    </div>
                
                    <br/>
                    <img className='image-contoh' src={cfit.intruction[active_intruction].example_img}/>
                    <p>Jawaban yang benar adalah <span style={{color:'#00a1ed'}}>{cfit.intruction[active_intruction].answer}</span></p>
                </div>
                <div className='show-mobile' style={{width:'100%'}}>
                    <div className='div-flex div-space-between'>
                        <p>Contoh:</p>
                        <p style={{color:'#cccccc'}}>Contoh lainnya geser</p>
                    </div>
                    <br/>
                    <div className='card-grid-modal'>
                        
                        {cfit.intruction.map((data)=>(
                            <div style={{width:300}}>
                                <img className='image-contoh' src={data.example_img}/>
                                <p>Jawaban yang benar adalah <span style={{color:'#00a1ed'}}>{data.answer}</span></p>
                            </div>
                        ))}
                    </div>
                </div>
                <br/>
            </div>
            </div>
        </div>
    )
}
