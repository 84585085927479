import {apiCall} from 'service/apiCall'
import {setLoading,setError,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import { URL, USER,PASS } from 'service/base_url'
import * as actionTypes from 'redux/constants/client'
import {tabToggle} from './master'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const setFilterClient=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_FILTER,
        payload:payload
    })
}
export const resetDetail=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_DETAIL,
        payload:payload
    })
}
export const setDetailClient=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_CLIENT_DETAIL,
        payload:payload
    })
}
export const getIndustry=()=>async dispatch=>{
    // dispatch(setLoading(true))
        let dataReq={
            url:'/sa/industry',
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch({
                type:actionTypes.GET_INDUSTRY,
                payload:res.data
            })
        }else{
            dispatch(setLoading(false))
        }
}
export const getClientList=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/sa/client${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionTypes.GET_CLIENT_LIST,
            payload:{
                client_list:res.data.clients,
                client_pagination:res.data.info
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}
export const getDetailClient=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/sa/client${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        let new_kredit=[]
        res.data.credits.map((data)=>{
            new_kredit.push({assestment:{id:data.assessmentId,text:data.title},balance:data.balance})
        })
        dispatch(setDetailClient({company:res.data.company}))
        dispatch(setDetailClient({clientId:res.data.clientId}))
        dispatch(setDetailClient({industry:res.data.industry}))
        dispatch(setDetailClient({phone:res.data.phone}))
        dispatch(setDetailClient({address:res.data.address}))
        dispatch(setDetailClient({kredit:new_kredit}))
        dispatch(setDetailClient({admin:res.data.admins}))
        
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}

export const deleteClient=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/client${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil dihapus",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const postClient=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/clientdata`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil disimpan",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putClient=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/clientdata`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil diupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
        }else{
            dispatch(setLoading(false))
        }
}
export const postClient2=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/client`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil disimpan",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putClient2=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/client`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Client berhasil diupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
        }else{
            dispatch(setLoading(false))
        }
}

export const postKredit=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/add`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Kredit Berhasil diupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const postAdmin=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/clientadmin`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Admin Berhasil disimpan",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putAdmin=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/clientadmin`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Admin Berhasil diupdate",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const deleteAdmin=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/sa/clientadmin${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Admin Berhasil dihapus",
                modal_component: "client",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
