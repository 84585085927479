
import * as actionType from '../constants/master'
import {setLoading,setLoadingTable,modalToggle} from './general'
// import {alertToggle} from './alert'
import {apiCall} from '../../service/apiCall'
import { get } from "lodash";

export const tabToggle=(key,back)=>dispatch=>{
    dispatch({
        type:actionType.SET_TAB,
        payload:{
            tab_active:key,
            tab_back:back
        }
    })
}
export const toastMsg=(msg)=>dispatch=>{
    dispatch({
        type:actionType.SET_TOAST_MSG,
        payload:msg
    })
}
export const setSearch=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_SEARCH,
        payload:data
    })
}
export const setPagination=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_PAGINATION,
        payload:data
    })
}




