import React, { Component } from 'react'
import Checklist from 'assets/icon/checklist.png'
import SuccessUpload from 'assets/image/success_upload.png'
import jam_pasir from 'assets/image/jam_pasir.png'
import close from 'assets/icon/close.svg'
import {connect} from 'react-redux'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
import {sendReportKominfo} from 'redux/actions/proyek'
// import {setAction} from 'redux/actions/event'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button ,CircularProgress} from '@material-ui/core'

const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00ACC1',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
class alert extends Component {
    
    render() {
        const {modal_data,modal_title,modal_action,}=this.props.general
        const {count_progress}=this.props.proyek
        return (
            <div className='alert-container'>
                <h4>Proses kirim laporan hasil peserta</h4>
                <img src={jam_pasir} style={{width:'30%'}}/>
                <br/>
                {/* <p>{modal_data.msg}</p> */}
                <div>
                    <p><span><CircularProgress size={20} /></span>&nbsp;Jumlah laporan hasil peserta terkirim : &nbsp;<span style={{color:'#A8E090',fontWeight:'bold'}}>{count_progress}</span> </p>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    proyek:state.proyek,
})
const mapDispatchToProps={
    modalToggleReset,
    modalToggle,
    sendReportKominfo
}
export default connect(mapStateToProps,mapDispatchToProps)(alert)