import React,{useRef,useState,useEffect} from 'react'
import './style.css'
import ReactAudioPlayer from 'react-audio-player'
import {useSelector,useDispatch} from 'react-redux'
import {setToefl} from 'redux/actions/assestment'
import { setRef } from '@material-ui/core'
import { PlayArrow} from '@material-ui/icons';
import {isSafari} from 'react-device-detect'
import { MuiThemeProvider, createMuiTheme, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#AAAAAA',
            contrastText: '#FFFFFF',

        },
    } 
})
export default function Index(props){
    const assestment = useSelector(state => state.assestment)
    const [can_play, setCan_play] = useState(true)
    const [can_play2,setCanPlay2]=useState(false)
    const [section2, setsection2] = useState(false)
    useEffect(() => {
        if(parrent!==null){
            setsection2(true)
            let childParrent=props.question.filter((data)=>{
                return data.audio_src===props.src
            })[0].parrent
            let findParrent=props.question.filter((data)=>{
                return data.audio_question[0].number===childParrent
            })[0]
            if(isSafari){
                if(!findParrent.can_play&&props.toefl.can_play){
                    setCanPlay2(true)
                    // setCan_play(true)
                }else{
                    // setCan_play(false)
                    setCanPlay2(false)
                    // setCan_play(false)
                }
            }else{
                if(!findParrent.can_play&&props.toefl.can_play){
                    // setCanPlay2(true)
                    setCan_play(true)
                }else{
                    // setCan_play(false)
                    // setCanPlay2(false)
                    setCan_play(false)
                }
            }
            
        }else{
            setsection2(false)
            if(props.toefl.can_play){
                setCan_play(true)
                setCanPlay2(false)

            }else{
                setCan_play(false)
                setCanPlay2(false)

            }
        }
    }, [assestment.toefl])
    let {parrent}=props.toefl
   
    let ref = useRef(null)
    let supposedCurrentTime = 0;
    const dispatch=useDispatch()
    const onListen=()=>{
        if(!ref.current.audioEl.current.seeking){
            supposedCurrentTime=ref.current.audioEl.current.currentTime
        }
    }
    const onEnded=()=>{
        props.toefl.can_play=false
        dispatch(setToefl([...assestment.toefl]))
        let {i1,i2}=props
        setCan_play(false)
        setCanPlay2(false)
        supposedCurrentTime = 0;
    }   
    const onSeeked=(e)=>{
        let delta=ref.current.audioEl.current.currentTime-supposedCurrentTime
        delta = Math.abs(delta);
        if(delta>0.01){
         ref.current.audioEl.current.currentTime=supposedCurrentTime
        }
     }
    const renderPlayer=()=>{
        if(!section2){
            if(can_play){
                return(
                    <ReactAudioPlayer
                        preload='metadata'
                        ref={ref}
                        src={props.src}
                        className="audio-player"
                        controls
                        controlsList="nodownload"
                        onEnded={onEnded}
                        onSeeked={onSeeked}
                        onListen={onListen}
                        listenInterval={1000}
                    />
                )
            }else{
                return <div className='disable-player'><MuiThemeProvider theme={themeButton}><PlayArrow color="primary"/></MuiThemeProvider>&nbsp;&nbsp;&nbsp;<p>Has been play</p></div>
            }
        }else{
            if(can_play&&can_play2){
                return(
                    <ReactAudioPlayer
                        preload='metadata'
                        ref={ref}
                        src={props.src}
                        className="audio-player"
                        controls
                        controlsList="nodownload"
                        onEnded={onEnded}
                        onSeeked={onSeeked}
                        onListen={onListen}
                        listenInterval={1000}
                    />
                )
            }else if(!can_play2&&can_play){
                return <div className='disable-player'><MuiThemeProvider theme={themeButton}><PlayArrow color="primary"/></MuiThemeProvider>&nbsp;&nbsp;&nbsp;<p>Play the main audio first</p></div>
            }else if(!can_play&&!can_play2){
                return <div className='disable-player'><MuiThemeProvider theme={themeButton}><PlayArrow color="primary"/></MuiThemeProvider>&nbsp;&nbsp;&nbsp;<p>Has been play</p></div>
            }
        }
    }
    return (
        <div>
            {isSafari?renderPlayer():
                 <ReactAudioPlayer
                    preload='metadata'
                    ref={ref}
                    src={can_play?props.src:''}
                    className="audio-player"
                    controls
                    controlsList="nodownload"
                    onEnded={onEnded}
                    onSeeked={onSeeked}
                    onListen={onListen}
                    listenInterval={1000}
                />
            }
            {/* {can_play&&!section2?
            <ReactAudioPlayer
                preload='metadata'
                ref={ref}
                src={props.src}
                className="audio-player"
                controls
                controlsList="nodownload"
                onEnded={onEnded}
                onSeeked={onSeeked}
                onListen={onListen}
                listenInterval={1000}
            />
            :<p>has been play</p>} 
            {can_play2&&section2?
            <ReactAudioPlayer
                preload='metadata'
                ref={ref}
                src={props.src}
                className="audio-player"
                controls
                controlsList="nodownload"
                onEnded={onEnded}
                onSeeked={onSeeked}
                onListen={onListen}
                listenInterval={1000}
            />
            :<p>play_main first</p>}  */}
            {/* {!can_play&&<p>has been play</p>}
            {!can_play2&&&&<p>play main first</p>} */}
            {/* <img src={DisablePlayer} className='audio-player-empty'/> */}
        </div>

    )
}
// const Index=React.memo(props=>{
   
// })
// export default Index
