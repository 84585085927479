import React, { useState,useEffect } from 'react'
import Navbar from './navbar'
import Sidebar from './sidebar'
import Modal from 'components/Modal'
import Cookie from 'universal-cookie'
import Loading from 'components/Loading'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isMobile} from 'react-device-detect'
import AdminInMobile from 'views/warning/admin_in_mobile'
import {withRouter} from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        secondary: {
            main:'#F44336',
            contrastText: '#FFFFFF',

        },
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
    } 
})
const cookie=new Cookie()

 function LayoutOas(props) {
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
    const [expand, setexpand] = useState(false)
    const [admin_collapse, setadmin_collapse] = useState(false)
    useEffect(()=>{
        let url=props.location.pathname
        let admin_collapse=url.includes('/admin')
        setadmin_collapse(admin_collapse)
    },[])
    const expandToggle=()=>{
        setexpand(!expand)
        setadmin_collapse(false)
    }
    if(isMobile){
        return <AdminInMobile/>
    }else{
        return (
            <div>
                <Navbar expandToggle={expandToggle} expand={expand}  profile={profile}/>
                    <Sidebar admin_collapse={admin_collapse} setadmin_collapse={setadmin_collapse} expand={expand} token={token} profile={profile}/>
                    <Loading/>
                    <ToastContainer 
                        autoClose={2000}
                        hideProgressBar={true}
                        toastClassName='toast-wrapper'
                        bodyClassName='toast-text'
                        closeButton={false}
                    />
                    <div className={expand?'content-wrapper-expand':'content-wrapper'}>
                        <Modal token={token} profile={profile}/>
                        <MuiThemeProvider theme={themeButton}>
                            {props.children}
                        </MuiThemeProvider>
                        
                    </div>
            </div>
        )
    }
    
}

export default withRouter(LayoutOas)