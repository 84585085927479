import React ,{useState,useEffect,useRef}from 'react'
import {useSelector,useDispatch} from 'react-redux'
import Welcome from './welcome'
import Assessment from './assestment'
import Modal from 'components/Modal'
import Loader from 'components/Loading'
import Camera from 'components/Camera'
import {setPapiTime,setPapiActiveSub} from 'redux/actions/assestment'
import {modalToggle} from 'redux/actions/general'
import Countdown from 'react-countdown';

export default function Index(props) {
    const dispatch=useDispatch()
    const ref = useRef(null)

    // const [papi_active_sub, setPapiActiveSub] = useState(0)
    const assestment = useSelector(state => state.assestment)
    const general = useSelector(state => state.general)
    const {papi_start_time,papi_active_sub}=assestment

    useEffect(() => {
        


    }, [papi_active_sub])
    const subToggle=(key)=>{
        if(key==='next'){
            dispatch(setPapiActiveSub(papi_active_sub+1))
        }else{
            dispatch(setPapiActiveSub(papi_active_sub-1))
        }
    }
    const clickAgree=()=>{
        
        if(papi_start_time==null){
            dispatch(setPapiTime(Date.now()))
        }
        subToggle('next')
    }
    // console.log(`papi_active_sub`, papi_active_sub)
    const renderLayer=()=>{
        switch (assestment.papi[papi_active_sub].type) {
            case 'welcome':
                return <Welcome profile={props.profile} token={props.token} subToggle={subToggle}  papi={assestment.papi[papi_active_sub]} clickAgree={clickAgree}/>
            case 'assestment':
                return <Assessment profile={props.profile} token={props.token} subToggle={subToggle}  papi={assestment.papi[papi_active_sub]} clickAgree={clickAgree}/>
        
            default:
                break;
        }
    }
    return (
        <div>
            <Loader/>
            {general.on_cam&&<Camera profile={props.profile}/>}
            <Modal token={props.token} profile={props.profile}/>
            
            {renderLayer()}
            
        </div>
    )
}
