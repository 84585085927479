import React,{useState,useEffect} from 'react'
import List from './list'
import Detail from './detail'
import './style.css'
import LayoutOas from 'components/LayoutOas'
import { useDispatch } from 'react-redux'
import { getAssestmentType } from 'redux/actions/oas'
export default function Index(props) {
    const [tab, settab] = useState('list')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAssestmentType())
    }, [])
    return (
        <LayoutOas>
            {tab==='list'&&<List profile={props.profile} settab={settab} tab={tab}/>}
            {tab==='add'&&<Detail profile={props.profile} settab={settab} tab={tab}/>}
            {tab==='detail'&&<Detail profile={props.profile} settab={settab} tab={tab}/>}
            {tab==='edit'&&<Detail profile={props.profile} settab={settab} tab={tab}/>}
        </LayoutOas>
    )
}
