import React,{useState,useEffect} from 'react'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import {Button,TextField} from '@material-ui/core'
import {useSelector,useDispatch} from 'react-redux'
import {setDetailClient,postAdmin,putAdmin,getDetailClient} from 'redux/actions/client'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:15
  },
}));
export default function Add_admin(props) {
    const dispatch = useDispatch()
    const [admins, setadmin] = useState({
        userId:props.profile.id,
        name:'',
        email:'',
        password:''
    })
    const client = useSelector(state => state.client)
    let {modal_data}=props

    useEffect(() => {
        console.log('modal_data', modal_data)
        if(modal_data!==null){
            setadmin({
                userId:props.profile.id,
                name:modal_data.name,
                email:modal_data.email,
                password:modal_data.password
            })
        }
    }, [])
    const classes=useStyles()
    console.log('admins', admins)
    const onClickSimpan=async ()=>{
        let {admin,clientId}=client.client_detail
        let data={
            userId:admins.userId,
            clientId:clientId,
            name:admins.name,
            password:admins.password,
            email:admins.email
        }
        // console.log('data', data)
        if(props.modal_action==='edit_admin'){
            await dispatch(putAdmin(data))
            dispatch(getDetailClient(`/${clientId}`))
            // admin[modal_data.index].userId=admins.userId
            // admin[modal_data.index].name=admins.name
            // admin[modal_data.index].email=admins.email
            // admin[modal_data.index].password=admins.password
            // dispatch(setDetailClient({admin:admin}))
        }else{
            await dispatch(putAdmin(data))
            dispatch(getDetailClient(`/${clientId}`))

            // let new_admin=[
            //     ...admin,
            //     admins
            // ]
            // dispatch(setDetailClient({admin:new_admin}))
        }
        
        // props.modalToggleReset()
    }
    const handleDisable=()=>{
        if(admins.password.length>0){
            if(admins.password.length>=6){
                return false
            }else{
                return true
            }
        }else{
            if(props.modal_action!=='edit_admin'){
                return true
            }else{
                return false
            }
        }
    }
    return (
        <div>
            <TextField
                label={<>Nama</>}
                variant='outlined'
                value={admins.name}
                size='small'
                className={classes.textField}
                onChange={(e)=>setadmin({...admins,name:e.target.value})}
            />
            <TextField
                label={<>Email</>}
                variant='outlined'
                value={admins.email}
                size='small'
                type="email"
                className={classes.textField}
                onChange={(e)=>setadmin({...admins,email:e.target.value})}
            />
            <TextField
                label={<>Password</>}
                variant='outlined'
                value={admins.password}
                size='small'
                placeholder="min 6 character"
                // type="password"
                className={classes.textField}
                onChange={(e)=>setadmin({...admins,password:e.target.value})}
            />
            <div style={{textAlign:'right'}}>
                <MuiThemeProvider theme={themeButton}>
                    <Button disabled={handleDisable()} size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
                </MuiThemeProvider>
            </div>
        </div>
    )
}
