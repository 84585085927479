import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";

import shape_1 from "assets/image/shape_1.svg";
import shape_2 from "assets/image/shape_2.svg";
import shape_3 from "assets/image/shape_3.svg";
import shape_4 from "assets/image/shape_4.svg";
import { tabToggle } from "redux/actions/master";
import expired_bg from "assets/image/disc_login.png";
import "./style.css";
import { logout } from "redux/actions/auth";
import ACS from "assets/image/ACS.png";
import moment from "moment";
export default function Done(props) {
  const [agree, setAgree] = useState();
  const dispatch = useDispatch();
  // console.log('props.detail_peserta', props.detail_peserta)
  const handleDisable = () => {
    if (moment().isBefore(props.detail_peserta.start_date)) {
      // console.log('object', moment().isBefore('2020-11-11T17:24:00'))

      return true;
    } else {
      // console.log('object hihi', moment().isBefore('2020-11-11T17:24:00'))
      return false;
    }
  };
  const onMulai = () => {
    dispatch(logout());
    // window.location.assign('/welcome')
  };
  return (
    <div>
      <div className="welcome-wrapper">
        <div className="welcome-text hide-mobile">
          <div className="hide-mobile">
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="welcome-mobile hide-mobile">
            <h2>
              Hi, <span>{props.detail_peserta.nama}</span>
            </h2>
            <p>
              Survey akan dimulai pada{" "}
              {moment(props.detail_peserta.start_date).format("DD MMMM YYYY")}{" "}
              jam {moment(props.detail_peserta.start_date).format("HH:mm")}{" "}
              sampai waktu yang ditentukan anda baru bisa menjalani proses DISC
              ini
            </p>
            <Button
              onClick={onMulai}
              className="hide-mobile"
              style={{
                textTransform: "none",
                borderRadius: 20,
                fontWeight: "bold",
              }}
              color="primary"
              variant="contained"
              size="large"
            >
              Keluar
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <Button onClick={clickKeluar}  className='hide-mobile' style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Download Hasil</Button> */}
          </div>
          <br />
          <br />
          <br />
          <div className="div-flex div-align-center hide-mobile">
            <p className="semi-bold">Provided By</p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <img
                src={ACS}
                width="auto"
                height={40}
                onClick={() =>
                  window.location.assign(
                    "https://www.gmlperformance.com/services/assesment"
                  )
                }
              />
              <div
                className="vertical-line"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              ></div>
              <img
                src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
                width="auto"
                height={37}
              />
            </div>
          </div>
        </div>
        <div className="welcome-example hide-mobile">
          <img
            style={{ top: 35, left: -20 }}
            className="shape-img"
            src={shape_1}
          />
          <img
            style={{ top: 5, right: 50 }}
            className="shape-img"
            src={shape_2}
          />
          <img
            style={{ top: 350, left: -40 }}
            className="shape-img"
            src={shape_3}
          />
          <img
            style={{ bottom: 10, left: 300 }}
            className="shape-img"
            src={shape_4}
          />
          <img
            style={{ bottom: 30, right: 20 }}
            className="shape-img"
            src={shape_1}
          />
          <div
            className="card-example"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={expired_bg} style={{ width: "70%" }} />
          </div>
        </div>
        <div className="show-mobile" style={{ height: "100%" }}>
          <div
            className=" welcome-text"
            style={{
              margin: "auto",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div className="welcome-mobile" style={{ textAlign: "center" }}>
              <img src={expired_bg} style={{ width: "80%" }} />
              <br />
              <br />
              <h2>
                Hi, <span>{props.detail_peserta.nama}</span>
              </h2>
              <p>
                Survey akan dimulai pada{" "}
                {moment(props.detail_peserta.start_date).format("DD MMMM YYYY")}{" "}
                jam {moment(props.detail_peserta.start_date).format("HH:mm")}{" "}
                sampai waktu yang ditentukan anda baru bisa menjalani proses
                DISC ini
              </p>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={onMulai}
                  style={{
                    width: 150,
                    textTransform: "none",
                    borderRadius: 20,
                    fontWeight: "bold",
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Keluar
                </Button>
                {/* <Button onClick={clickKeluar}   style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Download Hasil</Button> */}
              </div>
              <div style={{ textAlign: "center" }}>
                <p className="semi-bold" style={{ marginBottom: 10 }}>
                  Provided by:
                </p>
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <img
                    src={ACS}
                    width="auto"
                    height={40}
                    onClick={() =>
                      window.location.assign(
                        "https://www.gmlperformance.com/services/assesment"
                      )
                    }
                  />
                  <div
                    className="vertical-line"
                    style={{ marginLeft: "7px", marginRight: "7px" }}
                  ></div>
                  <img
                    src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
                    width="auto"
                    height={37}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
