import React, { Component } from 'react'
import Checklist from 'assets/icon/checklist.png'
import SuccessUpload from 'assets/image/success_upload.png'
import close from 'assets/icon/close.svg'
import Warning from 'assets/icon/Warning.svg'
import {connect} from 'react-redux'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
import {sendReportKominfo} from 'redux/actions/proyek'
// import {setAction} from 'redux/actions/event'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00ACC1',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
class alert extends Component {
    
    renderFooter=()=>{
        const {modal_component,modal_data,success_msg}=this.props.general
        
        switch (modal_component) {
            case 'proyek':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Tutup</Button>
                )     
            case 'client':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Tutup</Button>
                )     
            case 'peserta':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Lihat detail peserta</Button>
                )     
            case 'delete_peserta':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Tutup</Button>
                )     
            case 'kominfo':
                return(
                    <Button onClick={()=>this.props.sendReportKominfo(modal_data.slug,modal_data.name)}   size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded '>Kirim ulang</Button>
                )     
            
            
            default:
                break;
        }
    }
    render() {
        const {modal_data,modal_title,modal_action}=this.props.general
        return (
            <div className='alert-container'>
                {modal_action==='error_upload'?
                <img src={Warning} style={{width:'40%'}}/>
                :
                <img src={modal_action==='error'||modal_action==='error_kominfo'?close:modal_action==='success_upload'?SuccessUpload:Checklist} style={{width:'40%'}}/>}
                <br/>
                <h4>{modal_title}</h4>
                {/* <p>{modal_data.msg}</p> */}
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                <MuiThemeProvider theme={themeButton}>
                    {this.renderFooter()}
                </MuiThemeProvider>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general
})
const mapDispatchToProps={
    modalToggleReset,
    modalToggle,
    sendReportKominfo
}
export default connect(mapStateToProps,mapDispatchToProps)(alert)