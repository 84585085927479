import React, { useState } from 'react'
import TutorialContainer from 'components/oascomponent/TutorialContainer'
import { RadioGroup,FormControlLabel,Radio } from '@material-ui/core'
export default function Tutorial(props) {
    const [choose, setchoose] = useState(1)
    return (
        <TutorialContainer ln="id" btnText="Selanjutnya" clickAgree={()=>props.tabToggle(props.active_tab+1)} clickBack={()=>props.tabToggle(props.active_tab-1)} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
            <h3>Digital Transformation Readiness Assessment </h3>
            <p className="hide-mobile">Berikan nilai 1 sampai 4 sesuai karakteristik organisasi Anda memenuhi perilaku di bagian kiri (1) atau kanan (4). Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
            <p className="show-mobile">Berikan nilai 1 sampai 4 sesuai karakteristik organisasi Anda memenuhi perilaku di bagian atas (1) atau bawah (4). Bila Anda merasa bahwa organisasi Anda bahkan tidak mencerminkan perilaku di bagian 1, tetap berikan nilai 1.</p>
            <div className="div-flex div-space-between div-align-center hide-mobile">
                <div className="box">
                    <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                </div>
                <RadioGroup onChange={(e)=>setchoose(parseInt(e.target.value))} value={choose} row aria-label="position" name="position" defaultValue="1">
                        <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label="1"
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio color="primary" />}
                            label="2"
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio color="primary" />}
                            label="3"
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                        <FormControlLabel
                            value={4}
                            control={<Radio color="primary" />}
                            label="4"
                            labelPlacement="top"
                            style={{margin:3,height:60,fontWeight:'bold'}}
                        />
                </RadioGroup>
                <div className="box">
                    <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>
                </div>
            </div>
            <div className="show-mobile">
                <div className="box">
                    <div style={{padding:20}}>
                    <p>Kami fokus dalam menjual dan berinteraksi dengan pelanggan atau stakeholder utama kami dengan memakai saluran yang biasa kami pakai selama ini</p>

                    </div>
                </div>
                <br/>
                <div className={`spex2-option-item ${choose===1&&'selected'}`} onClick={()=>setchoose(1)}>
                    <div style={{padding:10,textAlign:'center'}}>
                        <p>1</p>
                    </div>
                </div>
                <div className={`spex2-option-item ${choose===2&&'selected'}`} onClick={()=>setchoose(2)}>
                    <div style={{padding:10,textAlign:'center'}}>
                        <p>2</p>
                    </div>
                </div>
                <div className={`spex2-option-item ${choose===3&&'selected'}`} onClick={()=>setchoose(3)}>
                    <div style={{padding:10,textAlign:'center'}}>
                        <p>3</p>
                    </div>
                </div>
                <div className={`spex2-option-item ${choose===4&&'selected'}`} onClick={()=>setchoose(4)}>
                    <div style={{padding:10,textAlign:'center'}}>
                        <p>4</p>
                    </div>
                </div>
                <br/>
                <div className="box">
                    <div style={{padding:20}}>
                        <p>Kami fokus pada perubahan perilaku digital pelanggan atau stakeholder utama kami dan menelusuri bagaimana perjalanan customer experience membeli atau menggunakan solusi/produk kami</p>
                    </div>
                </div>
            </div>
            <br/>
        </TutorialContainer>
    )
}
