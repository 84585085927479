import { combineReducers } from "redux";
import  general from './general'
import master from './master'
import assestment from './assestment'
import proyek from './proyek'
import peserta from './peserta'
import client from './client'
import oas from './oas'
export default combineReducers({
    general,
    master,
    assestment,
    proyek,
    peserta,
    client,
    oas
})