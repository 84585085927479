import * as actionType from "../constants/proyek";
import {apiCall} from 'service/apiCall'
import {apiCall2} from 'service/apiCall2'
import {setLoading,setError,modalToggle, setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import { URL, URL_PARTNER,USER,PASS } from 'service/base_url'
import moment from 'moment'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import {loginToPartner, refreshToken,partnerRefreshToken} from './auth'
import axios from 'axios'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const token_partner=cookie.get('login_partner_cookie')
const profile=cookie.get('profile_cookie')

export const setProyek=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_PROYEK,
        payload:payload
    })
}
export const resetHistoryCredit=(payload)=>dispatch=>{
    dispatch({
        type:actionType.RESET_HISTORY_CREDIT,
    })
}
export const setFilterSaProject=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_FILTER_SA_PROJECT,
        payload:payload
    })
}
export const setFilterHistoryCredit=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_FILTER_HISTORY_CREDIT,
        payload:payload
    })
}
export const setUseFilter=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_USE_FILTER,
        payload:payload
    })
}
export const setEmailTemplate=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_EMAIL_TEMPLATE,
        payload:payload
    })
}
export const setEmailBulk=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_EMAIL_BULK,
        payload:payload
    })
}
export const setEmailBulkIndividual=(payload)=>dispatch=>{
    dispatch({
        type:actionType.SET_EMAIL_BULK_INDIVIDUAL,
        payload:payload
    })
}
export const setCount=(payload)=>dispatch=>{
    // console.log('payload', payload)
    dispatch({
        type:actionType.SET_COUNT,
        payload:payload
    })
}
export const resetProyek=(payload)=>dispatch=>{
    console.log('trigger')
    dispatch({
        type:actionType.RESET_PROYEK,
    })
}

export const postProyek=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/project',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(setProyek({projectId:res.data.id}))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Proyek berhasil ditambahkan",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Proyek ${data.name} berhasil ditambahkan</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putProyek=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/project',
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Proyek berhasil diedit",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Proyek ${data.name} berhasil diedit</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const uploadPeserta=(data,projectId)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/disc/admin/upload',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Upload berhasil",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Sejumlah peserta telah ditambahkan ke proyek</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            dispatch(getListUserProject(`/${projectId}/2/0/0/1/100/*`))
            dispatch(getDetailProjectProgress(projectId))

            return res
        }else{
            dispatch(setLoading(false))
        }
}


export const getDashboard=()=>async dispatch=>{
    dispatch(setLoadingTable(true))
        let dataReq={
            url:`/disc/admin/dashboard/${profile.id}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoadingTable(false))
            dispatch({
                type:actionType.SET_BERANDA,
                payload:res.data
            })
            return res
        }else{
            dispatch(setLoadingTable(false))
        }
}
export const getProject=(slug=`/${moment().format('MM')}/${moment().format('YYYY')}/1/10/*`)=>async dispatch=>{
    dispatch(setLoadingTable(true))
        let dataReq={
            url:`/disc/admin/listproject/${profile.id}${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoadingTable(false))
            dispatch({
                type:actionType.GET_PROYEK,
                payload:{
                    proyek:res.data.items,
                    proyek_pagination:res.data.info
                }
            })
            return res
        }else{
            dispatch(setLoadingTable(false))
        }
}
export const getDetailProjectProgress=(id)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/detaildashboard/${id}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(setProyek({progress:{
                selesai:res.data.completed,
                proses:res.data.process,
                menunggu:res.data.waiting
            }}))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getDetailProject=(id)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/projectdetail/${id}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(setProyek({projectId:res.data.project.id}))
            dispatch(setProyek({nama:res.data.project.name}))
            dispatch(setProyek({deskripsi:res.data.project.description}))
            dispatch(setProyek({tgl_pengerjaan:res.data.project.startDate}))
            dispatch(setProyek({periode:res.data.project.endDate}))
            dispatch(setProyek({status:res.data.project.status}))
            dispatch(setProyek({jenis_test:res.data.tests}))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getListUserProject=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/listuser2/${profile.id}${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(setProyek({peserta:{
                peserta_list:res.data.items,
                peserta_pagination:{
                    page:res.data.info.page,
                    perPage:res.data.info.perPage,
                    total:res.data.info.total,
                }
            }}))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getListTest=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/assess/list`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch({
                type:actionType.GET_LIST_TEST,
                payload:res.data
            })
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getReport=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/reporturl`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            window.open(res.data,'_blank')
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getReportExcel=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/export`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            const wb = new ExcelJS.Workbook();

        const sheet1 = wb.addWorksheet('Rekap Peserta', {
            views: [{showGridLines: false}]
        });
        let borderStyles = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
        };
        const alignSetting=(cell,position)=>{
            sheet1.getCell(cell).alignment={ vertical: 'middle', horizontal: position}

        }
        const fontSetting=(cell)=>{
            sheet1.getCell(cell).font={
                name: 'Calibri',
                family: 4,
                size: 12,
                bold:true
            }
        }
        let single_addres=['A','B','C','D','E','F','G','H','I']
        let head=[
            'Informasi Project','Tanggal Terdaftar','Nama Peserta','Jenis Kelamin','Tanggal Lahir','Kota Asal','No.KTP','Telepon','Email'
        ]
        single_addres.map((d,i)=>{
            sheet1.mergeCells(`${d}1`,`${d}3`)
            sheet1.getCell(`${d}1`).value=head[i]
            fontSetting(`${d}1`)
            alignSetting(`${d}1`,'center')
        })
        
        
        //disc
        let disc_parent=['J1','J2','N2','R2']
        let disc_addres=['J','K','L','M','N','O','P','Q','R','S','T','U']
        let disc_abj=['D','I','S','C','D','I','S','C','D','I','S','C',]
        sheet1.mergeCells('J1','U1')
        sheet1.getCell('J1').value="DISC"
        sheet1.mergeCells('J2','M2')
        sheet1.getCell('J2').value="Grafik1"
        sheet1.mergeCells('N2','Q2')
        sheet1.getCell('N2').value="Grafik2"
        sheet1.mergeCells('R2','U2')
        sheet1.getCell('R2').value="Grafik3"
        
        disc_parent.map((d)=>{
            fontSetting(d)
            alignSetting(d,'center')
        })
        disc_addres.map((d,i)=>{
            sheet1.getCell(`${d}3`).value=disc_abj[i]
            fontSetting(`${d}3`)
            alignSetting(`${d}3`,'center')
        })

        //klasifikasi disc
        sheet1.mergeCells('V1','V3')
        sheet1.getCell('V1').value="Klasifikasi DISC"
        fontSetting('V1')
        alignSetting('V1','center')

        //IQ
        sheet1.mergeCells('W1','W3')
        sheet1.getCell('W1').value="IQ"
        fontSetting('W1')
        alignSetting('W1','center')

        sheet1.mergeCells('X1','AA2')
        sheet1.getCell('X1').value="IQ"
        sheet1.getCell('X3').value="Subtest 1"
        sheet1.getCell('Y3').value="Subtest 2"
        sheet1.getCell('Z3').value="Subtest 3"
        sheet1.getCell('AA3').value="Subtest 4"
        fontSetting('X1')
        alignSetting('X1','center')
        fontSetting('X3')
        alignSetting('X3','center')
        fontSetting('Y3')
        alignSetting('Y3','center')
        fontSetting('Z3')
        alignSetting('Z3','center')
        fontSetting('AA3')
        alignSetting('AA3','center')


        //papi
        sheet1.mergeCells('AB1','AU2')
        sheet1.getCell('AB1').value="PAPIKostick"
        fontSetting('AB1')
        alignSetting('AB1','center')

        let papi_address=['AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP','AQ','AR','AS','AT','AU']
        let papi_title=['N','G','A','L','P','I','T','V','X','S','B','O','R','D','C','Z','E','K','F','W']
        papi_address.map((d,i)=>{
            sheet1.getCell(`${d}3`).value=papi_title[i]
            fontSetting(`${d}3`)
            alignSetting(`${d}3`,'center')
        })


        //toefl
        sheet1.mergeCells('AV1','AV3')
        sheet1.getCell('AV1').value="TOEFL"
        fontSetting('AV1')
        alignSetting('AV1','center')

        sheet1.mergeCells('AW1','AW3')
        sheet1.getCell('AW1').value="Klasifikasi TOEFL"
        fontSetting('AW1')
        alignSetting('AW1','center')

        sheet1.mergeCells('AX1','AX3')
        sheet1.getCell('AX1').value="Presentase Kesesuaian"
        fontSetting('AX1')
        alignSetting('AX1','center')

        let colum=[ 
            {key:'project',width:30},
            {key:'tanggalTerdaftar',width:20},
            {key:'nama',width:30},
            {key:'gender',width:20},
            {key:'tanggalLahir',width:20},
            {key:'kotaAsal',width:30},
            {key:'ktp',width:30},
            {key:'telepon',width:20},
            {key:'email',width:30},
            {key:'mostD'},
            {key:'mostI'},
            {key:'mostS'},
            {key:'mostC'},
            {key:'leastD'},
            {key:'leastI'},
            {key:'leastS'},
            {key:'leastC'},
            {key:'changeD'},
            {key:'changeI'},
            {key:'changeS'},
            {key:'changeC'},
            {key:'profile',width:30},
            {key:'iq'},
            {key:'subtest1',width:15},
            {key:'subtest2',width:15},
            {key:'subtest3',width:15},
            {key:'subtest4',width:15},
            {key:'papiN'},
            {key:'papiG'},
            {key:'papiA'},
            {key:'papiL'},
            {key:'papiP'},
            {key:'papiI'},
            {key:'papiT'},
            {key:'papiV'},
            {key:'papiX'},
            {key:'papiS'},
            {key:'papiB'},
            {key:'papiO'},
            {key:'papiR'},
            {key:'papiD'},
            {key:'papiC'},
            {key:'papiZ'},
            {key:'papiE'},
            {key:'papiK'},
            {key:'papiF'},
            {key:'papiW'},
            {key:'toefl'},
            {key:'toeflCategory',width:20},
            {key:'kesesuaian',width:25},
        ]
        sheet1.columns=colum
        res.data.map((data,i)=>{
            let row=[
                data.project,
                moment(data.tanggalTerdaftar).format('DD-MMMM-YYYY'),
                data.nama,
                data.gender,
                moment(data.tanggalLahir).format('DD-MMMM-YYYY'),
                data.kotaAsal,
                data.ktp,
                data.telepon,
                data.email,
                data.mostD,
                data.mostI,
                data.mostS,
                data.mostC,
                data.leastD,
                data.leastI,
                data.leastS,
                data.leastC,
                data.changeD,
                data.changeI,
                data.changeS,
                data.changeC,
                data.profile,
                data.iq,
                data.subtest1,
                data.subtest2,
                data.subtest3,
                data.subtest4,
                data.papiN,
                data.papiG,
                data.papiA,
                data.papiL,
                data.papiP,
                data.papiI,
                data.papiT,
                data.papiV,
                data.papiX,
                data.papiS,
                data.papiB,
                data.papiO,
                data.papiR,
                data.papiD,
                data.papiC,
                data.papiZ,
                data.papiE,
                data.papiK,
                data.papiF,
                data.papiW,
                data.toefl,
                data.toeflCategory,
                data.kesesuaian,
            ]
            sheet1.addRow(row)
        })
        sheet1.eachRow(function(row, rowNumber) {
            row.eachCell({ includeEmpty: true }, function(cell, cellNumber) {
                cell.border = borderStyles;
            });
        })
        const buf = await wb.xlsx.writeBuffer()
        
        

        saveAs(new Blob([buf]), `Peserta.xlsx`)
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const deletePesertaProyek=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/disc/admin/assign2${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Peserta berhasil dihapus",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getDetailForPartner=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let dataReq={
        url:`/disc/admin/integrasi${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        return res
    }else{
        dispatch(setLoading(false))

    }
}
export const sendReportKominfo=(slug,name,tokens)=>async dispatch=>{
        let getDetail=await dispatch(getDetailForPartner(slug))
        if(getDetail){
            dispatch(setLoading(true))

            let datasent={
                url:`/profiling`,
                method:'POST',
                data:{data:getDetail.data,headers:{ 'Authorization': `Bearer ${tokens}`},}
            }
            let sentToKominfo= await dispatch(apiCall2(datasent))
            if(get(sentToKominfo,'status')===201){
                dispatch(setLoading(false))
                // dispatch(modalToggle({ 
                //     modal_open: true,
                //     modal_title: "Data peserta berhasil dikirim",
                //     modal_component: "proyek",
                //     modal_size:400,
                //     modal_type:'alert',
                //     modal_data:{
                //         msg:`<p>Data <b>${name}</b> berhasil dikirim ke system Kominfo</p> `,
                //         ...sentToKominfo.data
                //     },
                //     modal_action:'success',
                // }))
                return sentToKominfo
            }else{
                console.log('sentToKominfo', sentToKominfo.data)
                dispatch(modalToggle({ 
                    modal_open: true,
                    modal_title: "",
                    modal_component: "",
                    modal_size:400,
                    modal_type:'alert',
                    modal_data:{
                        msg:'<p>Please check your connection again and try to refresh</p>'
                    },
                    modal_action:'error'
                }))
                dispatch(setLoading(false))

            }
        }
}
export const getDataUserBatch=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/disc/admin/integrasi${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const updateDataSent=(slug)=>async dispatch=>{
    // dispatch(setLoading(true))

    let datasent={
        url:`/disc/admin/sent${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===204){
        // dispatch(setLoading(false))
        return res
        
    }else{
        // dispatch(setLoading(false))

    }
}
export const sendReportKominfoAll=(data,tokens)=>async dispatch=>{
    
    // dispatch(setLoading(true))

    let datasent={
        url:`/profiling`,
        method:'POST',
        data:{data:{...data},headers:{ 'Authorization': `Bearer ${tokens}`},}
    }
    let sentToKominfo= await dispatch(apiCall2(datasent))
    if(get(sentToKominfo,'status')===201){
        // dispatch(setLoading(false))
        return sentToKominfo
        // dispatch(modalToggle({ 
        //     modal_open: true,
        //     modal_title: "Data peserta berhasil dikirim",
        //     modal_component: "proyek",
        //     modal_size:400,
        //     modal_type:'alert',
        //     modal_data:{
        //         msg:`<p>Data <b>${name}</b> berhasil dikirim ke system Kominfo</p> `,
        //         ...sentToKominfo.data
        //     },
        //     modal_action:'success',
        // }))
    }else{
        console.log('eyoo', sentToKominfo.response.status)
        return sentToKominfo

        // dispatch(setLoading(false))

    }
        
}

export const getEmailTemplate=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/assess/email${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch(setEmailTemplate({subject:res.data.subject}))
        dispatch(setEmailTemplate({email:res.data.email}))
        dispatch(setProyek({projectId:res.data.projectId}))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const postEmailTemplate=(data)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/assess/email`,
        method:'POST',
        data:{headers:{ 'Authorization': `Bearer ${token}`},data:data}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        // dispatch(modalToggle({ 
        //     modal_open: true,
        //     modal_title: "Email Template",
        //     modal_component: "proyek",
        //     modal_size:400,
        //     modal_type:'alert',
        //     modal_data:{
        //         msg:`<p>Email template berhasil disimpan</p> `,
        //         ...res.data
        //     },
        //     modal_action:'success',
        // }))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getEmailBulk=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/assess/bulk/email${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch(setEmailBulk({subject:res.data.subject}))
        dispatch(setEmailBulk({email:res.data.email}))
        dispatch(setEmailBulk({cc:res.data.cc}))
        dispatch(setEmailBulk({userIds:res.data.cc}))
        dispatch(setProyek({projectId:res.data.projectId}))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getEmailBulkIndividual=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/assess/individual/email${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch(setEmailBulkIndividual({subject:res.data.subject}))
        dispatch(setEmailBulkIndividual({email:res.data.email}))
        dispatch(setEmailBulkIndividual({cc:res.data.cc}))
        dispatch(setProyek({projectId:res.data.projectId}))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const postEmailBulk=(data)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/assess/report/email`,
        method:'POST',
        data:{headers:{ 'Authorization': `Bearer ${token}`},data:data}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Email Template",
            modal_component: "proyek",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Email template berhasil disimpan</p> `,
                ...res.data
            },
            modal_action:'success',
        }))
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getSaDashboard=()=>async dispatch=>{
    dispatch(setLoadingTable(true))
        let dataReq={
            url:`/sa/dashboard`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoadingTable(false))
            dispatch({
                type:actionType.SET_BERANDA_SA,
                payload:res.data
            })
            return res
        }else{
            dispatch(setLoadingTable(false))
        }
}
export const getSaListProject=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/sa/project${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionType.GET_PROYEK,
            payload:{
                proyek:res.data.projects,
                proyek_pagination:res.data.info
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}
export const getListCredit=(clientId)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/assess/credit/${clientId}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionType.GET_LIST_CREDIT,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}
export const getHistoryCredit=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/assess/credit/history${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionType.GET_HISTORY_CREDIT,
            payload:{
                history_credit:res.data.items,
                history_credit_pagination:res.data.info
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}
