import { apiCall } from "service/apiCall";
import { apiCall2 } from "service/apiCall2";
import { setLoading, setError, modalToggle, setLoadingTable } from "./general";
import Cookie from "universal-cookie";
import { get } from "lodash";
import { URL, USER, PASS } from "service/base_url";
import { getDetailUser } from "./peserta";
import { resetQuestion } from "./assestment";
const cookie = new Cookie();
const token = cookie.get("login_cookie");
const token_partner = cookie.get("login_partner_cookie");
const profile = cookie.get("profile_cookie");
export const login = (data) => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: "/auth/login",
    method: "POST",
    data: { data: data, auth: { username: USER, password: PASS } },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoadingTable(false));
    dispatch(resetQuestion());
    cookie.remove("profile_cookie");
    cookie.remove("login_cookie");
    cookie.remove("refresh_cookie");
    cookie.set("login_cookie", res.data.accessToken.token);
    cookie.set("refresh_cookie", res.data.refreshToken);
    let profile = {
      userName: res.data.userName,
      email: res.data.email,
      profile_pic: res.data.profilePic,
      id: res.data.id,
      // roleId:3,
      roleId: res.data.roleId,
      // roleId:res.data.email==='rafdi@gmlperformance.co.id'?3:res.data.email==='rifky@gmlperformance.co.id'?1:res.data.roleId,
      name: res.data.name,
      clientId: res.data.clientId,
      act: "assestment",
    };
    cookie.set("profile_cookie", profile);

    dispatch(setError(null));
    if (res.data.roleId !== 2) {
      window.location.assign("/dashboard");
    } else if (res.data.roleId === 2) {
      window.location.assign("/demografi");
    }
  } else {
    dispatch(setLoadingTable(false));
    // dispatch(setError('The Email or password is incorrect. Please try again '))
  }
};
export const loginOas = (data) => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: "/auth/login",
    method: "POST",
    data: { data: data, auth: { username: USER, password: PASS } },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoadingTable(false));
    dispatch(resetQuestion());
    cookie.set("login_cookie", res.data.accessToken.token);
    cookie.set("refresh_cookie", res.data.refreshToken);
    let profile = {
      userName: res.data.userName,
      email: res.data.email,
      profile_pic: res.data.profilePic,
      id: res.data.id,
      // roleId:3,
      roleId: res.data.roleId,
      // roleId:res.data.email==='rafdi@gmlperformance.co.id'?3:res.data.email==='rifky@gmlperformance.co.id'?1:res.data.roleId,
      name: res.data.name,
      clientId: res.data.clientId,
      act: "oas",
    };
    cookie.set("profile_cookie", profile);

    dispatch(setError(null));
    window.location.assign("/oas/beranda");
    // if(res.data.roleId!==2){
    //     window.location.assign('/dashboard')

    // }else if(res.data.roleId===2){
    //     window.location.assign('/demografi')

    // }
  } else {
    dispatch(setLoadingTable(false));
    // dispatch(setError('The Email or password is incorrect. Please try again '))
  }
};
export const loginToPartner = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: "/auth/login",
    method: "POST",
    data: { data: data },
  };
  let res = await dispatch(apiCall2(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoading(false));
    cookie.set("login_partner_cookie", res.data.access_token);

    return res;
  } else {
    dispatch(setLoading(false));
    // dispatch(setError('The Email or password is incorrect. Please try again '))
  }
};
export const partnerRefreshToken = (token) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: "/auth/refresh",
    method: "POST",
    data: { headers: { Authorization: `Bearer ${token}` } },
    token: token,
  };
  let res = await dispatch(apiCall2(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoading(false));
    // cookie.remove('login_partner_cookie')

    cookie.set("login_partner_cookie", res.data.access_token);

    return res;
  } else {
    dispatch(setLoading(false));
    // dispatch(setError('The Email or password is incorrect. Please try again '))
  }
};
export const loginUuid = (uuid) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/auth/login/${uuid}`,
    method: "GET",
    data: { auth: { username: USER, password: PASS } },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoading(false));
    dispatch(resetQuestion());

    cookie.set("login_cookie", res.data.accessToken.token, { path: "/" });
    cookie.set("refresh_cookie", res.data.refreshToken, { path: "/" });
    let profile = {
      userName: res.data.userName,
      email: res.data.email,
      profile_pic: res.data.profilePic,
      id: res.data.id,
      roleId: res.data.roleId,
      act: "assestment",
    };
    cookie.set("profile_cookie", profile, { path: "/" });

    dispatch(setError(null));
    // window.location.assign('/welcome')
    if (res.data.roleId === 1) {
      window.location.assign("/dashboard");
    } else if (res.data.roleId === 2) {
      window.location.assign("/demografi");
    }
  } else {
    dispatch(setLoading(false));
    // dispatch(setError('The Email or password is incorrect. Please try again '))
  }
};

export const refreshToken = (token, refreshtoken, act) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: "/auth/refreshtoken",
      method: "POST",
      data: {
        auth: { username: USER, password: PASS },
        data: { accessToken: token, refreshToken: refreshtoken },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") === 200) {
      dispatch(setLoading(false));
      cookie.set("login_cookie", res.data.accessToken.token);
      cookie.set("refresh_cookie", res.data.refreshToken);
      window.location.assign(`/${act}`);
    } else {
      dispatch(setLoading(false));
      dispatch(logout());
      // console.log('d',res.data)
    }
  };
};
export const logout = (act) => async (dispatch) => {
  // dispatch(setLoading(true))
  // let dataReq={
  //     url:'/Profile/logout',
  //     method:'GET',
  //     data:{
  //         headers:{ 'Authorization': `Bearer ${token}`}
  //     }
  // }
  // let res=await dispatch(apiCall(dataReq))
  // dispatch(setLoading(false))
  dispatch(resetQuestion());

  cookie.remove("login_cookie");
  cookie.remove("refresh_cookie");
  cookie.remove("profile_cookie");
  window.location.assign(`/${act ? act : ""}`);
};
export const remove_cookie = () => async (dispatch) => {
  cookie.remove("login_cookie");
  cookie.remove("refresh_cookie");
  cookie.remove("profile_cookie");
};
export const changePassword = (data) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: "/Profile/changepassword",
      method: "PUT",
      data: {
        headers: { Authorization: `Bearer ${token}` },
        data: data,
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") === 200) {
      dispatch(setLoading(false));
      if (res.data.code === "old_password") {
        dispatch(setError(res.data.description));
      } else if (res.data.code === "new_password") {
        dispatch(setError(res.data.description));
      } else if (res.data.code === "ok") {
        dispatch(
          modalToggle({
            modal_open: true,
            modal_title: "Change password",
            modal_component: "change_password",
            modal_size: 400,
            modal_type: "alert",
            modal_data: {
              msg: `<p>${res.data.description}</p> `,
              ...res.data,
            },
            modal_action: "success",
            // success_msg:success_msg
          })
        );
        // dispatch(alertToggle({ isOpen: true,button_title:'Close',title: "Change password" ,componentName: "alert_client",size:4,message:res.data.description }))
        // dispatch(setError(res.data.description))
      }
    } else {
      console.log("123");
      dispatch(setLoading(false));
    }
  };
};
