import React, { useState, useEffect } from "react";
import "./style.css";
import {
  TextField,
  Button,
  CircularProgress,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginUuid,
  remove_cookie,
  loginToPartner,
} from "redux/actions/auth";
import Login_disc from "assets/image/disc_login.png";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Loader from "components/Loading";
import {
  resetQuestion,
  resetPapi,
  resetCfit,
  resetToefl,
  resetAssestmentReducer,
} from "redux/actions/assestment";
import ACS from 'assets/image/ACS.png'

const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#afe597",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#65b7ff",
      contrastText: "#FFFFFF",
    },
  },
});

function Index(props) {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const assestment = useSelector((state) => state.assestment);
  useEffect(() => {
    let { uuid } = props.match.params;
    dispatch(resetAssestmentReducer());
    dispatch(remove_cookie());
    dispatch(resetQuestion());
    dispatch(resetPapi());
    dispatch(resetCfit());
    dispatch(resetToefl());
    if (uuid !== undefined) {
      dispatch(loginUuid(uuid));
    }
  }, []);
  const [state, setState] = useState({
    show_pass: false,
    email: "",
    password: "",
  });
  const passwordToggle = () => {
    setState({ ...state, show_pass: !state.show_pass });
  };
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let { email, password } = state;
    let data = {
      userName: email,
      password,
      os: "windows",
      deviceID: 1,
      sourceID: 1,
      versionCode: 1,
      versionName: "string",
    };
    // dispatch(loginToPartner({username:'onegml',password:'onegml'}))
    dispatch(login(data));
  };
  const { show_pass, email, password } = state;
  return (
    <div>
      <Loader />
      <div className="login-wrapper">
        {/* <Modal/> */}
        <MuiThemeProvider theme={themeButton}>
          <div className="login-form">
            <img
              src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
              width={100}
              className="logo-login"
            />
            <img src={Login_disc} className="img-bg-mobile" />
            <h1>Providing Assessment & Talent Management solutions</h1>
            <p>
              Deliver assessment report as valuable information for the
              participants and provide development plan for each participant.
            </p>
            <h3>Login</h3>
            <form onSubmit={onSubmit}>
              <TextField
                label="Email"
                // label={<p>Name<span style={{color:'red'}}>*</span></p>}
                variant="outlined"
                size="small"
                className="form-login"
                name="email"
                onChange={onChange}
                required
                color="secondary"
                style={{ marginBottom: 20 }}
              />
              <br />
              <FormControl
                className="form-login"
                color="secondary"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password <span>*</span>
                </InputLabel>
                <OutlinedInput
                  className="form-login"
                  id="outlined-adornment-password"
                  type={show_pass ? "text" : "password"}
                  // label='Password'
                  name="password"
                  onChange={onChange}
                  required
                  color="secondary"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordToggle}
                        edge="end"
                      >
                        {show_pass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={85}
                />
              </FormControl>
              {general.error_msg !== null &&
                general.error_msg.map((data, i) => (
                  <p className="bold" key={i} style={{ color: "red" }}>
                    {data.description}
                  </p>
                ))}
              <br />
              <Button
                size="large"
                type="submit"
                color="secondary"
                className="btn-remove-capital btn-rounded btn-login"
                variant="contained"
              >
                {general.isLoadingTable ? (
                  <CircularProgress style={{ color: "white" }} size={25} />
                ) : (
                  "Masuk"
                )}
              </Button>
              {/* <p>Masuk sebagai <a href="/loginadmin" style={{textDecoration:'none',color:'#00a1ed',fontWeight:'bold'}}>Admin</a></p> */}
            </form>
            <br />
          </div>
          <div className="img-bg">
            <img src={Login_disc} />
          </div>
        </MuiThemeProvider>
        <br />
        <br />
      </div>
    </div>
  );
}

export default withRouter(Index);
