import React,{useState} from 'react'
import DemografiContainer from 'components/oascomponent/DemografiContainer'
import TextField from 'components/TextField'
import SurveyContainer from 'components/oascomponent/SurveyContainer'
import { useDispatch,useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import {RadioGroup ,Radio,FormControlLabel} from '@material-ui/core'
import { submitSurvey } from 'redux/actions/oas'
export default function Demografi(props) {

    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const [field, setfield] = useState('')
    let {jawaban,detail_survey}=oas
    const onChangeDemografi=(i,value,type)=>{
        setfield(value)
        if(type!=='single'){
            jawaban[props.active_tab][i].answerText=value
        }else{
            jawaban[props.active_tab][i].answerText=''
            jawaban[props.active_tab][i].ratingId=parseInt(value)

        }
    }
    const onSaveSurvey=async ()=>{
        let new_jawaban=[]
        jawaban.map((d,i)=>{
            if(i!==0&&i!==jawaban.length-1){
                new_jawaban.push({id:0,itemId:d.itemId,ratingId:parseInt(d.option),answerText:""})
            }
            if(i===jawaban.length-1){
                let new_demo=[]
                d.map((dm)=>{
                    new_demo.push({...dm,ratingId:parseInt(dm.ratingId)})
                })
                new_jawaban.push(...new_demo)
            }
        })
        let data={
            surveyId:detail_survey.surveyId,
            uuid:detail_survey.groupUuid,
            responses:new_jawaban
        }
        let res=await dispatch(submitSurvey(data))
        if(res){
            props.statusToggle('result')
        }
    }
    const handleDisabled=()=>{
        let status=[]
        jawaban[props.active_tab].map((d)=>{
            if(d.type!=='single'){
                status.push(d.answerText==="")
            }else{
                status.push(d.ratingId===0)
            }
        })
        let disabled= status.includes(true)
        return disabled
    }
    return (
        <SurveyContainer handleDisabled={handleDisabled} removeProgressBar={true} btnText="Selanjutnya" nameSurvey={props.nameSurvey} renderTextQuestionNumber={props.renderTextQuestionNumber} ln="id" btnText="Selanjutnya" clickNext={()=>onSaveSurvey()} clickBack={()=>props.tabToggle(props.active_tab-1)} welcomeMessage="Anda berikut merupakan halaman survey untuk Strategy and Performance Execution Excellence® Audit">
            <h2 style={{margin:0,marginBottom:20,color:'#333333'}}>Data Responden</h2>
                {props.content.items.map((d,i)=>{
                    if(d.itemType==='textfield'||d.itemType==='email'){
                        return(
                            <TextField
                                label={d.text.id}
                                variant='outlined'
                                value={jawaban[props.active_tab][i].answerText}
                                size='small'
                                style={{marginBottom:20}}
                                onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}
                            />
                        )
                    }
                    if(d.itemType==='phone'){
                        return(
                            <InputMask
                                maskChar={null}
                                mask="9999-9999-9999-9999"
                                value={jawaban[props.active_tab][i].answerText}
                                onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}

                            >
                            {() =>
                                <TextField
                                    label={d.text.id}
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    style={{marginBottom:20}}
                                />
                            }
                            </InputMask>
                        )
                    }
                    if(d.itemType==='single'){
                        return(
                            <div key={i} >
                                <p style={{color:'#808080',fontSize:12,fontWeight:'bold'}}>{d.text.id} </p>
                                <RadioGroup value={jawaban[props.active_tab][i].ratingId} onChange={(e)=>onChangeDemografi(i,e.target.value,d.itemType)}   style={{marginBottom:8}} aria-label="gender" name="gender1"  >
                                    <div className='lps-flex'>
                                        {d.options.map((dat,i)=>(
                                            <>
                                            <FormControlLabel key={i} value={dat.id} control={<Radio color='primary'/>} label={<p style={{fontSize:12,color:'#777777',fontWeight:'bold'}}>{dat.text.id}</p>} />
                                            
                                            </>
                                        ))}
                                    
                                    </div>
                                </RadioGroup>
                            </div>
                        )
                    }
                })}
        </SurveyContainer>
    )
}
