import React,{useState} from 'react'
import List from './List'
import './style.css'
import Layout from 'components/Layouts'
import Detail from './Detail'
import { MuiThemeProvider, createMuiTheme,withStyles, makeStyles} from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Index(props) {
    const [tab, settab] = useState('list')
    const tabToggle=(key)=>{settab(key)}
    return (
        <div>
            <Layout>
                <MuiThemeProvider theme={themeButton}>
                    {tab==='list'&&<List {...props} themeButton={themeButton} tab={tab} tabToggle={tabToggle}/>}
                    {tab==='add'&&<Detail {...props} themeButton={themeButton} tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail'&&<Detail {...props} themeButton={themeButton} tab={tab} tabToggle={tabToggle}/>}
                    {tab==='edit'&&<Detail {...props} themeButton={themeButton} tab={tab} tabToggle={tabToggle}/>}
                </MuiThemeProvider>
            </Layout>
        </div>
    )
}
