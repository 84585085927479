import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Modal} from '@material-ui/core'
import './style.css'
import Close from 'assets/icon/close.svg'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
import Alert from './src/alert'
import ConfirmDelete from 'components/Modal/src/confirm_delete'
import Expired from './src/expired'
import AssignmentPeserta from './src/assignment_peserta'
import ResetPass from './src/reset_password'
import Prosess from './src/prosess'
import CfitIntruksi from './src/CfitIntruksi'
import PapiIntruksi from './src/PapiIntruksi'
import ToeflIntruksi from './src/ToeflIntruksi'
import EmailTemplate from './src/email_template'
import EmailTemplate2 from './src/email_template2'
import EmailTemplate3 from './src/email_template3'
import {isMobile} from 'react-device-detect'
import DiscIntruksi from './src/discIntruksi'
import FilterProject from './src/filter_project'
import FilterClient from './src/filter_client'
import AddKredit from './src/add_kredit'
import AddAdmin from './src/add_admin'
import FilterHistory from './src/filter_history'
import Oas_add_kredit from './src/oas_add_kredit'
import Oas_add_group from './src/oas_add_group'
import Tutorial_dtra from './src/tutorial_dtra'
class index extends Component {
    emailTemplate=()=>{
        this.props.modalToggle({
            modal_open: true,
            modal_title: `Template Email`,
            modal_component: "email_template",
            modal_size:1200,
            modal_data:null,
            modal_action:'email_template'
        })
    }
    renderBody=()=>{
        const {modal_data,modal_title,modal_component,modal_type,modal_action}=this.props.general
        const {token,profile}=this.props

        switch (modal_type) {
            case 'confirm':
                return (
                    <>
                        {modal_component==='confirm_delete'&&<ConfirmDelete modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action}/>}
                        {/* {modal_component==='confirm_unpublish'&&<ConfirmUnpublish modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action }success_msg={success_msg}/>} */}
                    </>
                )
                break;
            case 'alert':
                return <Alert token={token}/>
            case 'prosess':
                return <Prosess/>
            default:
                return(
                        <>
                            <div className='modal-header'>
                                    <h2>{modal_title}</h2>
                                    <img src={Close} style={{width:20}} onClick={()=>modal_component==="assignment_peserta"?this.emailTemplate():this.props.modalToggleReset()}/>
                            </div>
                                {/* <hr className='modal-hr' size='1'/> */}
                            <div className='modal-body' >
                                   {modal_component==='expired'&&<Expired modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='assignment_peserta'&&<AssignmentPeserta modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='email_template'&&<EmailTemplate modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='email_template2'&&<EmailTemplate2 modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='email_template3'&&<EmailTemplate3 modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='reset_password'&&<ResetPass modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='intruksi_cfit'&&<CfitIntruksi modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='intruksi_papi'&&<PapiIntruksi modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='intruksi_toefl'&&<ToeflIntruksi modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='intruksi_disc'&&<DiscIntruksi modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_project'&&<FilterProject modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_client'&&<FilterClient modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_kredit'&&<AddKredit modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_admin'&&<AddAdmin modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='filter_history'&&<FilterHistory modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='oas_add_kredit'&&<Oas_add_kredit modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='oas_add_group'&&<Oas_add_group modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='oas_tutorial_dtra'&&<Tutorial_dtra modalToggleReset={this.props.modalToggleReset} modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                            </div>
                        </>
                )
                break;
        }
    }
    render() {
        const {modal_open,modal_data,modal_title,modal_component,modal_size,modal_type,modal_action}=this.props.general
        const {token,profile}=this.props
        return (
            <div>
                {modal_type!=='profile'?
                <Modal
                    className='modal'
                    open={modal_open}
                    onClose={modal_component!=='upload_file'&&modal_type!=='prosess'&&this.props.modalToggleReset}
                >
                   <div className='modal-content' style={{width:isMobile?'100%':modal_size}}>
                        {this.renderBody()}
                   </div>
                </Modal>
                :
                <Modal
                    className='modal-profile'
                    open={modal_open}
                    onClose={this.props.modalToggleReset}
                >
                    
                   <div className='modal-content' style={{width:modal_size}}>
                   </div>
                </Modal>
                }
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        general:state.general
    }
}
const mapDispatchToProp={
    modalToggleReset,modalToggle
}
export default connect(mapStateToProps,mapDispatchToProp)(index)