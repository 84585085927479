import React ,{useState}from 'react'
import {TextField,Button,CircularProgress,IconButton,OutlinedInput,InputAdornment,InputLabel,FormControl} from '@material-ui/core'
import {Visibility,VisibilityOff,} from '@material-ui/icons'
import {useDispatch,useSelector} from 'react-redux'
import {login,changePassword} from 'redux/actions/auth'
import Login_disc from 'assets/image/disc_login.png'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#afe597',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
    } 
})
export default function Reset_password() {
    const dispatch=useDispatch()
    const general = useSelector(state => state.general)
    const [state,setState]=useState({
        show_pass:false,
        old_pass:'',
        new_pass:'',
        verify_pass:''
    })
    const passwordToggle=()=>{
        setState({...state,show_pass:!state.show_pass})
    }
    const onChange=(e)=>{
        setState({...state,[e.target.name]:e.target.value})
    }
    const onClickSave=()=>{
        let data={
            oldPassword:state.old_pass,
            newPassword:state.new_pass
        }
        dispatch(changePassword(data))
    }
    const handleDisable=()=>{
        if(state.old_pass!==''&&state.new_pass!==''&&state.verify_pass!==''){
            if(state.new_pass===state.verify_pass){
                return false
            }else{
                return true
            }
        }else{
            return true
        }
    }
    const {show_pass,email,password}=state

    return (
        <div>
            <FormControl className='form-login2'  variant="outlined" size="small">
                <InputLabel  htmlFor="outlined-adornment-password">Password Lama<span >*</span></InputLabel>
                <OutlinedInput
                    className='form-login2'
                    id="outlined-adornment-password"
                    type={show_pass ? 'text' : 'password'}
                    // label='Password'
                    name='old_pass'
                    onChange={onChange}
                    required
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordToggle}
                        edge="end"
                        >
                        {show_pass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={125}
                />
            </FormControl>
            <FormControl className='form-login2'  variant="outlined" size="small">
                <InputLabel  htmlFor="outlined-adornment-password">Password Baru<span >*</span></InputLabel>
                <OutlinedInput
                    className='form-login2'
                    id="outlined-adornment-password"
                    type={show_pass ? 'text' : 'password'}
                    // label='Password'
                    name='new_pass'
                    onChange={onChange}
                    required
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordToggle}
                        edge="end"
                        >
                        {show_pass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={125}
                />
            </FormControl>
            <FormControl className='form-login2'  variant="outlined" size="small">
                <InputLabel  htmlFor="outlined-adornment-password">Verifikasi Password Baru<span >*</span></InputLabel>
                <OutlinedInput
                    className='form-login2'
                    id="outlined-adornment-password"
                    type={show_pass ? 'text' : 'password'}
                    // label='Password'
                    name='verify_pass'
                    onChange={onChange}
                    required
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={passwordToggle}
                        edge="end"
                        >
                        {show_pass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    labelWidth={185}
                />
            </FormControl>
                {general.error_msg!==null&&<p style={{color:'red',margin:0}}>{general.error_msg}</p>}
            <div style={{textAlign:'right'}}>
                <Button disabled={handleDisable()} onClick={onClickSave} className='btn-remove-capital btn-rounded' variant="contained" size="medium" color="primary">Simpan</Button>
            </div>
        </div>
    )
}
