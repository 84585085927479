import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,FormControlLabel,Checkbox} from '@material-ui/core'

import Correct from 'assets/image/Correct.png'
import Wrong from 'assets/image/Wrong.png'
import shape_1 from 'assets/image/shape_1.svg'
import shape_2 from 'assets/image/shape_2.svg'
import shape_3 from 'assets/image/shape_3.svg'
import shape_4 from 'assets/image/shape_4.svg'
import shape_5 from 'assets/image/shape_5.png'
import shape_6 from 'assets/image/shape_6.png'
import takenote from 'assets/image/oas/takenote.png'
// import '../style.css'

const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
            },
            width:'100%',
            marginBottom:15
    },
    
}));
export default function Welcome(props) {
    const clickNext=()=>{
        props.clickNext()
    }
    const clickBack=()=>{
        props.clickBack()
    }
    return (
        <div>
            <div className='welcome-wrapper'>
                <div className='welcome-text'>
                    <div className='welcome-mobile' >
                        <h2 className="show-mobile">{props.nameSurvey}</h2>
                        <img className="hide-mobile" src={takenote} style={{width:400}}/>
                    </div>
                </div>
                <div className='welcome-example' style={{position:'relative'}}>
                    <img style={{top:-35,left:-40,width:150,position:'absolute'}} className="shape-img-oas"  src={shape_1}/>
                    <img style={{top:-85,right:50,width:250,position:'absolute'}} className="shape-img-oas"  src={shape_5}/>
                    <img style={{bottom:-30,left:-150,width:250,position:'absolute'}} className="shape-img-oas"  src={shape_6}/>
                    <img style={{bottom:-120,left:200,width:200,position:'absolute'}} className="shape-img-oas" src={shape_6}/>
                    <img style={{bottom:-100,right:20,width:150,position:'absolute'}} className="shape-img-oas" src={shape_1}/>
                    <div className='card-example'>
                        <div className='welcome-mobile'>
                            {!props.removeProgressBar&&<div style={{gap:10}} className='hide-mobile div-flex div-align-center div-justify-between'>
                                <p style={{color:'#252525',fontSize:18,margin:0}}>Pertanyaan</p>
                                <div style={{width:'100%',display:'flex',alignItems:'center'}}>
                                <progress className='bar-dtra' max={props.totalQuestion} value={props.current_question}></progress>
                                </div>
                                <p style={{color:'#808080',fontSize:14,margin:0}}>{props.current_question}/{props.totalQuestion}</p>
                                {props.withHelp&&<div className='help' onClick={()=>props.showHelp()}>?</div>}
                            </div>}
                            {!props.removeProgressBar&&
                            <div className="show-mobile " >
                                <div className="div-flex div-align-center div-space-between" >
                                    <p style={{color:'#252525',fontSize:18,fontWeight:'bold',margin:0}}>Pertanyaan</p>
                                    <div className="div-flex div-align-center" style={{gap:20}}>
                                        <p style={{color:'#808080',fontSize:16,margin:0,fontWeight:'bold',margin:0}}>{props.current_question}/{props.totalQuestion}</p>
                                        {props.withHelp&&<div className='help' onClick={()=>props.showHelp()}>?</div>}

                                    </div>
                                </div>
                                <br/>
                                <div style={{width:'100%',display:'flex',alignItems:'center'}}>
                                <progress className='bar-dtra' max={props.totalQuestion} value={props.current_question}></progress>
                                </div>
                            </div>}
                            {props.children}
                            <div className="div-flex div-align-center div-space-between">
                            <Button onClick={clickBack}   style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kembali</Button>
                            <Button onClick={clickNext} disabled={props.handleDisabled()}  style={{textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>{props.btnText}</Button>

                            </div>
                            <br/>
                        </div>
                    </div>
                </div>

                <br className='show-mobile'/>

            </div>
        </div>
    )
}
