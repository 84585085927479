import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect
  } from "react-router-dom";
  import React,{useEffect} from 'react'
import {isMobile} from 'react-device-detect'

import Login from 'views/login'
import LoginAdmin from 'views/login/admin'


import Cookie from 'universal-cookie'
import { useSelector, useDispatch } from "react-redux";
import {logout} from 'redux/actions/auth'
import Assestment from 'views/assestment'
import Done from 'views/assestment/done'
import Demografi from 'views/assestment/demografi'
import Admin from 'views/beranda'
import Proyek from 'views/proyek'
import Peserta from 'views/peserta'
import Expired from 'views/assestment/expired'
import Client from 'views/client'
import AdminInMobile from 'views/warning/admin_in_mobile'
import moment from 'moment'
import NotReady from 'views/assestment/not_ready'

import Portal from 'views/assestment/portal'

import Disc from 'views/assestment/disc'
import DiscAssestment from 'views/assestment/assestment'
import DoneDisc from 'views/assestment/disc/done'

import Cfit from 'views/assestment/cfit'
import DoneCfit from 'views/assestment/cfit/done'

import Papi from 'views/assestment/papi'
import DonePapi from 'views/assestment/papi/done'

import Toefl from 'views/assestment/toefl'
import DoneToefl from 'views/assestment/toefl/done'

import OasLogin from 'views/oas/login'
import OasAdmin from 'views/oas/admin'
import OasSpex2 from 'views/oas/assestment/spex2'
import OasDtra from 'views/oas/assestment/dtra'
import OasSurvey from 'views/oas/assestment'

import Spex2ReportIndividu from 'views/oas/assestment/spex2/report_individu'

import DtraReport from 'views/oas/assestment/dtra/report'
import Check from "views/check";
const cookie=new Cookie
const Logout=()=>{
    const dispatch=useDispatch()
    const profile=cookie.get('profile_cookie')

    useEffect(()=>{
        let oas=window.location.pathname.includes('/oas')

        dispatch(logout(oas?'?act=oas':''))
        // console.log(`profile`, oas)
    },[])
    return(
        // <Redirect to='/login'/>
        <div></div>
    )
}
const indexRouter=(props)=>{
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
    // console.log('profile', profile)
    const isHaveToken=()=>{
        if(token!==undefined &&profile!==undefined){
            if(profile.roleId===1){
                return(
                    <>
                        <Route path='/dashboard' exact render={()=>(<Admin token={token} profile={profile}/>)} />
                        <Route path='/proyek' exact render={()=>(<Proyek token={token} profile={profile}/>)} />
                        <Route path='/peserta' exact render={()=>(<Peserta token={token} profile={profile}/>)} />
                        <Route path='/peserta/:id' exact render={()=>(<Peserta token={token} profile={profile}/>)} />

                        
                    </>
                )
                
            }else if(profile.roleId===3){
                return(
                    <>
                        <Route path='/dashboard' exact render={()=>(<Admin token={token} profile={profile}/>)} />
                        <Route path='/client' exact render={()=>(<Client token={token} profile={profile}/>)} />
                        
                    </>
                )
            }   
            else if(profile.roleId===2){
                if(profile.expired){
                    return(
                        <>
                        <Route path='/expired/:title' exact render={()=>(<Expired token={token} profile={profile}/>)} />
                        <Route path='/done/:title' exact render={()=>(<Done token={token} profile={profile}/>)} />
                        </>
                    )
                }else{
                    if(profile.completed){
                        return <Route path='/done/:title' exact render={()=>(<Done token={token} profile={profile}/>)} />
                    }else{
                        return(
                            <>
                                {/* <Route path='/assestment/:slug' exact render={()=>(<Assestment token={token} profile={profile}/>)} /> */}
                                <Route path='/done/:title' exact render={()=>(<Done token={token} profile={profile}/>)} />
                                <Route path='/demografi' exact render={()=>(<Demografi token={token} profile={profile}/>)} />
                                <Route path='/portal' exact render={()=>(<Portal token={token} profile={profile}/>)} />

                                <Route path='/disc/welcome' exact render={()=>(<Disc token={token} profile={profile}/>)} />
                                <Route path='/disc/assestment' exact render={()=>(<DiscAssestment token={token} profile={profile}/>)} />
                                <Route path='/disc/done' exact render={()=>(<DoneDisc token={token} profile={profile}/>)} />

                                <Route path='/cfit/assestment' exact render={()=>(<Cfit token={token} profile={profile}/>)} />
                                <Route path='/cfit/done' exact render={()=>(<DoneCfit token={token} profile={profile}/>)} />

                                <Route path='/papi/assestment' exact render={()=>(<Papi token={token} profile={profile}/>)} />
                                <Route path='/papi/done' exact render={()=>(<DonePapi token={token} profile={profile}/>)} />

                                <Route path='/toefl/assestment' exact render={()=>(<Toefl token={token} profile={profile}/>)} />
                                <Route path='/toefl/done' exact render={()=>(<DoneToefl token={token} profile={profile}/>)} />

                            </>
                        )
                    }
                   
                }
               
            }else if(profile.roleId===6){
                return(
                    <>
                        <Route path='/oas/beranda' exact component={()=><OasAdmin token={token} profile={profile}/>} />
                        <Route path='/oas/spex2' exact component={()=><OasSpex2 token={token} profile={profile}/>}/>
                        <Route path='/oas/dtra' exact component={()=><OasDtra token={token} profile={profile}/>}/>
                    </>
                )
            }else{
                
                return <Logout/>
            }
            
        }else{
            return <Logout/>
        }
    }
    return( 
        <Router>
        <Switch>
            <Route path='/login' exact component={Login}/>
            <Route path='/login/:uuid' exact component={Login}/>
            <Route path='/' exact component={Check}/>
            <Route path='/oas/login' exact component={OasLogin}/>

            <Route path='/loginadmin' exact component={LoginAdmin}/>
            <Route path='/warning' exact component={AdminInMobile}/>
            <Route path='/not_ready' exact component={NotReady}/>
            <Route path='/oas/survey/:uuid' exact component={()=><OasSurvey token={token} profile={profile}/>}/>
            <Route path='/oas/spex2/report/:uuid' exact component={()=><Spex2ReportIndividu token={token} profile={profile}/>}/>
            <Route path='/oas/dtra/report/:uuid' exact component={()=><DtraReport token={token} profile={profile}/>}/>

            {/* <Route path='/previewdetail' exact component={DetailPreview}/> */}
            {isHaveToken()}

            
            
        </Switch>
        </Router>
    )
}
  export default indexRouter
  