import React,{useState,useEffect} from 'react'
import {Button,Checkbox,FormGroup ,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@material-ui/core'
import Countdown from 'react-countdown';
import {useSelector,useDispatch} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import {postToefl, setToefl} from 'redux/actions/assestment'
import {isMobile} from 'react-device-detect'
// import './style.css'
import Loader from 'components/Loading'
import Check from 'assets/icon/Check.svg'
import AudioPlayer from 'components/AudioPlayer'
import ReactAudioPlayer from 'react-audio-player'

const upperCaseAlp = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

// const Navbar=React.memo(props=>{
//     const dispatch=useDispatch()
//     const [second,setSecond]=useState(props.duration)
//     const onClickHelp=()=>{
//         dispatch(modalToggle({ 
//             modal_open: true,
//             modal_title: "",
//             modal_component: "intruksi_toefl",
//             modal_size:700,
//             modal_type:'',
//             modal_data:{
//                 toefl:props.toefl
//             },
//             modal_action:'success',
//         }))
//     }
//     const renderer = ({ hours, minutes, seconds, completed }) => {

//         if (completed) {
//           return <span className='timer-expired'>00:00:00</span>;
            
//         } else {
//             return <span className='timer'>00:{minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}</span>;
//         }
//       };
//       let username=props.profile.userName.split(" ")[0]
//     return(
//         <div className='navbar-wrapper'>
//             <div className='div-flex div-space-between div-align-center navbar' >
//             <h3>Good luck, <br className='show-mobile'/> <span className='name'>{username}</span></h3>
//             <div className=' assestmen-toolbar div-flex div-align-center div-space-between'>
//                 <div className='div-flex div-align-center'>
//                     <p className='hide-mobile' style={{color:'white',fontWeight:'bold',margin:0}}>Instruction</p>
//                     &nbsp;&nbsp;
//                     <div onClick={onClickHelp} className='circle-help' ><span>?</span></div>

//                 </div>
//                 <div className='hide-mobile' style={{width:1.3,height:30,backgroundColor:'white'}}></div>
//                 <h3>remaining time <br className='show-mobile'/> 
//                     <Countdown
//                         date={Date.now() + second}
//                         renderer={renderer}
//                     />
//                 </h3>
//             </div>
//             </div>
            
//         </div>
//     )
// })
const Listening=(props)=>{
    let {data,i1,question,toefl,}=props

   
    const dispatch=useDispatch()


    const assestment = useSelector(state => state.assestment)

    const chooseAnswer=(i1,i2,answer)=>{
        toefl.question[i1].audio_question[i2].answer=parseInt(answer)
        dispatch(setToefl([...assestment.toefl]))

    }
    return(
        <div className='toefl-question-wrapper' key={i1}>
            {data.audio_question.map((ques,i2)=>(
                <div>
                    <div className="toefl-question-header">
                        <div className='toefl-question-number'>
                            {ques.number}
                        </div>
                        &nbsp;
                        <div style={{width:'85%'}}><AudioPlayer  question={question} toefl={data} i1={i1} i2={i2}  src={data.audio_src} can_play={data.can_play}/></div>
                    </div>
                    <FormControl fullWidth >
                        <RadioGroup  value={ques.answer} onChange={(e)=>chooseAnswer(i1,0,e.target.value,'radio')} row aria-label="position" name="position" defaultValue="top">
                            <div className='' style={{width:'100%'}}>
                                {ques.question_answer.map((answer,i3)=>(
                                    <div key={i3}  className='toefl-answer-item'>
                                        
                                        <FormControlLabel
                                            style={{marginRight:0}} 
                                            value={answer.value}
                                            control={<Radio style={{margin:'10px 15px 10px 15px'}} color="primary" size="large"/>}
                                            label=""
                                            labelPlacement="left"
                                            
                                        />
                                        <div onClick={()=>chooseAnswer(i1,0,answer.value,'radio')} style={{width:'100%',cursor:'pointer'}} className='div-flex div-align-center'><b style={{width:30,color:'#252525'}}>{upperCaseAlp[i3]}</b><div className='option-box'><p>{answer.text}</p></div></div>
                                    </div>
                                ))}
                            </div>
                        </RadioGroup>
                    </FormControl>
                    
                </div>
             ))} 
            
            
        </div>
    )

}
// const Listening=React.memo((props)=>{
//     })
const Reading1part1=React.memo(props=>{
    let {data,i1,toefl}=props
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)

    const chooseAnswer2=(i1,i2,answer)=>{
        toefl.question[i1].answer=parseInt(answer)
        dispatch(setToefl([...assestment.toefl]))

    }
    return(
        <div className='toefl-question-wrapper' key={i1}>
            <div className="toefl-question-header-reading">
                <div className='toefl-question-number'>
                    {data.number}
                </div>
                &nbsp;
                <div className='toefl-question-reading-desc'>
                <p><b>{data.desc}</b></p>
                </div>
            </div>
            <FormControl fullWidth >
                <RadioGroup  value={data.answer} onChange={(e)=>chooseAnswer2(i1,0,e.target.value,'radio')} row aria-label="position" name="position" defaultValue="top">
                    <div className='' style={{width:'100%'}}>
                        {data.question_answer.map((answer,i3)=>(
                            <div key={i3}  className='toefl-answer-item'>
                                
                                <FormControlLabel
                                style={{marginRight:0}} 
                                    value={answer.value}
                                    control={<Radio style={{margin:'10px 15px 10px 15px'}} color="primary" size="large"/>}
                                    label=""
                                    labelPlacement="left"
                                    
                                />
                                <div onClick={()=>chooseAnswer2(i1,0,answer.value)} style={{width:'100%',cursor:'pointer'}} className='div-flex div-align-center'><b style={{width:30,color:'#252525'}}>{upperCaseAlp[i3]}</b><div className='option-box'><p>{answer.text}</p></div></div>
                            </div>
                        ))}
                    </div>
                </RadioGroup>
            </FormControl>
        </div>
    )
})
const Reading1part2=React.memo(props=>{
    let {data,i1,toefl}=props
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)

    const chooseAnswer2=(i1,i2,answer)=>{
        toefl.question[i1].answer=parseInt(answer)
        dispatch(setToefl([...assestment.toefl]))

    }
    return(
        <div className='toefl-question-wrapper' key={i1}>
            <div className="toefl-question-header-reading2">
                <div className='toefl-question-number2'>
                    {data.number}
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className='toefl-question-reading2-desc'>
                    <div  dangerouslySetInnerHTML={{ __html: data.desc }}></div>
                </div>
            </div>
            <FormControl  >
                <RadioGroup  value={data.answer} onChange={(e)=>chooseAnswer2(i1,0,e.target.value,'radio')} row aria-label="position" name="position" defaultValue="top">
                    {data.question_answer.map((answer,i3)=>(
                        <div key={i3}  className='toefl-answer-item'>
                            <FormControlLabel
                                value={answer.value}
                                control={<Radio style={{margin:'0px 0px 0px 20px'}}  color="primary" size="large"/>}
                                label={answer.text}
                                labelPlacement="left"
                            />
                            
                        </div>
                    ))}
                </RadioGroup>
            </FormControl>
            <br/><br/>
        </div>
    )
})
const Reading2=React.memo(props=>{
    let {data,i1,toefl}=props
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)

    const chooseAnswer3=(i1,i2,answer)=>{
        toefl.question[i1].image_question[i2].answer=parseInt(answer)
        dispatch(setToefl([...assestment.toefl]))
    }
    return(
        <div className='toefl-question-wrapper' key={i1}>
            <img src={data.image} style={{width:'100%'}}/>
            <br/><br/><br/>
            {data.image_question.map((que,i2)=>(
                <div key={i2}>
                    <div className="toefl-question-header-reading">
                        <div className='toefl-question-number'>
                            {que.number}
                        </div>
                        &nbsp;
                        <div className='toefl-question-reading-desc'>
                        <p><b>{que.desc}</b></p>
                        </div>
                    </div>
                    <FormControl fullWidth >
                        <RadioGroup  value={que.answer} onChange={(e)=>chooseAnswer3(i1,i2,e.target.value,'radio')} row aria-label="position" name="position" defaultValue="top">
                            <div className='' style={{width:'100%'}}>
                                {que.question_answer.map((answer,i3)=>(
                                    <div key={i3}  className='toefl-answer-item'>
                                        
                                        <FormControlLabel
                                        style={{marginRight:0}} 
                                            value={answer.value}
                                            control={<Radio style={{margin:'10px 15px 10px 15px'}} color="primary" size="large"/>}
                                            label=""
                                            labelPlacement="left"
                                            
                                        />
                                        <div onClick={()=>chooseAnswer3(i1,i2,answer.value,'radio')} style={{width:'100%',cursor:'pointer'}} className='div-flex div-align-center'><b style={{width:30,color:'#252525'}}>{upperCaseAlp[i3]}</b><div className='option-box'><p>{answer.text}</p></div></div>
                                    </div>
                                ))}
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <br/><br/>
                </div>
            ))}

            
        </div>
    )
})
export default function Assestment(props) {
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    let {toefl,session,resetPart,partToggle,subToggle,active_part,count_part,active_sub,count_sub}=props
    
    console.log(`props`, props)
    const handleDisable=()=>{
        return false
    }
    
    

    const onNext=()=>{
        if(count_sub!==active_sub){
            if(active_part===count_part){
                subToggle('next')
                resetPart()
            }else{
                partToggle('next')
            }
        }else{
            props.postJawaban()
        }
        
        
                
    }
    return (
        <div>
            <Loader/>

            {/* <Navbar duration={props.duration} toefl={toefl}  profile={props.profile}/> */}
            
            <div className='assestment-wrapper'>
                <div className='assestment-container' >
                    
                    {toefl.question.map((data,i1)=>{
                        if(session==='listening'){
                            return <Listening   toefl={toefl}  question={toefl.question} data={data} i1={i1}/>
                        }else if(session==='reading-1'){
                            if(toefl.part===1){
                                return <Reading1part1 toefl={toefl} data={data} i1={i1}/>
                            }else{
                                return <Reading1part2 toefl={toefl} data={data} i1={i1}/>

                            }
                        }else{
                            return <Reading2 toefl={toefl} data={data} i1={i1}/>
                        }
                    })}
                    
                    
                </div>
                <br/>
                <div style={{textAlign:'center'}} >
                    <Button disabled={handleDisable()} onClick={onNext} style={{width:300,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Next</Button>
                </div>
                <br/>
            </div>
        </div>
    )
}
