import * as actionType from 'redux/constants/oas'
import * as actionGeneral from './general'
import {apiCall} from 'service/apiCall'
import _ from "lodash";
import Cookie from 'universal-cookie'
import { URL, USER,PASS } from 'service/base_url'

const cookie=new Cookie()
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')
export const setOas=(obj)=>async dispatch=>{
    dispatch({
        type:actionType.SET_OAS,
        payload:obj
    })
    return obj
}

export const getAssestmentType=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Survey/list/1`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
        dispatch(setOas({type_assestment:res.data}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        return res

    }
}
export const getAssestmentList=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Survey/list/activity${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingTable(false))
        dispatch(setOas({list_assestment:res.data.items}))
        dispatch(setOas({list_assestment_pagination:res.data.info}))
        dispatch(setOas({all_total_assestment:res.data.totalActivities}))
        dispatch(setOas({all_total_participant:res.data.totalParticipants}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        return res

    }
}

export const postAssestment=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:'/Survey/activity',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))

            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Assestment berhasil ditambahkan",
                modal_component: "assestment",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Assestment ${data.name} berhasil ditambahkan</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const putAssestment=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:'/Survey/activity/'+data.id,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))

            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Assestment berhasil terupdate",
                modal_component: "assestment",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Assestment ${data.name} berhasil terupdate</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const deleteAssestment=(id)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/Survey/activity/${id}/${profile.id}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))

            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Assestment berhasil dihapus",
                modal_component: "assestment",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Assestment berhasil dihapus</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}


export const getAssestmentDetail=(id)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Survey/activity/${id}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoading(false))
        dispatch(setOas({assestment_detail:res.data}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        return res

    }
}

export const getGroup=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable(true))
    let dataReq={
        url:`/Survey/list/group${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingTable(false))
        dispatch(setOas({list_group:res.data.items}))
        dispatch(setOas({group_pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingTable(false))
        return res

    }
}
export const getIndividualReport=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingTable2(true))
    let dataReq={
        url:`/Survey/list/individual${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingTable2(false))
        dispatch(setOas({individual_report:res.data.items}))
        dispatch(setOas({individual_report_pagination:res.data.info}))
        let new_headers=[]
        res.data.headers.map((d)=>{
            new_headers.push(d.text)
        })
        dispatch(setOas({individual_report_headers:new_headers}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingTable2(false))
        return res

    }
}

export const postGroup=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:'/Survey/group',
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))

            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Group berhasil ditambahkan",
                modal_component: "assestment",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Group ${data.name} berhasil ditambahkan</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}

export const deleteGroup=(id)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:`/Survey/group/${id}/${profile.id}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))

            dispatch(actionGeneral.modalToggle({ 
                modal_open: true,
                modal_title: "Group berhasil dihapus",
                modal_component: "group",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Group berhasil dihapus</p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}

export const getSurveyUuid=(uuid)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Survey/uuid/${uuid}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(actionGeneral.setLoading(false))
        let new_jawaban=[]
        res.data.pages.map((data)=>{
            if(data.itemType==='varied'){
                let demografi=[]
                data.items.map((org)=>{
                    demografi.push({
                        id: 0,
                        itemId: org.id,
                        ratingId: 0,
                        answerText: "",
                        type:org.itemType
                    })
                })
                new_jawaban.push(demografi)
            }else if(data.itemType==='single'){
                new_jawaban.push({itemType:data.itemType,id:data.id,itemId:data.items[0].id,option:null})
            }else{
                new_jawaban.push({itemType:data.itemType,id:data.id,itemId:0,option:null})
            }
        })
        // console.log(`new_jawaban`, new_jawaban)
        dispatch(setOas({jawaban:new_jawaban}))
        dispatch(setOas({detail_survey:res.data}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        return res

    }
}


export const submitSurvey=(data)=>async dispatch=>{
    dispatch(actionGeneral.setLoading(true))
        let dataReq={
            url:'/Survey',
            method:'POST',
            data:{data:data, auth:{username:USER,password:PASS},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(_.get(res,'status')===200){
            dispatch(actionGeneral.setLoading(false))
            dispatch(setOas({result_survey:res.data}))
            return res
        }else{
            dispatch(actionGeneral.setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}

export const getSurveyReport=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoading(true))
    let dataReq={
        url:`/Survey/report${slug}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){
        dispatch(actionGeneral.setLoading(false))
        dispatch(setOas({report_survey:res.data}))
        return res
    }else{
        dispatch(actionGeneral.setLoading(false))
        // return res

    }
}