import React,{useState,useEffect} from 'react'
import {Button,Checkbox,FormGroup ,Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@material-ui/core'
import Countdown from 'react-countdown';
import {useSelector,useDispatch} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import {setPapi,postPapi} from 'redux/actions/assestment'
import {isMobile} from 'react-device-detect'
// import './style.css'
import Loader from 'components/Loading'
import Check from 'assets/icon/Check.svg'
import infomedia from 'assets/image/Logo_Infomedia_white.png'

const Navbar=React.memo(props=>{
    const dispatch=useDispatch()
    const [start, setStart] = useState(Date.now())
    const [second,setSecond]=useState(1200000)
    useEffect(() => {
        // console.log('props.papi_start_time', props.papi_start_time)
        setStart(props.papi_start_time==null?Date.now():props.papi_start_time)
    }, [])
    const onClickHelp=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "",
            modal_component: "intruksi_papi",
            modal_size:700,
            modal_type:'',
            modal_data:{
                papi:props.papi
            },
            modal_action:'success',
        }))
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {

        if (completed) {
          return <span className='timer-expired'>00:00:00</span>;
            
        } else {
            return <span className='timer'>00:{minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}</span>;
        }
      };
      let username=props.profile.userName.split(" ")[0]
    // console.log('Date.now()-start', Date.now(),start)
    return(
        <div className='navbar-wrapper'>
            <div className='div-flex div-space-between div-align-center navbar' >
                <div className='d-flex'>
                        <img
                        src={infomedia}
                        width="auto"
                        height={35}
                        />
                        </div>
                <h3>Selamat mengerjakan <br className='show-mobile'/> <span className='name'>{username}</span></h3>
            <div className=' assestmen-toolbar div-flex div-align-center div-space-between'>
                <div className='div-flex div-align-center'>
                    <p className='hide-mobile' style={{color:'white',fontWeight:'bold',margin:0}}>Petunjuk pengisian</p>
                    &nbsp;&nbsp;
                    <div onClick={onClickHelp} className='circle-help' ><span>?</span></div>

                </div>
                <div className='hide-mobile' style={{width:1.3,height:30,backgroundColor:'white'}}></div>
                <h3>Sisa waktu <br className='show-mobile'/> 
                    <Countdown
                        date={start + second}
                        renderer={renderer}
                    />
                </h3>
            </div>
            </div>
            
        </div>
    )
})
export default function Assestment(props) {
    const dispatch=useDispatch()
    
    const assestment = useSelector(state => state.assestment)
    const {papi_start_time}=assestment
    const [expired,setExpired]=useState(false)
    let {papi}=props

    
    const handleDisable=()=>{
        // return false
        let find_empty_answer= papi.question.filter(data=>{
            return data.answer===0
        })
        if(find_empty_answer.length>0){
            return true
        }else{
            return false
        }
    }
    const postJawaban=()=>{
        let new_jawaban=[]
        papi.question.map((data)=>{
            new_jawaban.push(data.answer)
        })
        let data={
            userId:props.profile.id,
            answers:new_jawaban
        }
        dispatch(postPapi(data))
        console.log('new_jawaban', new_jawaban)
    }   
    const chooseAnswer=(i1,i2,answer)=>{
        papi.question[i1].answer=answer
        dispatch(setPapi([...assestment.papi]))
    }
    const onNext=()=>{
        postJawaban()
        
    }
    // console.log('trigger')
    return (
        <div>
            <Loader/>

            <Navbar papi_start_time={papi_start_time}  papi={papi}  profile={props.profile}/>

            <div className='assestment-wrapper'>
                <div className='assestment-container' >
                    {papi.question.map((data,i)=>(
                        <div className='papi-question-wrapper' key={i}>
                            <div className='papi-question-number'>
                                {i+1}
                            </div>
                            {data.item.map((item,i2)=>(
                                <div className='papi-question' key={i2} onClick={()=>chooseAnswer(i,i2,item.id)}>
                                    <div className={`papi-question-checkbox ${data.answer===item.id?'checked-question':''} `}>
                                        <img src={Check}/>
                                    </div>
                                    <div className='papi-question-question'>
                                        <p>{item.text}.</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    
                    
                </div>
                <br/>
                <div style={{textAlign:'center'}} >
                    <Button disabled={handleDisable()} onClick={onNext} style={{width:300,textTransform:'none',borderRadius:20,fontWeight:'bold'}} color='primary' variant='contained' size='large'>Kumpulkan</Button>
                </div>
                <br/>
            </div>
        </div>
    )
}
