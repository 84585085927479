import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Routes from './routes'
import { ThemeProvider } from '@material-ui/styles';
import {Provider} from 'react-redux'
import {store,persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
  palette: { 
      primary: {
          main:'#65b7ff',
          contrastText: '#FFFFFF',

      },
      secondary: {
          main:'#FFFFFF',
      }
  } 
})
function App() {
  return (
    <MuiThemeProvider theme={themeButton}>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
            <Routes/>
        </PersistGate>
      </Provider>
      </MuiThemeProvider>
  );
}

export default App;
