import React,{useEffect,useState} from 'react'
import {Button,FormControl,InputLabel,Select,MenuItem, TableRow, TableCell} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from 'components/TextField'
import AutoCompleteSelect from 'components/Select'
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import BerandaPeople from 'assets/image/oas/beranda-people.png'
import Chain from 'assets/image/oas/Chain.png'
import DataTable from 'components/DataTable'
import SearchTable from 'components/SearchTable'
import _ from 'lodash'
import { modalToggle } from 'redux/actions/general'
import { useDispatch,useSelector } from 'react-redux'
import { setOas,postAssestment,getAssestmentDetail,putAssestment,getGroup,getIndividualReport,deleteGroup } from 'redux/actions/oas'
import moment from 'moment'
const useStyles = makeStyles(theme => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 8,
          },
          width:'100%',
          marginBottom:20
  },
}));
const RenderHeadGroup=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
        <Button className='btn-remove-capital' variant='text' color="primary">Refresh</Button>
    </div>
)
const RenderHeadIndividual=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
        <Button className='btn-remove-capital' variant='text' color="primary">Refresh</Button>
    </div>
)
export default function Detail(props) {
    const classes=useStyles()
    const dispatch = useDispatch()
    const oas = useSelector(state => state.oas)
    const general = useSelector(state => state.general)
    const [copied, setcopied] = useState({})
    const [searchgroup, setsearchgroup] = useState('')
    const [searchindividual, setsearchindividual] = useState('')
    const {type_assestment,assestment_detail}=oas
    useEffect(() => {
        if(props.tab!=='add'){
            dispatch(getGroup(`/${assestment_detail.id}/1/10/*`))
            dispatch(getIndividualReport(`/${assestment_detail.id}/1/10/*`))
        }
    }, [])
    const searchToggleGroup=_.debounce(async (e)=>{
        setsearchgroup(e)
        dispatch(getGroup(`/${assestment_detail.id}/1/10/${e.length>0?e:'*'}`))
    },1000)
    const searchToggleIndividual=_.debounce(async (e)=>{
        setsearchindividual(e)
        dispatch(getIndividualReport(`/${assestment_detail.id}/1/10/${e.length>0?e:'*'}`))
    },1000)
    const togglePaginationGroup=(page,pageLength)=>{
        dispatch(getGroup(`/${assestment_detail.id}/${page}/${pageLength}/${searchgroup.length>0?searchgroup:'*'}`))
    }
    const togglePaginationIndividual=(page,pageLength)=>{
        
        dispatch(getIndividualReport(`/${assestment_detail.id}/${page}/${pageLength}/${searchindividual.length>0?searchindividual:'*'}`))
    }
    const addGroup=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Add Group`,
            modal_component: "oas_add_group",
            modal_size:400,
            modal_data:{
                afterPost:()=>dispatch(getGroup(`/${assestment_detail.id}/1/10/*`))
            },
            modal_action:'oas_add_group'
        }))
    }
    const onClickSave=async ()=>{
        let data={
            id:assestment_detail.id,
            surveyId:assestment_detail.survey.id,
            clientId:0,
            name:assestment_detail.name,
            startDate:moment(assestment_detail.startDate).format(),
            endDate:moment(assestment_detail.endDate).format(),
            useId:props.profile.id
        }
        if(props.tab==='add'){
            let res= await dispatch(postAssestment(data))
            if(res){
                dispatch(getAssestmentDetail(res.data.id))
                props.settab('detail')
            }
        }
        if(props.tab==='detail'){
            props.settab('edit')
        }
        if(props.tab==='edit'){
            dispatch(putAssestment(data))
        }
    }
    const handleButtonDisable=()=>{
        if(props.tab==='add'){
            if(assestment_detail.name!==""){
                return false
            }else{
                return true
            }
        }
    }
    const handleFieldDisable=()=>{
        if(props.tab==='detail'){
            return true
        }else{
            return false
        }
    }
    const deleteActionGroup=async (data)=>{
        let res=await dispatch(deleteGroup(data.id))
        if(res){
            dispatch(getGroup(`/${assestment_detail.id}/1/10/*`))
        }
    }
    const onDeleteGroup=(data)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Group',
                action:()=>deleteActionGroup(data),
                msg:`<p>Yakin hapus <b>${data.name}</b> ?</p>`
            },
            modal_action:'delete_talenta_project'
        }))
    }
    const onCopied=(d,i)=>{
        setcopied({[i]:true})
        setTimeout(() => {
            setcopied({})
        }, 2000);
        navigator.clipboard.writeText(d.url)
    }
    const individualReport=(d)=>{
        window.open(`/oas/${oas.assestment_detail.survey.id===3?'spex2':'dtra'}/report/${d.uuid}`)
    }
    console.log(`oas`, oas)
    return (
        <div>
            <div className='head-section'>  
                <h4 data-testid="heading"><b>Create Assestment</b></h4>
                <div className="div-flex">
                <Button onClick={()=>props.settab('list')} className='btn-remove-capital btn-rounded btn-action' variant='outlined'  color="primary">Back</Button>
                &nbsp;
                <Button disabled={handleButtonDisable()} onClick={onClickSave} className='btn-remove-capital btn-rounded btn-action' variant='contained'  color="primary">
                    {props.tab==='detail'?'Edit':'Save'}
                </Button>

                </div>
            </div>
            <div className='card-content' style={{padding:20}}>
                <div className="div-flex" style={{gap:40}}>
                    <div style={{width:'40%'}}>
                        <TextField
                            label="Activity name"
                            variant='outlined'
                            value={assestment_detail.name}
                            size='small'
                            style={{marginBottom:20}}
                            disabled={handleFieldDisable()}
                            onChange={(e)=>dispatch(setOas({assestment_detail:{...assestment_detail,name:e.target.value}}))}
                        />
                         <FormControl  size='small' variant='outlined' className={classes.textField}>
                            <InputLabel id="demo-simple-select-label">Assessment type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={assestment_detail.survey.id}
                                onChange={(e)=>dispatch(setOas({assestment_detail:{...assestment_detail,survey:{id:e.target.value}}}))}
                                labelWidth={130}
                                disabled={handleFieldDisable()}
                            >
                                {type_assestment.map((d,i)=>(
                                    <MenuItem  value={d.id}>{d.text}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>
                        
                        <div className="div-flex" style={{gap:20}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                inputVariant="outlined"
                                value={assestment_detail.startDate}
                                color='primary' 
                                disabled={handleFieldDisable()}
                                format={'DD MMM YYYY HH:mm'} 

                                onChange={(data)=>dispatch(setOas({assestment_detail:{...assestment_detail,startDate:data}}))}
                                className={classes.textField}  label='Start Date' clearable={true} size='small' inputVariant='outlined'
                            />
                            
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker 
                                format={'DD MMM YYYY HH:mm'} 
                                color='primary' 
                                disabled={handleFieldDisable()}
                                value={assestment_detail.endDate} 
                                onChange={(data)=>dispatch(setOas({assestment_detail:{...assestment_detail,endDate:data}}))}
                                className={classes.textField}  label='End Date' clearable={true} size='small' inputVariant='outlined'  />
                        </MuiPickersUtilsProvider>
                        </div>
                        
                        
                    </div>
                    <div style={{width:'60%'}}>
                        <div className="div-flex" style={{gap:20}}>
                            <div className="total-card">
                                <h3>Total Group</h3>
                                <div className="div-flex div-space-between div-align-center">
                                    <h1>{props.tab==='add'?'-':assestment_detail.totalGroups}</h1>
                                    <img src={Chain}/>
                                </div>
                            </div>
                            <div className="total-card">
                                <h3>Total Participant</h3>
                                <div className="div-flex div-space-between div-align-center">
                                    <h1>{props.tab==='add'?'-':assestment_detail.totalParticipants}</h1>
                                    <img src={BerandaPeople}/>
                                </div>
                            </div>
                        </div>
                        {/* <br/>
                        <div className="div-flex div-align-center" style={{gap:20}}>
                            <div style={{width:'60%'}}>
                                <TextField
                                    label="Credit"
                                    variant='outlined'
                                    // value={admins.name}
                                    size='small'
                                    // onChange={(e)=>setadmin({...admins,name:e.target.value})}
                                />
                            </div>
                            <Button onClick={addKredit} className="btn-remove-capital " color="primary" variant="text">Add Credit</Button>
                        </div> */}
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className='head-section'>  
                <h4 data-testid="heading"><b>List Group</b></h4>
                <Button disabled={props.tab!=='add'?false:true} onClick={addGroup} className='btn-remove-capital btn-rounded btn-action' variant='contained'  color="primary">Create Group</Button>
            </div>
            <DataTable
                head={['No.','Group Name','Link','Total Participant','Action']}
                body={()=>{
                    if(oas.list_group.length>0){
                        return oas.list_group.map((d,i)=>(
                            <TableRow key={i}>
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{d.name}</TableCell>
                                <TableCell style={{color:'#00a1ed'}}>{d.url}</TableCell>
                                <TableCell>{d.total}</TableCell>
                                <TableCell align='right'>
                                    <div className="div-flex" style={{justifyContent:'flex-end'}}>
                                    <p onClick={()=>onCopied(d,i)} style={{margin:'0 8px',cursor:'pointer',fontWeight:'bold', color:'#00a1ed'}}>{copied[i]?'Copied':'Copy'}</p>
                                    <p onClick={()=>{window.open(`/oas/${oas.assestment_detail.survey.id===3?'spex2':'dtra'}/report/${d.uuid}`,'_blank')}} style={{margin:'0 8px',cursor:'pointer',fontWeight:'bold', color:'#4cc614'}}>Report</p>
                                    <p onClick={()=>onDeleteGroup(d)} style={{margin:'0 8px',cursor:'pointer',fontWeight:'bold', color:'#ff2e21'}}>Delete</p>
                                    </div>
                                    
                                </TableCell>
                            </TableRow>
                        ))
                    }else{
                        return(
                            <TableRow>
                                <TableCell colSpan={5} align='center'>No Link assessment created</TableCell>
                            </TableRow>
                        )
                    }
                }}
                cardHead={<RenderHeadGroup {...props}  searchToggle={searchToggleGroup} />}
                loading={general.isLoadingTable}
                pagination={oas.group_pagination}
                togglePagination={togglePaginationGroup}
            />
            <br/>
            <div className='head-section'>  
                <h4 data-testid="heading"><b>Individual Report</b></h4>
            </div>
            <DataTable
                head={['No.',...oas.individual_report_headers,'Aksi']}
                body={()=>{
                    if(oas.list_group.length>0){
                        return oas.individual_report.map((d,i)=>(
                            <TableRow key={i}>
                                <TableCell>{i+1}</TableCell>

                                {d.data.map((dat,i)=>(
                                    <TableCell>{dat}</TableCell>

                                ))}
                                <TableCell align='right'>
                                    <p onClick={()=>individualReport(d)} style={{margin:0,cursor:'pointer',fontWeight:'bold', color:'#4cc614'}}>Report</p>
                                </TableCell>
                            </TableRow>
                        ))
                    }else{
                        return(
                            <TableRow>
                                <TableCell colSpan={5} align='center'>No Individual Report</TableCell>
                            </TableRow>
                        )
                    }
                }}
                cardHead={<RenderHeadGroup {...props}  searchToggle={searchToggleIndividual} />}
                loading={general.isLoadingTable2}
                pagination={oas.individual_report_pagination}
                togglePagination={togglePaginationIndividual}
            />
        </div>
    )
}
