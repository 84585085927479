import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";

import shape_1 from "assets/image/shape_1.svg";
import shape_2 from "assets/image/shape_2.svg";
import shape_3 from "assets/image/shape_3.svg";
import shape_4 from "assets/image/shape_4.svg";
import { tabToggle } from "redux/actions/master";
import Bg from "assets/image/disc_login.png";
import "../style.css";
import { logout } from "redux/actions/auth";
import ACS from "assets/image/ACS.png";
export default function Done(props) {
  const [agree, setAgree] = useState();
  const dispatch = useDispatch();
  const clickKeluar = () => {
    // window.location.assign('/portal')
    dispatch(logout());
    // dispatch(tabToggle('welcome','done'))
  };
  const kembaliKemenu = () => {
    window.location.assign("/portal");
  };
  return (
    <div>
      <div className="welcome-wrapper">
        <div className="welcome-text hide-mobile">
          <div className="hide-mobile">
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="welcome-mobile hide-mobile">
            <h2>
              Hi, <span>{props.profile.userName}</span>
            </h2>
            <p>Anda sudah menjalani proses DISC Personality Test </p>
            <Button
              onClick={clickKeluar}
              className="hide-mobile"
              style={{
                textTransform: "none",
                borderRadius: 20,
                fontWeight: "bold",
              }}
              color="primary"
              variant="text"
              size="large"
            >
              Keluar
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              onClick={kembaliKemenu}
              className="hide-mobile"
              style={{
                textTransform: "none",
                borderRadius: 20,
                fontWeight: "bold",
              }}
              color="primary"
              variant="contained"
              size="large"
            >
              Kembali ke menu
            </Button>
          </div>
          <br />
          <br />
          <br />
          <div className="div-flex div-align-center hide-mobile">
            <p className="semi-bold">Provided By</p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <img
                src={ACS}
                width="auto"
                height={40}
                onClick={() =>
                  window.location.assign(
                    "https://www.gmlperformance.com/services/assesment"
                  )
                }
              />
              <div
                className="vertical-line"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              ></div>
              <img
                src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
                width="auto"
                height={37}
              />
            </div>
          </div>
        </div>
        <div className="welcome-example hide-mobile">
          <img
            style={{ top: 35, left: -20 }}
            className="shape-img"
            src={shape_1}
          />
          <img
            style={{ top: 5, right: 50 }}
            className="shape-img"
            src={shape_2}
          />
          <img
            style={{ top: 350, left: -40 }}
            className="shape-img"
            src={shape_3}
          />
          <img
            style={{ bottom: 10, left: 300 }}
            className="shape-img"
            src={shape_4}
          />
          <img
            style={{ bottom: 30, right: 20 }}
            className="shape-img"
            src={shape_1}
          />
          <div className="card-example" style={{ textAlign: "center" }}>
            <img src={Bg} style={{ width: "90%" }} />
          </div>
        </div>
        <div className="show-mobile" style={{ height: "100%" }}>
          <div
            className=" welcome-text"
            style={{
              margin: "auto",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div className="welcome-mobile" style={{ textAlign: "center" }}>
              <img src={Bg} style={{ width: "80%", margin: "auto" }} />
              <br />
              <br />
              <h2>
                Hi, <span>{props.profile.userName}</span>
              </h2>
              <p>Anda sudah menjalani proses DISC Personality Test </p>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={clickKeluar}
                  style={{
                    width: 150,
                    textTransform: "none",
                    borderRadius: 20,
                    fontWeight: "bold",
                  }}
                  color="primary"
                  variant="text"
                  size="large"
                >
                  Keluar
                </Button>
                <Button
                  onClick={kembaliKemenu}
                  style={{
                    textTransform: "none",
                    borderRadius: 20,
                    fontWeight: "bold",
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Kembali ke menu
                </Button>
              </div>
              <div style={{ textAlign: "center" }}>
                <p className="semi-bold" style={{ marginBottom: 10 }}>
                  Provided by:
                </p>
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <img
                    src={ACS}
                    width="auto"
                    height={40}
                    onClick={() =>
                      window.location.assign(
                        "https://www.gmlperformance.com/services/assesment"
                      )
                    }
                  />
                  <div
                    className="vertical-line"
                    style={{ marginLeft: "7px", marginRight: "7px" }}
                  ></div>
                  <img
                    src="https://apidls.onegml.com/ac/holland_image/1690252267.jpg"
                    width="auto"
                    height={37}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
