import React ,{useEffect,useState}from 'react'
import Webcam from "react-webcam";
import {useSelector,useDispatch} from 'react-redux'
import {postFoto,setCountFoto} from 'redux/actions/assestment'
export default function Index(props) {
    const dispatch=useDispatch()
    const assestment = useSelector(state => state.assestment)
    useEffect(()=>{
        if(assestment.record){
            // setTimeout(() => {
            //     capture()
                
            // }, 5000);
            setInterval(() => {
                capture()
            }, 180000);
            // 200000
        }

    },[])
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
      };
       
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        async () => {
            if(assestment.record&&webcamRef.current!==null){
                const imageSrc = webcamRef.current.getScreenshot();
                if(imageSrc!==null){
                    let data={
                        userId:props.profile.id,
                        imageSrc:imageSrc
                    }
                    if(assestment.count_foto<=7){
                        // console.log('assestment.count_foto', assestment.count_foto)
                        dispatch(postFoto(data))
                        dispatch(setCountFoto(assestment.count_foto++))
                    }
                    
                }
                
            }
                     
        },
        [webcamRef]
    );
    // console.log('assestment.count_foto', assestment.count_foto)
    return (
        <div>
            {assestment.record&&
            <Webcam
                audio={false}
                height={0}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={0}
                videoConstraints={videoConstraints}
                forceScreenshotSourceSize={true}
                
            />}
      
        </div>
    )
}
